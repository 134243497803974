import { createSlice } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import {
  EditGiftVoucherResponse,
  MutationEditGiftVoucherArgs,
} from "@generated/types";
import type { PayloadAction } from "@reduxjs/toolkit";

export type GiftVoucherEditState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loading: boolean;
  data: Nullable<EditGiftVoucherResponse>;
};

const initialState: GiftVoucherEditState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loading: false,
  data: null,
});

export const giftVoucherEditSlice = createSlice({
  name: "giftVouchersSaved",
  initialState,
  reducers: {
    editGiftVoucher: (
      state,
      action: PayloadAction<MutationEditGiftVoucherArgs>
    ) => {
      state.hasError = false;
      state.loading = true;
      state.data = null;
      state.errorMessage = null;
    },
    editGiftVoucherSuccess: (
      state,
      action: PayloadAction<EditGiftVoucherResponse>
    ) => {
      state.data = action.payload;
      state.loading = false;
      state.errorMessage = null;
      state.hasError = false;
    },
    editGiftVoucherFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.hasError = true;
      state.errorMessage = action.payload;
      state.data = null;
    },
    redirectActionLoading: (state) => {
      state.loading = true;
      state.hasError = false;
      state.data = null;
      state.errorMessage = null;
    },
    redirectActionComplete: (state) => {
      state.loading = false;
      state.hasError = false;
      state.data = null;
      state.errorMessage = null;
    },
  },
});
export const {
  editGiftVoucher,
  editGiftVoucherFail,
  editGiftVoucherSuccess,
  redirectActionComplete,
  redirectActionLoading,
} = giftVoucherEditSlice.actions;

export default giftVoucherEditSlice.reducer;
