import { createSlice, current } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import {
  // ApplicablePaymentOptionsResponse,
  PaymentOptionsAndPrices,
  QueryCabinPaymentOptionsArgs,
} from "@generated/types";

export type BookingPaymentOptionsState = {
  loading: boolean;
  data: Nullable<PaymentOptionsAndPrices[]>;
  errorMessage: Nullable<string>;
  loaded: boolean;
  hasError: boolean;
};

const initialState: BookingPaymentOptionsState = Object.freeze({
  loading: false,
  data: null,
  errorMessage: null,
  loaded: false,
  hasError: false,
});

export const paymentOptionsSlice = createSlice({
  name: "paymentOptions",
  initialState,
  reducers: {
    getPaymentOptionsAction: (
      state,
      action: PayloadAction<QueryCabinPaymentOptionsArgs>
    ) => {
      state.loading = true;
      state.data = null;
      state.errorMessage = null;
      state.loaded = false;
      state.hasError = false;
    },
    getPaymentOptionsSuccess: (
      state,
      action: PayloadAction<PaymentOptionsAndPrices[]>
    ) => {
      state.data = action.payload;
      state.loaded = true;
      state.loading = false;
      state.errorMessage = null;
      state.hasError = false;
    },
    getPaymentOptionsFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loaded = false;
      state.loading = false;
      state.data = null;
    },
  },
});

export const {
  getPaymentOptionsAction,
  getPaymentOptionsSuccess,
  getPaymentOptionsFail,
} = paymentOptionsSlice.actions;
export default paymentOptionsSlice.reducer;
