import { createSlice } from "@reduxjs/toolkit";
import { QueryUpcomingBookingsArgs, UpcomingBooking } from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type UpcomingBookingState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: UpcomingBooking;
};

export const initialState: UpcomingBookingState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: {
    upcomingBookingSummary: null,
    cabins: [],
  },
});

export const bookingUpcoming = createSlice({
  name: "bookingUpcoming",
  initialState,
  reducers: {
    getUpcomingBookings: (
      state,
      action: PayloadAction<QueryUpcomingBookingsArgs>
    ) => {
      state.loading = true;
      state.data = {
        upcomingBookingSummary: null,
        cabins: [],
      };
      state.errorMessage = null;
      state.loaded = false;
      state.hasError = false;
    },
    getUpcomingBookingsSuccess: (
      state,
      action: PayloadAction<UpcomingBooking>
    ) => {
      state.data = action.payload;
      state.loaded = true;
      state.loading = false;
      state.errorMessage = null;
      state.hasError = false;
    },
    getUpcomingBookingsFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.data = {
        upcomingBookingSummary: null,
        cabins: [],
      };
      state.errorMessage = action.payload;
      state.loaded = false;
      state.hasError = true;
    },
  },
});

export const {
  getUpcomingBookings,
  getUpcomingBookingsSuccess,
  getUpcomingBookingsFail,
} = bookingUpcoming.actions;

export default bookingUpcoming.reducer;
