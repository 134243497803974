import { createSlice } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import { ErrorMessage } from "@generated/types";
import type { PayloadAction } from "@reduxjs/toolkit";

export type ErrorMessagesState = {
  errorMessages: Nullable<ErrorMessage[]>;
  hasError: boolean;
  error: Nullable<string>;
  loaded: boolean;
  loading: boolean;
};

const initialState: ErrorMessagesState = Object.freeze({
  errorMessages: null,
  hasError: false,
  error: null,
  loaded: false,
  loading: false,
});

export const errorMessagesSlice = createSlice({
  name: "errorMessages",
  initialState,
  reducers: {
    getErrorMessages: (state) => {
      state.loading = true;
      state.hasError = false;
      state.loaded = false;
      state.errorMessages = null;
      state.error = null;
    },
    getErrorMessagesSuccess: (state, action: PayloadAction<ErrorMessage[]>) => {
      state.errorMessages = action.payload;
      state.loaded = true;
      state.loading = false;
      state.hasError = false;
      state.error = null;
    },
    getErrorMessagesFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.error = action.payload;
      state.loaded = false;
      state.loading = false;
      state.errorMessages = null;
    },
  },
});

export const {
  getErrorMessages,
  getErrorMessagesSuccess,
  getErrorMessagesFail,
} = errorMessagesSlice.actions;

export default errorMessagesSlice.reducer;
