import { createSlice } from "@reduxjs/toolkit";
import {
  CustomerForgottenPasswordRequestInput,
  CustomerForgottenPasswordResponse,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type ForgotPasswordState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<CustomerForgottenPasswordResponse>;
};

const initialState: ForgotPasswordState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {
    forgotPassword: (
      state,
      action: PayloadAction<CustomerForgottenPasswordRequestInput>
    ) => {
      state.loading = true;
      state.hasError = false;
      state.loaded = false;
      state.errorMessage = null;
      state.data = null;
    },
    forgotPasswordSuccess: (
      state,
      action: PayloadAction<CustomerForgottenPasswordResponse>
    ) => {
      state.data = action.payload;
      state.loaded = true;
      state.loading = false;
      state.hasError = false;
      state.errorMessage = null;
    },
    forgotPasswordFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loaded = false;
      state.loading = false;
      state.data = null;
    },
    forgotPasswordReset: (state, action: PayloadAction<void>) => ({
      ...initialState,
    }),
  },
});

export const {
  forgotPassword,
  forgotPasswordSuccess,
  forgotPasswordFail,
  forgotPasswordReset,
} = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
