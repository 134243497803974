import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type ExtraUiState = {
  isAddedExtrasExpanded: boolean;
};

const initialState: ExtraUiState = Object.freeze({
  isAddedExtrasExpanded: false,
});

export const extraUiSlice = createSlice({
  name: "extraUi",
  initialState,
  reducers: {
    expandExtraSummaryDetails: (state, action: PayloadAction<boolean>) => {
      state.isAddedExtrasExpanded = action.payload;
    },
  },
});

export const { expandExtraSummaryDetails } = extraUiSlice.actions;

export default extraUiSlice.reducer;
