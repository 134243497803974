import { ApolloClient, InMemoryCache, HttpLink, from } from "@apollo/client";
import ApolloLinkTimeout from "apollo-link-timeout";
import fetch from "cross-fetch";
import { onError } from "@apollo/client/link/error";
import * as Sentry from "@sentry/nextjs";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      Sentry.captureException(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) Sentry.captureException(`[Network error]: ${networkError}`);
});

const timeoutLink = new ApolloLinkTimeout(
  Number(process.env.NEXT_PUBLIC_APOLLO_LINK_TIMEOUT_BSL)
);

const timeoutLinkStrapi = new ApolloLinkTimeout(
  Number(process.env.NEXT_PUBLIC_APOLLO_LINK_TIMEOUT_STRAPI)
);

const httpLink = new HttpLink({
  uri: process.env.NEXT_PUBLIC_BSL_ENDPOINT,
  fetch,
});

const httpLinkStrapi = new HttpLink({
  uri: process.env.NEXT_PUBLIC_STRAPI_CMS_GRAPHQL_ENDPOINT,
  fetch,
});

const timeoutHttpLinkCms = timeoutLinkStrapi.concat(httpLinkStrapi);
const timeoutHttpLink = timeoutLink.concat(httpLink);

const createApolloClient = () =>
  new ApolloClient({
    link: from([errorLink, timeoutHttpLink]),
    cache: new InMemoryCache(),
    ssrMode: true,
  });

const createApolloClientStrapi = () =>
  new ApolloClient({
    link: from([errorLink, timeoutHttpLinkCms]),
    cache: new InMemoryCache(),
  });

export const apolloClient = createApolloClient();
export const apolloClientStrapi = createApolloClientStrapi();
