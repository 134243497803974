import { createSlice } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  BlogArticle_S as BlogArticle,
  BlogPostsResponse,
  QueryBlogPostsArgs,
} from "@generated/types";

export type FHBlogArticle = {
  title: string;
  subtitle: string;
  imageURL: string;
  categoryName: string;
  categoryColour: string;
  blogURL: string;
};

export type BlogsState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  getBlogsRequest?: QueryBlogPostsArgs;
  blogs: Nullable<BlogPostsResponse>;
  articles: FHBlogArticle[];
  hasMore: boolean;
  totalCount: number;
};

const initialState: BlogsState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  blogs: null,
  articles: [],
  hasMore: false,
  totalCount: 0,
});

export const blogsSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    getBlogsAction: (state, action: PayloadAction<QueryBlogPostsArgs>) => {
      state.getBlogsRequest = action.payload;
      state.loading = true;
    },
    getBlogsSuccessAction: (
      state,
      action: PayloadAction<BlogPostsResponse>
    ) => {
      const blogs = action.payload.blogArticles as BlogArticle[];

      const articles: FHBlogArticle[] = blogs.map((blog) => {
        return {
          title: blog.title as string,
          subtitle: blog.subtitle as string,
          categoryName: blog.category?.name as string,
          categoryColour: blog.category?.colour?.value as string,
          imageURL: blog.imageURL as string,
          blogURL: blog.url as string,
        };
      });

      state.blogs = action.payload;
      state.articles = articles;
      state.loaded = true;
      state.loading = false;
      state.hasMore = action.payload.hasMore || false;
      state.totalCount = action.payload.totalCount || 0;
    },
    getBlogsFailAction: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.loaded = true;
      state.errorMessage =
        action?.payload || "An error occurred. Please try again.";
      state.hasMore = false;
      state.totalCount = 0;
      state.articles = [];
      state.blogs = null;
      state.loading = false;
    },
  },
});

export const { getBlogsAction, getBlogsSuccessAction, getBlogsFailAction } =
  blogsSlice.actions;

export default blogsSlice.reducer;
