import { createSlice } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import { PaymentRequestInput, PaymentResponse } from "@generated/types";
import type { PayloadAction } from "@reduxjs/toolkit";

export type CreateExtraPaymentState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<PaymentResponse>;
};

const initialState: CreateExtraPaymentState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const extraPaymentSlice = createSlice({
  name: "extraPayment",
  initialState,
  reducers: {
    createExtraCardPayment: (
      state,
      action: PayloadAction<PaymentRequestInput>
    ) => {
      state.loading = true;
      state.loaded = false;
      state.errorMessage = null;
      state.hasError = false;
      state.data = null;
    },
    createExtraCardPaymentSuccess: (
      state,
      action: PayloadAction<PaymentResponse>
    ) => {
      state.data = action.payload;
      state.loading = false;
      state.loaded = true;
      state.errorMessage = null;
      state.hasError = false;
    },
    createExtraCardPaymentFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loaded = false;
      state.loading = false;
      state.data = null;
    },
    createExtraCardPaymentReset: (state) => ({
      ...initialState,
    }),
  },
});

export const {
  createExtraCardPayment,
  createExtraCardPaymentSuccess,
  createExtraCardPaymentFail,
  createExtraCardPaymentReset,
} = extraPaymentSlice.actions;

export default extraPaymentSlice.reducer;
