import { createSlice } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import {
  Address,
  AuthenticatedCustomer,
  MutationLoginArgs,
} from "@generated/types";
import type { PayloadAction } from "@reduxjs/toolkit";
import { FHEventType } from "src/interfaces/serverSideTracking";
import { BillingAddress } from "src/interfaces/customer";

export type LoginState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<AuthenticatedCustomer>;
  loginRequest?: {
    email: string;
    password: string;
  };
  isBookingJourney: boolean;
  isGiftVoucherJourney: boolean;
  loggedOutRecently: boolean;
  hasAutoLoginError: boolean;
  isEmailRegistered: Nullable<boolean>;
  justLoggedIn: boolean;
  experimentAddressData: Nullable<BillingAddress | Address>;
};

const initialState: LoginState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
  isBookingJourney: false,
  isGiftVoucherJourney: false,
  loggedOutRecently: false,
  hasAutoLoginError: false,
  isEmailRegistered: null,
  justLoggedIn: false,
  experimentAddressData: null,
});

export const authSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    createLogin: (
      state,
      action: PayloadAction<
        MutationLoginArgs & {
          rememberMe?: boolean;
          eventType?: FHEventType;
        }
      >,
    ) => ({
      ...initialState,
      loading: true,
      isBookingJourney: state.isBookingJourney,
      isGiftVoucherJourney: state.isGiftVoucherJourney,
      isEmailRegistered: state.isEmailRegistered,
    }),
    createAutoLogin: (state, action: PayloadAction<LoginState>) => {
      state.loading = true;
    },
    setIsBookingJourney: (state, action: PayloadAction<boolean>) => {
      state.isBookingJourney = action.payload;
    },
    setIsGiftVoucherJouney: (state, action: PayloadAction<boolean>) => {
      state.isGiftVoucherJourney = action.payload;
    },
    checkEmailRegistration: (state, action: PayloadAction<string>) => {
      state.isEmailRegistered = null;
      state.loading = true;
      state.hasError = false;
    },
    checkEmailRegistrationSuccess: (state, action: PayloadAction<boolean>) => {
      state.isEmailRegistered = action.payload;
      state.loading = false;
    },
    resetEmailRegistrationCheck: (state, action: PayloadAction<void>) => {
      state.isEmailRegistered = null;
      state.loading = false;
      state.hasError = false;
    },
    createLoginSuccess: (
      state,
      action: PayloadAction<
        AuthenticatedCustomer & { eventType?: FHEventType }
      >,
    ) => {
      state.data = action.payload;
      state.isBookingJourney = state.isBookingJourney;
      state.isGiftVoucherJourney = state.isGiftVoucherJourney;
      state.isEmailRegistered = state.isEmailRegistered;
      state.loaded = true;
      state.loading = false;
    },
    createLoginFail: (state, action: PayloadAction<{ message: string }>) => {
      state.loading = false;
      state.hasError = true;
      state.errorMessage = action.payload.message;
      state.isBookingJourney = state.isBookingJourney;
      state.isEmailRegistered = state.isEmailRegistered;
    },
    createAutoLoginFail: (state) => {
      state.hasError = false;
      state.loggedOutRecently = false;
      state.loading = false;
      state.loaded = false;
      state.hasAutoLoginError = true;
      state.isBookingJourney = state.isBookingJourney;
      state.isEmailRegistered = state.isEmailRegistered;
    },
    setLogoutOutStatus: (state, action: PayloadAction<boolean>) => {
      state.loggedOutRecently = action.payload;
    },
    setIsJustLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.justLoggedIn = action.payload;
    },
    setExperimentAddressData: (
      state,
      action: PayloadAction<BillingAddress | Address>,
    ) => {
      state.experimentAddressData = action.payload;
    },
    resetExperimentAddressData: (state) => {
      state.experimentAddressData = null;
    },
  },
});

export const {
  createLogin,
  createAutoLogin,
  setIsBookingJourney,
  setIsGiftVoucherJouney,
  checkEmailRegistration,
  checkEmailRegistrationSuccess,
  resetEmailRegistrationCheck,
  createLoginSuccess,
  createLoginFail,
  createAutoLoginFail,
  setLogoutOutStatus,
  setIsJustLoggedIn,
  setExperimentAddressData,
  resetExperimentAddressData,
} = authSlice.actions;

export default authSlice.reducer;
