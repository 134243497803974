import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type ActiveExperimentsState = {
  activeExperiments?: { [key: string]: string | boolean };
};

const initialState: ActiveExperimentsState = Object.freeze({
  activeExperiments: null,
});

export const experimentsSlice = createSlice({
  name: "experiments",
  initialState,
  reducers: {
    setActiveExperiments(
      state,
      action: PayloadAction<{ [key: string]: string | boolean }>,
    ) {
      state.activeExperiments = action.payload;
    },
  },
});

export const { setActiveExperiments } = experimentsSlice.actions;

export default experimentsSlice.reducer;
