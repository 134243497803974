import { useTheme, useMediaQuery } from "@mui/material";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { getBSLPaymentIPUrl } from "../components/Utils";
import { BillingAddress } from "../interfaces/customer";
import { useLoginSelector } from "../store/selectors";
import { useRouter } from "next/router";

export const useIsMobile = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("md"), {
    // without this, useMediaQuery always returns false first page load.
    noSsr: true,
  });
};

export const useIsTabletOrSmaller = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down("lg"), {
    // without this, useMediaQuery always returns false first page load.
    noSsr: true,
  });
};

export const useIsDesktopOrTablet = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.up("sm"), {
    // without this, useMediaQuery always returns false first page load.
    noSsr: true,
  });
};

export const useIsTouchEnabled = () => {
  return "intouchstart" in window || navigator.maxTouchPoints > 0;
};

export const detectIE = () => {
  if (typeof window !== "undefined") {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE");
    const trident = ua.indexOf("Trident/");
    return msie > 0 || trident > 0;
  }
};

export const detectSafari = () => {
  if (
    typeof window !== "undefined" &&
    typeof window.navigator !== "undefined"
  ) {
    /apple/i.test(navigator.vendor);
  }
};

export const useIsIE = () => detectIE();

export const isIpad = () => {
  const ua = window.navigator.userAgent.toLowerCase();
  return (
    ua.indexOf("ipad") > -1 ||
    (ua.indexOf("macintosh") > -1 && "ontouchend" in document)
  );
};

export enum ScrollDirections {
  Up = "UP",
  Down = "DOWN",
  Static = "STATIC",
}

export const useScrollDirection = () => {
  const [scrollDir, setScrollDir] = useState(ScrollDirections.Static);

  useEffect(() => {
    const threshold = 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false;
        return;
      }

      if (scrollY === 0) {
        setScrollDir(ScrollDirections.Static);
      } else {
        setScrollDir(
          scrollY > lastScrollY ? ScrollDirections.Down : ScrollDirections.Up,
        );
      }

      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [scrollDir]);

  return scrollDir;
};

export const useStickyBlock = (stickyRef: any) => {
  const [stickyState, setStickyState] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const sticky_ref: any = stickyRef.current;
      const sticky_check: any = sticky_ref?.getBoundingClientRect().top;
      if (sticky_check && sticky_check < 0) {
        setStickyState(true);
      } else {
        setStickyState(false);
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [stickyState]);
  return stickyState;
};

export const useStickyContainer = (stickyRef: any, revealStickyRef: any) => {
  const [stickyState, setStickyState] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const sticky_ref: HTMLHRElement = stickyRef.current;
      const sticky_check: number = sticky_ref?.getBoundingClientRect().top;

      const reveal_stickyRef: HTMLHRElement = revealStickyRef.current;
      const reveal_sticky_top: number =
        reveal_stickyRef?.getBoundingClientRect().top;
      const reveal_sticky_height: number =
        reveal_stickyRef?.getBoundingClientRect().height;
      if (
        sticky_check &&
        sticky_check < 0 &&
        reveal_sticky_height < reveal_sticky_top
      ) {
        setStickyState(true);
      } else {
        setStickyState(false);
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [stickyState]);
  return stickyState;
};

export const useStickyBottom = (stickyBottomRef: any) => {
  const [stickyState, setStickyState] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const sticky_ref: any = stickyBottomRef.current;
      const sticky_check: any = sticky_ref?.getBoundingClientRect().top;
      const sticky_height: number = sticky_ref?.getBoundingClientRect().height;
      if (sticky_check < sticky_height) {
        setStickyState(true);
      } else {
        setStickyState(false);
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [stickyState]);
  return stickyState;
};

export const useContainerDimensions = (scrollWidthRef: any) => {
  const getDimensions = () => ({
    width: scrollWidthRef?.current?.clientWidth || 0,
  });

  const [dimensions, setDimensions] = useState({ width: 0 });

  useEffect(() => {
    if (scrollWidthRef.current) {
      setDimensions(getDimensions());
    }

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [scrollWidthRef?.current?.clientWidth]);

  return dimensions;
};

export const useGetElementDimensions = (dimensionsRef: any) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const getDimensions = () => ({
    width: dimensionsRef?.current?.offsetWidth || 0,
    height: dimensionsRef?.current?.offsetHeight || 0,
  });

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions());
    };
    if (dimensionsRef.current) {
      setDimensions(getDimensions());
    }
    if (dimensionsRef.current !== null) {
      window.addEventListener("resize", handleResize);
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dimensionsRef]);

  return dimensions;
};

export const useGetElementHeight = (heightRef: any) => {
  const [height, setHeight] = useState({ height: 0 });

  const getHeight = () => ({
    height: heightRef?.current?.offsetHeight || 0,
  });

  useEffect(() => {
    const handleResize = () => {
      setHeight(getHeight());
    };
    if (heightRef.current) {
      setHeight(getHeight());
    }
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [heightRef]);

  return height;
};
export const useClientIPAddress = () => {
  const [clientIPAddress, setClientIPAddress] = useState("");

  useEffect(() => {
    // Get Client IP Address
    const xhr = new XMLHttpRequest();
    xhr.open("GET", getBSLPaymentIPUrl());
    xhr.send();
    xhr.addEventListener("load", () => {
      if (xhr.status === 200) {
        setClientIPAddress(xhr.responseText);
      } else {
        setClientIPAddress("");
        const error = `Failed to get client IP Address for Browser Data ${xhr.status} - ${xhr.statusText}`;
        console.log(error);
        // appInsights.trackException(error as any);
      }
    });
  }, []);

  return { clientIPAddress };
};
export const useCustomerAddressDetails = () => {
  const { data: loginData } = useLoginSelector();
  const customerAddress = loginData?.customer?.address;

  const [address, setAddress] = useState<BillingAddress>({
    addressLine1: "",
    addressLine2: "",
    city: "",
    postCode: "",
    country: "United Kingdom",
  });

  useEffect(() => {
    if (!loginData || !customerAddress) return;

    setAddress({
      addressLine1: customerAddress.addressLine1,
      addressLine2: customerAddress.addressLine2 || "",
      city: customerAddress.addressLine3 || "",
      postCode: customerAddress.postCode,
      country: customerAddress.country || "United Kingdom",
    });
  }, [loginData]);

  return { address, setAddress };
};

export const stickyContainer = (stickyRef: any, revealStickyRef: any) => {
  const [stickyState, setStickyState] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const sticky_ref: HTMLHRElement = stickyRef.current;
      const sticky_check: number = sticky_ref?.getBoundingClientRect().top;

      const reveal_stickyRef: HTMLHRElement = revealStickyRef.current;
      const reveal_sticky_top: number =
        reveal_stickyRef?.getBoundingClientRect().top;
      const reveal_sticky_height: number =
        reveal_stickyRef?.getBoundingClientRect().height;
      if (
        sticky_check &&
        sticky_check < 0 &&
        reveal_sticky_height < reveal_sticky_top
      ) {
        setStickyState(true);
      } else {
        setStickyState(false);
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [stickyState]);
  return stickyState;
};

export const stickyBottom = (stickyBottomRef: any) => {
  const [stickyState, setStickyState] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const sticky_ref: any = stickyBottomRef.current;
      const sticky_check: any = sticky_ref?.getBoundingClientRect().top;
      const sticky_height: number = sticky_ref?.getBoundingClientRect().height;
      if (sticky_check < sticky_height) {
        setStickyState(true);
      } else {
        setStickyState(false);
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [stickyState]);
  return stickyState;
};

export const useIsClient = () => {
  const [isClient, setClient] = useState(false);

  useEffect(() => {
    setClient(true);
  }, []);

  return isClient;
};

export const useOnScreen = <T extends Element>(
  ref: MutableRefObject<T>,
): Boolean => {
  const [isVisibile, setIsVisible] = useState<Boolean>();

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      // Update our state when observer callback fires
      setIsVisible(entry?.isIntersecting);
    });
    if (ref?.current) {
      observer?.observe(ref.current);
    }
    return () => {
      if (ref?.current) {
        observer?.observe(ref.current);
      }
    };
  }, []);

  return isVisibile;
};

export const useRouteInfo = () => {
  const router = useRouter();
  const latestRouteRef = useRef<string>(router.asPath);
  const [routeLoaded, setRouteLoaded] = useState<boolean>(false);

  useEffect(() => {
    const handleRouteChangeStart = () => {
      setRouteLoaded(false);
    };

    const handleRouteChangeComplete = (url: string) => {
      latestRouteRef.current = url;
      setRouteLoaded(true);
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);
    router.events.on("routeChangeComplete", handleRouteChangeComplete);

    // Clean up the event listeners on unmount
    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
      router.events.off("routeChangeComplete", handleRouteChangeComplete);
    };
  }, [router]);

  useEffect(() => {
    if (router.isReady) {
      setRouteLoaded(true);
    }
  }, [router.isReady]);

  return {
    latestRoute: latestRouteRef.current,
    routeLoaded,
  };
};
