import { Nullable } from "../utils";

export type GvUserSelectedOptions = {
  imageUrl: string;
  color: string;
  price: Nullable<number>;
  qty: number;
  title: string;
};

export enum GvStepperTag { // gv journey stepper tag names
  select = "select",
  details = "details",
  login = "login",
  payment = "payment",
  confirm = "confirm",
}

export type GvJourneyStep = {
  label: string;
  url?: string;
  tag?: string;
  completed?: boolean;
};
