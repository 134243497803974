import { createSlice } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import {
  MutationApplyPromoCodeArgs,
  MutationRemovePromoCodeArgs,
  ValidatePromoCodeRequestInput,
  ValidatePromoCodeType,
} from "@generated/types";
import type { PayloadAction } from "@reduxjs/toolkit";
import { PromoCodeSuccessType } from "../../interfaces/promoCode";

export type PromoCodeState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<ValidatePromoCodeType>;
  discountCode: Nullable<string>;
  summaryCodeApplicable: Nullable<string>;
  summaryCodeDescription: Nullable<string>;
};

export type RemovePromoCodeActionProps = {
  props: MutationRemovePromoCodeArgs;
  isPostBooking: boolean;
};

const initialState: PromoCodeState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
  discountCode: null,
  summaryCodeApplicable: null,
  summaryCodeDescription: null,
});

export const promoCodeSlice = createSlice({
  name: "promoCode",
  initialState,
  reducers: {
    setDiscountCode: (state, action: PayloadAction<string>) => {
      state.discountCode = action.payload;
    },
    checkPromoCode: (
      state,
      action: PayloadAction<ValidatePromoCodeRequestInput>
    ) => {
      state.loading = true;
      state.loaded = false;
      state.data = null;
      state.discountCode = null;
      state.summaryCodeApplicable = null;
      state.summaryCodeDescription = null;
      state.errorMessage = null;
      state.hasError = false;
    },
    checkPromoCodeSuccess: (
      state,
      action: PayloadAction<ValidatePromoCodeType>
    ) => {
      state.data = action.payload;
      state.loaded = true;
      state.discountCode = null;
      state.summaryCodeApplicable = null;
      state.summaryCodeDescription = null;
      state.errorMessage = null;
      state.hasError = false;
      state.loading = false;
    },
    checkPromoCodeFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loading = false;
      state.loaded = false;
      state.data = null;
      state.discountCode = null;
      state.summaryCodeApplicable = null;
      state.summaryCodeDescription = null;
    },
    resetCheckPromoCode: (state) => initialState,
    applyPromoCode: (
      state,
      action: PayloadAction<MutationApplyPromoCodeArgs>
    ) => {
      state.loading = true;
      state.loaded = false;
      state.data = null;
      state.discountCode = null;
      state.summaryCodeApplicable = null;
      state.summaryCodeDescription = null;
      state.errorMessage = null;
      state.hasError = false;
    },
    applyPromoCodeSuccess: (
      state,
      action: PayloadAction<PromoCodeSuccessType>
    ) => {
      state.summaryCodeApplicable = action.payload.discountCode;
      state.summaryCodeDescription = action.payload.description || null;
      state.loading = false;
      state.loaded = true;
      state.data = null;
      state.discountCode = null;
      state.errorMessage = null;
      state.hasError = false;
    },
    applyPromoCodeFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.loaded = true;
      state.hasError = true;
      state.errorMessage = action.payload;
      state.data = null;
      state.discountCode = null;
      state.summaryCodeApplicable = null;
      state.summaryCodeDescription = null;
    },
    removePromoCodeSuccess: (state, action: PayloadAction<boolean>) => {
      state.errorMessage = null;
      state.hasError = false;
      state.loaded = false;
      state.loading = false;
      state.data = null;
      state.discountCode = null;
      state.summaryCodeApplicable = null;
      state.summaryCodeDescription = null;
    },
    removePromoCodeFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loaded = true;
      state.loading = false;
      state.data = null;
      state.discountCode = null;
      state.summaryCodeApplicable = null;
      state.summaryCodeDescription = null;
    },
    setChecked: (state, action: PayloadAction<PromoCodeSuccessType>) => {
      state.summaryCodeApplicable = action.payload.discountCode;
      state.summaryCodeDescription = action.payload.description || null;
    },
    removePromoCode: (
      state,
      action: PayloadAction<RemovePromoCodeActionProps>
    ) => {},
  },
});

export const {
  setDiscountCode,
  applyPromoCode,
  applyPromoCodeFail,
  applyPromoCodeSuccess,
  checkPromoCode,
  checkPromoCodeFail,
  checkPromoCodeSuccess,
  removePromoCodeFail,
  removePromoCodeSuccess,
  resetCheckPromoCode,
  setChecked,
  removePromoCode,
} = promoCodeSlice.actions;

export default promoCodeSlice.reducer;
