import { createSlice } from "@reduxjs/toolkit";
import { Customer } from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type AccountState = {
  customer?: Nullable<Customer>;
  loading: boolean;
  loaded: boolean;
  hasError: boolean;
  errorMessage: Nullable<string>;
  account?: any;
  bookingId?: string;
  cabinId?: string;
  customerId?: string;
  token?: string;
};

const initialState: AccountState = Object.freeze({
  customer: null,
  loading: false,
  loaded: false,
  hasError: false,
  errorMessage: null,
});

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    getCustomer: (state, action: PayloadAction<string>) => ({
      ...initialState,
      loading: true,
      bookingId: state.bookingId,
      cabinId: state.cabinId,
      customerId: state.customerId,
      token: state.token,
    }),
    getCustomerSuccess: (state, action: PayloadAction<Customer>) => {
      state.customer = action.payload;
      state.loading = false;
      state.loaded = true;
    },
    getCustomerFail: (state, action: PayloadAction<string>) => ({
      ...initialState,
      errorMessage: action.payload,
      hasError: true,
      bookingId: state.bookingId,
      cabinId: state.cabinId,
      customerId: state.customerId,
      token: state.token,
    }),
    setAccount: (state, action: PayloadAction<any>) => {
      state.account = action.payload;
    },
    setCustomerId: (state, action: PayloadAction<string>) => {
      state.customerId = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
});

export const {
  getCustomer,
  getCustomerSuccess,
  getCustomerFail,
  setAccount,
  setCustomerId,
  setToken,
} = accountSlice.actions;

export default accountSlice.reducer;
