import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import {
  MutationRemoveSavedCardArgs,
  RemoveSavedCardResponse,
} from "@generated/types";

export type RemoveCardsState = {
  loading: boolean;
  data: Nullable<RemoveSavedCardResponse>;
  errorMessage: Nullable<string>;
  hasError: boolean;
};

const initialState: RemoveCardsState = Object.freeze({
  loading: false,
  data: null,
  errorMessage: null,
  hasError: false,
});

export const removeCardsSlice = createSlice({
  name: "removedCards",
  initialState,
  reducers: {
    getRemoveCardsAction: (
      state,
      action: PayloadAction<MutationRemoveSavedCardArgs>
    ) => {
      state.loading = true;
      state.data = null;
      state.errorMessage = null;
      state.hasError = false;
    },
    getRemoveCardsSuccess: (
      state,
      action: PayloadAction<RemoveSavedCardResponse>
    ) => {
      state.data = action.payload;
      state.loading = false;
      state.errorMessage = null;
      state.hasError = false;
    },
    getRemoveCardsFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loading = false;
      state.data = null;
    },
    resetRemovedCardAction: (state) => {
      state.loading = false;
      state.data = null;
      state.errorMessage = null;
      state.hasError = false;
    },
  },
});

export const {
  getRemoveCardsAction,
  getRemoveCardsFail,
  getRemoveCardsSuccess,
  resetRemovedCardAction,
} = removeCardsSlice.actions;

export default removeCardsSlice.reducer;
