import { combineReducers } from "redux";
import featuredExtraListSlice, {
  FeaturedExtraListState,
} from "./featured-extra-list.slice";
import featuredExtraRemoveSlice, {
  RemoveFeaturedExtraState,
} from "./featured-extra-remove";
import featuredExtraAddSlice, {
  AddFeaturedExtraState,
} from "./featured-extras-add.slice";

export type FeaturedExtraState = {
  featuredExtrasList: FeaturedExtraListState;
  addFeaturedExtra: AddFeaturedExtraState;
  removeFeaturedExtra: RemoveFeaturedExtraState;
};

export const featuredExtraReducer = combineReducers<FeaturedExtraState>({
  featuredExtrasList: featuredExtraListSlice,
  addFeaturedExtra: featuredExtraAddSlice,
  removeFeaturedExtra: featuredExtraRemoveSlice,
});
