import { createSlice } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";
import { MutationAddCustomerToBookingArgs } from "@generated/types";

export type BookingCustomerAddState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<boolean>;
};

const initialState: BookingCustomerAddState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const bookingCustomerAddSlice = createSlice({
  name: "bookingCustomerAdd",
  initialState,
  reducers: {
    addCustomerToBooking: (
      state,
      action: PayloadAction<MutationAddCustomerToBookingArgs>
    ) => {
      state.loading = true;
      state.data = null;
      state.errorMessage = null;
      state.loaded = false;
      state.hasError = false;
    },
    addCustomerToBookingSuccess: (state, action: PayloadAction<boolean>) => {
      state.data = action.payload;
      state.errorMessage = null;
      state.loaded = true;
      state.hasError = false;
      state.loading = false;
    },
    addCustomerToBookingFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.data = null;
      state.errorMessage = action.payload;
      state.loaded = false;
      state.hasError = true;
    },
  },
});

export const {
  addCustomerToBooking,
  addCustomerToBookingSuccess,
  addCustomerToBookingFail,
} = bookingCustomerAddSlice.actions;

export default bookingCustomerAddSlice.reducer;
