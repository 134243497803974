import {
  ConfirmExtrasResponse,
  MutationConfirmFreeExtrasArgs,
} from "@generated/types";
import { Nullable } from "../../utils";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type CreateConfirmFreeExtrasState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<ConfirmExtrasResponse>;
};

const initialState: CreateConfirmFreeExtrasState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const confirmFreeExtrasCreateSlice = createSlice({
  name: "confirmFreeExtras",
  initialState,
  reducers: {
    createConfirmFreeExtras: (
      state,
      action: PayloadAction<MutationConfirmFreeExtrasArgs>
    ) => {
      state.loading = true;
      state.errorMessage = null;
      state.hasError = false;
      state.loaded = false;
      state.data = null;
    },
    createConfirmFreeExtrasSuccess: (
      state,
      action: PayloadAction<ConfirmExtrasResponse>
    ) => {
      state.data = action.payload;
      state.loaded = true;
      state.loading = false;
      state.errorMessage = null;
      state.hasError = false;
    },
    createConfirmFreeExtrasFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage =
        action?.payload || "An error occurred. Please try again..";
      state.loaded = true;
      state.loading = false;
      state.data = null;
    },
    clearConfirmFreeExtras: (state, action) => initialState,
  },
});

export const {
  clearConfirmFreeExtras,
  createConfirmFreeExtras,
  createConfirmFreeExtrasFail,
  createConfirmFreeExtrasSuccess,
} = confirmFreeExtrasCreateSlice.actions;

export default confirmFreeExtrasCreateSlice.reducer;
