import { createSlice } from "@reduxjs/toolkit";
import {
  ExtrasStockAvailabilityResponse,
  QueryExtrasStockAvailabilityArgs,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type ExtrasStockControlState = {
  extrasStockControlItems: Nullable<ExtrasStockAvailabilityResponse[]>;
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
};

const initialState: ExtrasStockControlState = Object.freeze({
  extrasStockControlItems: null,
  hasError: false,
  errorMessage: null,
  loaded: false,
  loading: false,
});

export const extrasStockControlSlice = createSlice({
  name: "extrasStock",
  initialState,
  reducers: {
    getExtrasStock: (
      state,
      action: PayloadAction<QueryExtrasStockAvailabilityArgs>
    ) => {
      state.loading = true;
    },
    getExtrasStockSuccess: (
      state,
      action: PayloadAction<ExtrasStockAvailabilityResponse>
    ) => {
      const extrasStockControlItems = state.extrasStockControlItems ?? [
        action.payload,
      ];

      state.loading = false;
      state.loaded = true;
      state.extrasStockControlItems = extrasStockControlItems;
    },
    getExtrasStockFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.extrasStockControlItems = null;
      state.loaded = false;
      state.loading = false;
    },
    resetExtrasStock: (state, action) => ({
      ...initialState,
    }),
  },
});

export const {
  getExtrasStock,
  getExtrasStockSuccess,
  getExtrasStockFail,
  resetExtrasStock,
} = extrasStockControlSlice.actions;

export default extrasStockControlSlice.reducer;
