import { createSlice } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";
import { FaqCategory_S as FaqCategory, FaQsResponse } from "@generated/types";

export type FaqState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  faqCategories: Nullable<FaqCategory[]>;
};

const initialState: FaqState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  faqCategories: null,
});

export const faqsSlice = createSlice({
  name: "faqs",
  initialState,
  reducers: {
    getFaqAction: (state, action: PayloadAction<void>) => {
      state.loading = true;
    },
    getFaqSuccessAction: (state, action: PayloadAction<FaQsResponse>) => {
      const faqCategories = action.payload.fAQCategories as FaqCategory[];

      return {
        ...state,
        faqCategories,
        loaded: true,
        loading: false,
      };
    },
    getFaqFailAction: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage =
        action?.payload || "An error occurred. Please try again.";
      state.faqCategories = null;
      state.loaded = true;
      state.loading = false;
    },
  },
});

export const { getFaqAction, getFaqSuccessAction, getFaqFailAction } =
  faqsSlice.actions;

export default faqsSlice.reducer;
