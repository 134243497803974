import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  getPostCodeAddressesFail,
  getPostCodeAddressesSuccess,
  getPostCodeAddresses,
} from "../slices";

const url = process.env.NEXT_PUBLIC_POSTCODE_LOOKUP_URL;
const headers = new Headers({
  Origin: process.env.NEXT_PUBLIC_BASE_URL,
});

function* getPostCodeAddressesSaga(
  action: ReturnType<typeof getPostCodeAddresses>
) {
  const request = { postcode: action?.payload?.postCode };
  const getPostCodeCall = () =>
    fetch(url, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(request),
    }).then((response) => response.json());

  try {
    const response = yield call(getPostCodeCall);

    if (response) {
      yield put(getPostCodeAddressesSuccess(response));
    } else {
      throw Error("Failed to get post code addresses");
    }
  } catch (error) {
    yield put(getPostCodeAddressesFail(error.message));
  }
}

export default function* postCodeSagas() {
  yield all([
    takeLatest(
      "postCodeAddresses/getPostCodeAddresses",
      getPostCodeAddressesSaga
    ),
  ]);
}
