import { getCookie, hasCookie } from "cookies-next";
import { FHCookies } from "src/interfaces/cookies";
import TagManager from "react-gtm-module";

enum CROEventType {
  experiments = "experiments",
  viewedPreBookingCabinSummary = "viewedPreBookingCabinSummary",
}

export enum mandatoryExtraCode {
  ice = "ICE",
  compICE = "COMPICE",
  flex = "CCANCS",
  break = "BREAK",
  iceBrecon = "BRECOTRUM",
  iceSherwood = "SWDOTRUM",
  iceSilverBirch = "OTRUMSB",
  iceForestOfDean = "FODOTRUM",
  iceArgyll = "AAOTRUM",
  iceStratyre = "STROTRUM",
  iceBeddgelert = "BGOTRUM",
  iceThorpe = "THPOTRUM",
  iceDeerpark = "DPOTRUM",
  iceBlackwood = "BWDOTRUM",
  iceGlentress = "GLTOTRUM",
  iceDelamere = "DELOTRUM",
  iceCropton = "CRPOTRUM",
  iceKeldy = "KELOTRUM",
}

export const getMandatoryExtraCodes = () => {
  return [
    mandatoryExtraCode.ice,
    mandatoryExtraCode.flex,
    mandatoryExtraCode.break,
    mandatoryExtraCode.iceBrecon,
    mandatoryExtraCode.iceSherwood,
    mandatoryExtraCode.iceSilverBirch,
    mandatoryExtraCode.iceForestOfDean,
    mandatoryExtraCode.iceArgyll,
    mandatoryExtraCode.iceStratyre,
    mandatoryExtraCode.iceBeddgelert,
    mandatoryExtraCode.iceThorpe,
    mandatoryExtraCode.iceDeerpark,
    mandatoryExtraCode.iceBlackwood,
    mandatoryExtraCode.iceGlentress,
    mandatoryExtraCode.iceDelamere,
    mandatoryExtraCode.iceCropton,
    mandatoryExtraCode.iceKeldy,
  ];
};

export const pushActiveExperimentsToDataLayer = () => {
  const activeExperiments = hasCookie(FHCookies.active)
    ? JSON.parse(getCookie(FHCookies.active) as string)
    : { control: [], active: [] };

  TagManager.dataLayer({
    dataLayer: {
      event: CROEventType.experiments,
      active: activeExperiments,
    },
  });
};

export const pushViewedPreBookingCabinSummaryToDataLayer = (
  petsComponentVisible: boolean,
) => {
  TagManager.dataLayer({
    dataLayer: {
      event: CROEventType.viewedPreBookingCabinSummary,
      petsComponentVisible: petsComponentVisible,
    },
  });
};
