import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { deleteCookie, setCookie } from "cookies-next";
import { get400DaysAfterDateFromCurrentDate } from "src/utils";

export type AuthRememberMeState = {
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  authRememberSuccess: boolean;
  isValidXFhAuthCookie: boolean;
};

const initialState: AuthRememberMeState = Object.freeze({
  loaded: false,
  loading: false,
  hasError: false,
  authRememberSuccess: false,
  isValidXFhAuthCookie: true,
});

export const authRememberMeSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginRememberMe: (
      state,
      action: PayloadAction<{ token: string; uid: string }>
    ) => {
      state.loading = true;
    },
    loginRememberMeSuccess: (state, action: PayloadAction<{ uid: string }>) => {
      state.loaded = true;
      state.loading = false;
      state.authRememberSuccess = true;
      setCookie("SignedInDeviceId", action.payload.uid, {
        expires: get400DaysAfterDateFromCurrentDate(),
      });
    },
    loginRememberMeFailed: (state) => {
      state.authRememberSuccess = false;
      state.hasError = true;
      state.loaded = true;
      state.loading = false;
    },
    validateXFhAuthCookie: (state, action: PayloadAction<{ uid: string }>) => {
      state.isValidXFhAuthCookie = false;
    },
    validateXFhAuthCookieSuccess: (
      state,
      action: PayloadAction<{ uid: string }>
    ) => {
      state.isValidXFhAuthCookie = true;
      setCookie("SignedInDeviceId", action.payload.uid, {
        expires: get400DaysAfterDateFromCurrentDate(),
      });
    },
    validateXFhAuthCookieFailed: (state, action) => {
      state.isValidXFhAuthCookie = false;
    },
  },
});

export const {
  loginRememberMe,
  loginRememberMeFailed,
  loginRememberMeSuccess,
  validateXFhAuthCookie,
  validateXFhAuthCookieFailed,
  validateXFhAuthCookieSuccess,
} = authRememberMeSlice.actions;

export default authRememberMeSlice.reducer;
