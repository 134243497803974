import { useSelector } from "react-redux";
import { RootState } from "../slices";

export const stickySelector = (state: RootState) => state.sticky;

export const stickyCabinSelector = (state: RootState) => state.sticky.cabin;
export const stickyCabinBedroomSelector = (state: RootState) =>
  state.sticky.cabinBedroom;
export const stickyCabinDetailsUrlSelector = (state: RootState) =>
  state.sticky.cabinDetailsUrl;
export const stickyLocationSelector = (state: RootState) =>
  state.sticky.location;
export const stickyLocationDetailsUrlSelector = (state: RootState) =>
  state.sticky.locationDetailsUrl;
export const stickyGuestsSelector = (state: RootState) => state.sticky.guests;
export const stickyDdaSelector = (state: RootState) => state.sticky.dda;
export const stickyDateRangeSelector = (state: RootState) =>
  state.sticky.dateRange;
export const isInterstitialBookingSelector = (state: RootState) =>
  state.sticky.isInterstitialBooking;
export const getInterstitialPagePathSelector = (state: RootState) =>
  state.sticky.interstitialPagePathName;
export const stickyExpirySelector = (state: RootState) => state.sticky.expiry;

export const stickyConfirmedCabinReservationId = (state: RootState) =>
  state.sticky.confirmedCabinReservationId;
export const stickyLearneMoreClicked = (state: RootState) =>
  state.sticky.isLearnMoreClicked;
export const activeStepperPersistedSelector = (state: RootState) =>
  state.activeStepperSetting;

export const useActiveStepperPersistedSelector = () =>
  useSelector(activeStepperPersistedSelector);
export const useStickySelector = () => useSelector(stickySelector);
