import { RootState } from "../slices";
import { useSelector } from "react-redux";
import { GvJourneyStep, GvStepperTag } from "../../interfaces/giftvouchers";
import { getGvStepByTag } from "../../utils";

export const gvSettingsSelector = (state: RootState) => state.gvSettings;
export const gvPaymentUrlSelector = (state: RootState) =>
  state.gvSettings.gvBookingSteps?.find(
    (bookingSteps) => bookingSteps?.tag === GvStepperTag.payment
  );
export const gvBookingStepsSelector = (state: RootState) =>
  state.gvSettings.gvBookingSteps;
export const addGiftVoucherStateSelector = (state: RootState) =>
  state.giftVoucherAdd;
export const getGiftVoucherBasketStateSelector = (state: RootState) =>
  state.giftVoucherBasket;
export const gvBasketIdPersistedStateSelector = (state: RootState) =>
  state.gvBasketIdPersisted;
export const giftVouchersSaved = (state: RootState) => state.giftVouchersSaved;
export const gvActiveStepSelector = (state: RootState) =>
  (state.gvSettings.gvBookingSteps || [])[state.gvSettings.activeGvStepIndex] ||
  null;
export const giftVouchersPayment = (state: RootState) =>
  state.giftVouchersPayment;
export const gvpreviousFromActiveStepSelector = (
  state: RootState,
  index: number = state.gvSettings.activeGvStepIndex
): GvJourneyStep => {
  const previousStep =
    (state.gvSettings.gvBookingSteps || [])[index - 1] || null;
  if (previousStep?.tag === GvStepperTag.login) {
    return gvpreviousFromActiveStepSelector(state, index - 1);
  }
  return previousStep;
};
export const gvnextFromActiveStepSelector = (state: RootState) =>
  (state.gvSettings.gvBookingSteps || [])[
    state.gvSettings.activeGvStepIndex + 1
  ] || null;
export const getConfirmedGiftVoucherBasketStateSelector = (state: RootState) =>
  state.giftVoucherConfirmedBasket;

export const useGvSettingsSelector = () => useSelector(gvSettingsSelector);
export const useAddGiftVoucherStateSelector = () =>
  useSelector(addGiftVoucherStateSelector);
export const useGetGiftVoucherBasketStateSelector = () =>
  useSelector(getGiftVoucherBasketStateSelector);
export const useGVBasketIdPersistedtStateSelector = () =>
  useSelector(gvBasketIdPersistedStateSelector);
export const useGiftVouchersSavedStateSelector = () =>
  useSelector(giftVouchersSaved);
export const useGiftVouchersPaymentStateSelector = () =>
  useSelector(giftVouchersPayment);
export const useGvPaymentUrlSelector = () => useSelector(gvPaymentUrlSelector);
export const useGvFindStepSelector = (tag: GvStepperTag) =>
  getGvStepByTag(tag, useSelector(gvBookingStepsSelector));
export const useGvActiveStepSelector = () => useSelector(gvActiveStepSelector);
export const useGVPreviousFromActiveStepSelector = () =>
  useSelector(gvpreviousFromActiveStepSelector);
export const useGVNextFromActiveStepSelector = () =>
  useSelector(gvnextFromActiveStepSelector);
export const useGetConfirmedGiftVoucherBasketStateSelector = () =>
  useSelector(getConfirmedGiftVoucherBasketStateSelector);
