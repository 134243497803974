import { createSlice } from "@reduxjs/toolkit";
import { AddExtraRequestInput, AddExtraResponse } from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type AddFeaturedExtraState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<AddExtraResponse[]>;
  addExtraRequest?: AddExtraRequestInput[];
  isPostBookingJourney?: boolean;
};

const initialState: AddFeaturedExtraState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
  isPostBookingJourney: false,
});

export const featuredExtraAddSlice = createSlice({
  name: "addFeaturedExtra",
  initialState,
  reducers: {
    addFeaturedExtra: (
      state,
      action: PayloadAction<{
        addFeaturedExtraInput: AddExtraRequestInput[];
        showSticky: boolean;
        isPostBookingJourney: boolean;
      }>,
    ) => {
      state.addExtraRequest = action.payload.addFeaturedExtraInput;
      state.loading = true;
      state.isPostBookingJourney = action.payload.isPostBookingJourney;
      state.errorMessage = null;
      state.loaded = false;
      state.data = null;
      state.hasError = false;
    },
    addFeaturedExtraSuccess: (
      state,
      action: PayloadAction<{
        addFeaturedExtraResponse: AddExtraResponse[];
        isPostBookingJourney: boolean;
      }>,
    ) => ({
      ...initialState,
      addExtraRequest: state.addExtraRequest,
      data: action.payload.addFeaturedExtraResponse,
      loaded: true,
      errorMessage: null,
      isPostBookingJourney: action.payload.isPostBookingJourney,
    }),
    addFeaturedExtraFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action?.payload;
    },
    addFeaturedExtraResetState: (state) => ({
      ...initialState,
    }),
  },
});

export const {
  addFeaturedExtra,
  addFeaturedExtraSuccess,
  addFeaturedExtraFail,
  addFeaturedExtraResetState,
} = featuredExtraAddSlice.actions;

export default featuredExtraAddSlice.reducer;
