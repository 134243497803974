import { createSlice } from "@reduxjs/toolkit";

const initialState = null;
export const windowLocationSlice = createSlice({
  name: "windowLocation",
  initialState,
  reducers: {
    onWindowLocationUpdated: () => {
      /* no state update required.*/
    },
  },
});

export const { onWindowLocationUpdated } = windowLocationSlice.actions;
