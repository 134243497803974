import moment from "moment";
import { useSelector } from "react-redux";
import { isDateNowOrInFuture, isValidArrayOfGuids } from "../../utils/common";
import { DateRange } from "../../interfaces/bookingForm";
import { isGuid } from "../../utils";
import { RootState } from "../slices";
import { filterXSS } from "xss";
import { Guests } from "src/interfaces/guests";

export const bookingPersistedStateSelector = (state: RootState) =>
  state.bookingPersisted;
export const bookingSearchSelectedLocationIds = (state: RootState) =>
  state.bookingSearch.selectedLocationIds;
export const bookingSearchDateRangeSelector = (state: RootState) => {
  const { startDateISO, endDateISO } = state.bookingSearch.dateRange;
  const startDate = moment(startDateISO).format("YYYY-MM-DD");
  const endDate = moment(endDateISO).format("YYYY-MM-DD");
  return {
    startDateISO: startDate,
    endDateISO: endDate,
  };
};

export const bookingSearchGuestsSelector = (state: RootState) =>
  state.bookingSearch.guests;
export const useBookingPersistedStateSelector = () =>
  useSelector(bookingPersistedStateSelector);
export const useCabinReservationIdSelector = () =>
  useSelector(bookingPersistedStateSelector).cabinReservationId;
export const useBookingIdSelector = () =>
  useSelector(bookingPersistedStateSelector).bookingId;
export const useEssentialExtrasSelector = () =>
  useSelector(bookingPersistedStateSelector).essentialExtras;

export const abandonedBasketSelector = (state: RootState) =>
  state.abandonedBasket;
export const useAbandonedBasketSelector = () =>
  useSelector(abandonedBasketSelector);

export const bookingSearchSelector = (state: RootState) => state.bookingSearch;
export const bookingSummarySelector = (state: RootState) =>
  state.bookingSummary;
export const bookingUpcomingSelector = (state: RootState) =>
  state.bookingUpcoming;

export const confirmedBookingSummarySelector = (state: RootState) =>
  state.confirmedBookingSummary;
export const confirmedBookingInfoSelector = (state: RootState) =>
  state.confirmedBookingInfo;
export const confirmedBookingCabinEssentials = (state: RootState) =>
  state.confirmedBookingCabinEssentials;

export const useBookingSummaryStateSelector = () =>
  useSelector(bookingSummarySelector);

export const useConfirmedBookingSummaryStateSelector = () =>
  useSelector(confirmedBookingSummarySelector);

export const useConfirmedBookingInfoStateSelector = () =>
  useSelector(confirmedBookingInfoSelector);

export const useConfirmedBookingCabinEssentialsStateSelector = () =>
  useSelector(confirmedBookingCabinEssentials);

export const useBookingSearchStateSelector = () =>
  useSelector(bookingSearchSelector);

export const useBookingSearchDateRange = () =>
  useSelector(bookingSearchDateRangeSelector);
export const useBookingSearchDda = () =>
  useSelector((state: RootState) => state.bookingSearch.dda);
export const useBookingSearchGuests = () =>
  useSelector(bookingSearchGuestsSelector);
export const useBookingSearchSelectedLocationIds = () =>
  useSelector(bookingSearchSelectedLocationIds);
export const useBookingSearchPromoCode = () =>
  useSelector((state: RootState) => state.bookingSearch.promoCode);

export const useUpcomingBookingStateSelector = () =>
  useSelector(bookingUpcomingSelector);

export const futureBookingsSelector = (state: RootState) =>
  state.futureBookings;
export const useFutureBookingsSelector = () =>
  useSelector(futureBookingsSelector);

export const singleFutureCabinReservationSelector = (state: RootState) =>
  state.singleFutureCabinReservation;
export const useSingleFutureCabinReservationSelector = () =>
  useSelector(singleFutureCabinReservationSelector);

export const useBookingQueryStringSelector = ():
  | {
      dateRange: DateRange;
      selectedLocationIds: string[];
      guests: Guests;
      dda: boolean;
      queryDataIsValid: boolean;
    }
  | undefined => {
  let urlSearch;
  if (typeof window !== "undefined") {
    urlSearch = filterXSS(window.location.search);
  }
  const query = new URLSearchParams(urlSearch);

  const parsed = {
    l: decodeURIComponent(query.get("l") || ""),
    d: decodeURIComponent(query.get("d") || ""),
    sd: decodeURIComponent(query.get("sd") || ""),
    dda: decodeURIComponent(query.get("dda") || "false"),
    a: decodeURIComponent(query.get("a") || "0"),
    b: decodeURIComponent(query.get("b") || "0"),
    c: decodeURIComponent(query.get("c") || "0"),
    i: decodeURIComponent(query.get("i") || "0"),
    p: decodeURIComponent(query.get("p") || "0"),
  };

  const adults = +parsed.a;
  const bedrooms = +parsed.b;
  const children = +parsed.c;
  const infants = +parsed.i;
  const pets = +parsed.p;

  const hasQueryStringValues = urlSearch?.trim().length > 0;
  const queryLocationIds = parsed.l.split(",");

  const duration = +parsed.d;
  const momentStartDate = moment(parsed.sd, "YYYY/MM/DD");

  if (hasQueryStringValues && queryLocationIds.every(isGuid)) {
    const dateRangeValid =
      momentStartDate.isValid() && duration > 0 && duration <= 14;

    const queryDateRange = {
      startDateISO: dateRangeValid
        ? moment(momentStartDate).format("YYYY-MM-DD")
        : null,
      endDateISO: dateRangeValid
        ? momentStartDate.add(duration, "days").format("YYYY-MM-DD")
        : null,
    };

    const locationIsValid = isValidArrayOfGuids(queryLocationIds);
    const dateIsSameOrAfteNow = isDateNowOrInFuture(parsed.sd);

    return {
      dateRange: queryDateRange as DateRange,
      selectedLocationIds: queryLocationIds,
      guests: {
        adults: adults,
        bedrooms: bedrooms,
        children: children,
        infants: infants,
        pets: pets,
        guestTotal: adults + children,
      },
      dda: parsed.dda === "true",
      queryDataIsValid:
        dateRangeValid && locationIsValid && dateIsSameOrAfteNow,
    };
  }
};

export const useInterstitialBookingQueryStringSelector = ():
  | {
      startDate: string;
      cabinId: string;
      duration: number;
      guests: Guests;
      price?: number;
      referrer?: string;
      queryDataIsValid?: boolean;
      trackingId?: number;
    }
  | undefined => {
  let urlSearch;
  if (typeof window !== "undefined") {
    urlSearch = filterXSS(window.location.search);
  }
  const query = new URLSearchParams(urlSearch);

  const parsed = {
    caid: decodeURIComponent(query.get("caid") || ""),
    sd: decodeURIComponent(query.get("sd") || ""),
    d: decodeURIComponent(query.get("d") || ""),
    a: decodeURIComponent(query.get("a") || "0"),
    b: decodeURIComponent(query.get("b") || "0"),
    c: decodeURIComponent(query.get("c") || "0"),
    i: decodeURIComponent(query.get("i") || "0"),
    p: decodeURIComponent(query.get("p") || "0"),
    pr: decodeURIComponent(query.get("pr") || "0"),
    ref: decodeURIComponent(query.get("ref") || ""),
    t: decodeURIComponent(query.get("t") || "0"),
  };

  const adults = +parsed.a;
  const bedrooms = +parsed.b;
  const children = +parsed.c;
  const infants = +parsed.i;
  const pets = +parsed.p;
  const price = +parsed.pr;
  const duration = +parsed.d?.replaceAll("/", "");
  const hasQueryStringValues = urlSearch?.trim().length > 0;
  const cabinId = parsed.caid;
  const startDate = moment(parsed.sd, "YYYY/MM/DD");
  const referrer = parsed.ref;
  const trackingId = +parsed.t;

  if (hasQueryStringValues && isGuid(cabinId)) {
    const isValidDate = startDate.isValid() && duration > 0 && duration <= 14;

    const queryStartDate = isValidDate
      ? moment(startDate).format("YYYY-MM-DD")
      : null;

    const dateIsSameOrAfteNow = isDateNowOrInFuture(parsed.sd);

    return {
      startDate: queryStartDate,
      cabinId,
      duration,
      guests: {
        adults: adults,
        bedrooms: bedrooms,
        children: children,
        infants: infants,
        pets: pets,
      },
      price: price,
      referrer,
      trackingId,
      queryDataIsValid: isValidDate && dateIsSameOrAfteNow,
    };
  }
};
