import { createSlice } from "@reduxjs/toolkit";
import {
  FeaturedExtrasResponse,
  QueryExtrasForReservationArgs,
  QueryFeaturedExtrasForReservationArgs,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ExtrasForReservationArgs } from "src/graphql/bsl/gql-generated/custom-types";

export type FeaturedExtraListState = {
  errorMessage: Nullable<any>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<FeaturedExtrasResponse>;
  getExtrasRequest?: QueryExtrasForReservationArgs | ExtrasForReservationArgs;
};

const initialState: FeaturedExtraListState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const featuredExtraListSlice = createSlice({
  name: "featuredExtrasList",
  initialState,
  reducers: {
    getFeaturedExtras: (
      state,
      action: PayloadAction<{
        featuredExtrasQuery: QueryFeaturedExtrasForReservationArgs;
      }>
    ) => {
      state.loading = true;
      state.getExtrasRequest = action?.payload?.featuredExtrasQuery;
    },
    getFeaturedExtrasSuccess: (
      state,
      action: PayloadAction<{
        featuredExtrasResponse: FeaturedExtrasResponse;
      }>
    ) => {
      state.data = action?.payload?.featuredExtrasResponse;
      state.loaded = true;
      state.loading = false;
    },
    getFeaturedExtrasFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage =
        action?.payload || "An error occurred. Please try again.";
      state.data = null;
      state.loaded = false;
      state.loading = false;
    },
  },
});

export const {
  getFeaturedExtras,
  getFeaturedExtrasSuccess,
  getFeaturedExtrasFail,
} = featuredExtraListSlice.actions;

export default featuredExtraListSlice.reducer;
