import { createSlice } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  ChangeCustomerPasswordResponseUnion,
  MutationChangeCustomerPasswordArgs,
} from "@generated/types";

export type CreateChangePasswordState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<ChangeCustomerPasswordResponseUnion>;
};

const initialState: CreateChangePasswordState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const changePasswordCreateSlice = createSlice({
  name: "changeCustomerPassword",
  initialState,
  reducers: {
    createChangePassword: (
      state,
      action: PayloadAction<MutationChangeCustomerPasswordArgs>
    ) => {
      state.loading = true;
      state.data = null;
      state.loaded = false;
      state.errorMessage = null;
      state.hasError = false;
    },
    createChangePasswordSuccess: (
      state,
      action: PayloadAction<ChangeCustomerPasswordResponseUnion>
    ) => {
      state.data = action.payload;
      state.loaded = true;
      state.loading = false;
      state.errorMessage = null;
      state.hasError = false;
    },
    createChangePasswordFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loaded = true;
      state.loading = false;
      state.data = null;
    },
  },
});
export const {
  createChangePassword,
  createChangePasswordSuccess,
  createChangePasswordFail,
} = changePasswordCreateSlice.actions;

export default changePasswordCreateSlice.reducer;
