import { createSlice } from "@reduxjs/toolkit";

const initialState = null;
export const appStateSlice = createSlice({
  name: "appState",
  initialState,
  reducers: {
    resetAppState: (state) => {},
  },
});

export const { resetAppState } = appStateSlice.actions;
