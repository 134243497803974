import { createSlice } from "@reduxjs/toolkit";
import {
  FutureBookingsResponse,
  QueryFutureBookingsArgs,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type ResetActiveBasketAndBooking ={
  isExpOn: boolean | string;
  isOnCabinOrLocationsResultsPage: boolean
};

export type FutureBookingsState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  getFutureBookingsRequest?: QueryFutureBookingsArgs;
  bookings: Nullable<FutureBookingsResponse>;
};

const initialState: FutureBookingsState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  bookings: null,
});

export const futureBookingsSlice = createSlice({
  name: "futureBookings",
  initialState,
  reducers: {
    getFutureBookings: (
      state,
      action: PayloadAction<QueryFutureBookingsArgs>,
    ) => {
      state.getFutureBookingsRequest = action.payload;
      state.loading = true;
    },
    getFutureBookingsSuccess: (
      state,
      action: PayloadAction<FutureBookingsResponse>,
    ) => {
      state.bookings = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    getFutureBookingsFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage =
        action?.payload || "An error occurred. Please try again.";
      state.loaded = true;
      state.loading = false;
      state.bookings = null;
    },
    resetActiveBasketAndBooking: (
      state,
      action?: PayloadAction<ResetActiveBasketAndBooking>,
    ) => {},
  },
});

export const {
  getFutureBookings,
  getFutureBookingsSuccess,
  getFutureBookingsFail,
  resetActiveBasketAndBooking,
} = futureBookingsSlice.actions;

export default futureBookingsSlice.reducer;
