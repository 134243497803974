import { createSlice } from "@reduxjs/toolkit";
import {
  ExtraOptionPrice,
  getObtainingQuoteExtras,
  getPriceOfObtainingQuoteExtras,
  selectedExtraOptions,
} from "../../ExtraUtils";
import {
  BookingConfirmation_S as BookingConfirmation,
  BookingSummary_S as BookingSummary,
  QueryBookingConfirmationArgs,
  ReservationExtra,
} from "@generated/types";
import { Booking } from "../../interfaces/bookingSummary";
import { SelectedExtraOption } from "../../interfaces/extras";
import { Nullable } from "../../utils";
import { ReservationExtra as UIKitReservationExtra } from "../../interfaces/bookingSummary";
import type { PayloadAction } from "@reduxjs/toolkit";

export type ConfirmedBookingSummaryState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  confirmation: Nullable<BookingConfirmation>;
  bookingSummary: Nullable<Booking>;
  selectedCabinReservationId?: string;
  getConfirmationRequest?: QueryBookingConfirmationArgs;
  cabin: Nullable<BookingSummary>;
  cabinId: Nullable<string>;
  reservationExtraOptionIds: string[];
  petQuantity: number;
  selectedExtraOptions: SelectedExtraOption[];
  obtainingQuoteExtras: UIKitReservationExtra[];
  obtainingQuoteExtrasTotalPrice: number;
  obtainingQuoteExtrasSellingPrice: number;
  experiments?: Nullable<string>;
};

const initialState: ConfirmedBookingSummaryState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  confirmation: null,
  bookingSummary: null,
  cabin: null,
  cabinId: null,
  reservationExtraOptionIds: [],
  petQuantity: 0,
  selectedExtraOptions: [],
  obtainingQuoteExtras: [],
  obtainingQuoteExtrasTotalPrice: 0,
  obtainingQuoteExtrasSellingPrice: 0,
  experiments: null,
});

export const confirmedBookingSummarySlice = createSlice({
  name: "confrimedBookingSummary",
  initialState,
  reducers: {
    getConfirmedBookingSummaryAction: (
      state,
      action: PayloadAction<{
        bookingId: QueryBookingConfirmationArgs;
        refreshObtainingQuoteExtras: boolean;
        experiments?: Nullable<string>;
      }>,
    ) => {
      state.getConfirmationRequest = action.payload.bookingId.bookingId;
      state.loading = true;
      state.experiments = action.payload.experiments;
    },
    getConfirmedBookingSummarySuccessAction: (
      state,
      action: PayloadAction<{
        bookingConfirmation: BookingConfirmation;
        refreshObtainingQuoteExtras: boolean;
      }>,
    ) => {
      const cabin = action.payload.bookingConfirmation
        .cabinReservations?.[0] as BookingSummary;
      const refreshObtainingQuoteExtras =
        action.payload.refreshObtainingQuoteExtras;

      state.confirmation = action.payload.bookingConfirmation;
      state.cabin = cabin;
      state.reservationExtraOptionIds = makeCabinReservationOptionIds(
        (cabin.reservationExtras || []) as ReservationExtra[],
      );
      state.petQuantity = cabin.noOfPetsBooked || 0;
      state.loaded = true;
      state.loading = false;
      state.selectedExtraOptions = selectedExtraOptions(
        (cabin?.reservationExtras || []) as ReservationExtra[],
      );
      state.obtainingQuoteExtras = refreshObtainingQuoteExtras
        ? getObtainingQuoteExtras(
            (cabin?.reservationExtras || []) as UIKitReservationExtra[],
          )
        : state.obtainingQuoteExtras;
      state.obtainingQuoteExtrasTotalPrice = refreshObtainingQuoteExtras
        ? getPriceOfObtainingQuoteExtras(
            (cabin?.reservationExtras || []) as UIKitReservationExtra[],
            ExtraOptionPrice.totalPrice,
          )
        : state.obtainingQuoteExtrasTotalPrice;
      state.obtainingQuoteExtrasSellingPrice = refreshObtainingQuoteExtras
        ? getPriceOfObtainingQuoteExtras(
            (cabin?.reservationExtras || []) as UIKitReservationExtra[],
            ExtraOptionPrice.sellingPrice,
          )
        : state.obtainingQuoteExtrasSellingPrice;
    },

    getConfirmedBookingSummaryFailAction: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.hasError = true;
      state.errorMessage =
        action?.payload ||
        "An error occurred with confirmed booking summary. Please try again.";
      state.loaded = false;
      state.loading = false;
      state.bookingSummary = null;
      state.cabin = null;
      state.cabinId = null;
      state.confirmation = null;
    },
    setConfirmedSelectedCabinReservationId: (
      state,
      action: PayloadAction<string>,
    ) => {
      state.selectedCabinReservationId = action.payload;
    },
    resetConfrimedBookingSummary: () => ({
      ...initialState,
    }),
    setConfirmedBookingSummary: (state, action: PayloadAction<Booking>) => {
      state.bookingSummary = action.payload;
    },
  },
});

const makeCabinReservationOptionIds = (extras: ReservationExtra[]): string[] =>
  extras
    ?.flatMap((extra) => extra?.reservationExtraOptions)
    .map((option) => option?.extraOptionId)
    .filter((id: string) => id);

export const {
  getConfirmedBookingSummaryAction,
  getConfirmedBookingSummarySuccessAction,
  getConfirmedBookingSummaryFailAction,
  setConfirmedSelectedCabinReservationId,
  resetConfrimedBookingSummary,
  setConfirmedBookingSummary,
} = confirmedBookingSummarySlice.actions;

export default confirmedBookingSummarySlice.reducer;
