import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { MutationAddCustomerToGiftVoucherBasketArgs } from "@generated/types";

const initialState = null;
export const addCustomerToGvBasketSlice = createSlice({
  name: "gvCustomer",
  initialState,
  reducers: {
    addCustomerToGvBasket: (
      state,
      action: PayloadAction<MutationAddCustomerToGiftVoucherBasketArgs>
    ) => {
      state;
    },
    addCustomerToGvBasketSuccess: (state, action: PayloadAction<boolean>) => {
      state;
    },
    addCustomerToGvBasketFail: (state, action: PayloadAction<string>) => {
      state;
    },
  },
});

export const {
  addCustomerToGvBasket,
  addCustomerToGvBasketSuccess,
  addCustomerToGvBasketFail,
} = addCustomerToGvBasketSlice.actions;
