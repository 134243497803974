import { createSlice } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import {
  GiftVoucherBasketResponse,
  QueryGiftVoucherBasketArgs,
} from "@generated/types";
import type { PayloadAction } from "@reduxjs/toolkit";

export type GiftVoucherBasketState = {
  loading: boolean;
  data: Nullable<GiftVoucherBasketResponse>;
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
};

const initialState: GiftVoucherBasketState = Object.freeze({
  loading: false,
  data: null,
  errorMessage: null,
  hasError: false,
  loaded: false,
});

export const giftVoucherBasketSlice = createSlice({
  name: "giftVoucherBasket",
  initialState,
  reducers: {
    getGiftVoucherBasket: (
      state,
      action: PayloadAction<QueryGiftVoucherBasketArgs>
    ) => {
      state.loading = true;
      state.data = null;
      state.errorMessage = null;
      state.hasError = false;
      state.loaded = false;
    },
    getGiftVoucherBasketSuccess: (
      state,
      action: PayloadAction<GiftVoucherBasketResponse>
    ) => {
      state.data = action.payload;
      state.loading = false;
      state.errorMessage = null;
      state.hasError = false;
      state.loaded = true;
    },
    getGiftVoucherBasketFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.loading = false;
      state.errorMessage = action.payload;
      state.data = null;
      state.loaded = false;
    },
  },
});

export const {
  getGiftVoucherBasket,
  getGiftVoucherBasketFail,
  getGiftVoucherBasketSuccess,
} = giftVoucherBasketSlice.actions;

export default giftVoucherBasketSlice.reducer;
