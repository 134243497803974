import { createSlice } from "@reduxjs/toolkit";
import {
  CalendarAvailabilityRequestInput,
  CalendarAvailabilityType,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type DatesState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<CalendarAvailabilityType[]>;
  startDates: CalendarAvailabilityType[];
  calendarDatesWithCabinCount: Nullable<any>;
};

const initialState: DatesState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
  startDates: [],
  calendarDatesWithCabinCount: null,
});

export const datesSlice = createSlice({
  name: "dates",
  initialState,
  reducers: {
    getAvailableDates: (
      state,
      action: PayloadAction<CalendarAvailabilityRequestInput>
    ) => {
      state.loading = true;
      state.hasError = false;
      state.loaded = false;
      state.errorMessage = null;
      state.data = null;
      state.startDates = [];
      state.calendarDatesWithCabinCount = null;
    },
    getAvailableDatesSuccess: (
      state,
      action: PayloadAction<CalendarAvailabilityType[]>
    ) => {
      state.data = action.payload;
      state.startDates = makeStartDates(action.payload);
      state.calendarDatesWithCabinCount = makeCalendarDates(action.payload);
      state.loaded = true;
      state.loading = false;
      state.hasError = false;
      state.errorMessage = null;
    },
    getAvailableDatesFail: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.hasError = true;
      state.loaded = false;
      state.errorMessage = action.payload;
      state.data = null;
      state.startDates = [];
      state.calendarDatesWithCabinCount = null;
    },
  },
});

const makeStartDates = (calendarAvailability: CalendarAvailabilityType[]) => {
  return calendarAvailability
    .map((date: CalendarAvailabilityType) => date.startDate)
    .filter(
      (value: string, index: number, self: any) => self.indexOf(value) === index
    );
};

const makeCalendarDates = (
  calendarAvailability: CalendarAvailabilityType[]
) => {
  if (!calendarAvailability.length) return null;

  const calendarDates = calendarAvailability.reduce(
    (dates: any, date: CalendarAvailabilityType) => ({
      ...dates,
      [date.startDate]: [
        ...(dates[date.startDate] || []),
        { endDate: date.endDate, cabinCount: date.cabinCount },
      ],
    }),
    {}
  );
  return calendarDates;
};

export const {
  getAvailableDates,
  getAvailableDatesSuccess,
  getAvailableDatesFail,
} = datesSlice.actions;

export default datesSlice.reducer;
