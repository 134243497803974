import { useSelector } from "react-redux";
import { RootState } from "../slices";

export const addVehicleStateSelector = (state: RootState) => state.vehicleAdd;
export const useAddVehicleStateSelector = () =>
  useSelector(addVehicleStateSelector);

export const removeVehicleStateSelector = (state: RootState) =>
  state.vehicleRemove;
export const useRemoveVehicleStateSelector = () =>
  useSelector(removeVehicleStateSelector);
