import { createSlice } from "@reduxjs/toolkit";
import { Maybe } from "graphql/jsutils/Maybe";
import {
  calculateTotalExtrasPrice,
  getBasketExtrasWithPriceCheck,
} from "../../ExtraUtils";
import {
  BasketAndAvailableExtras,
  Category_S as Category,
  Extra,
  LongIdAndShortKey,
  QueryExtrasForReservationArgs,
  RemainingChipFilter,
  ReservationExtra,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ExtrasForReservationArgs } from "src/graphql/bsl/gql-generated/custom-types";

export type ExtraListState = {
  errorMessage: Nullable<any>; // todo
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<BasketAndAvailableExtras>;
  extrasData: Nullable<BasketAndAvailableExtras>;
  totalPrice: number;
  cartExpiry: number; // todo: to be clarified where this should be coming from and what type it would be
  getExtrasRequest?: QueryExtrasForReservationArgs | ExtrasForReservationArgs;
  availableFilters: Category[];
  activeFilters: string[];
  minRemainingTagsInLists?: Maybe<number>;
  remainingChipFilters?: Maybe<RemainingChipFilter[]>;
  tagIdLookup?: Maybe<LongIdAndShortKey[]>;
  hasMore: boolean;
  totalCount: number;
  defaultSort: string;
};

const initialState: ExtraListState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
  extrasData: null,
  totalPrice: 0,
  cartExpiry: 0,
  availableFilters: [],
  activeFilters: [],
  minRemainingTagsInLists: 0,
  remainingChipFilters: [],
  tagIdLookup: [{ longId: "", shortKey: "" }],
  hasMore: false,
  totalCount: 0,
  defaultSort: "",
});

export const extraListSlice = createSlice({
  name: "extraList",
  initialState,
  reducers: {
    getExtras: (
      state,
      action: PayloadAction<{
        extrasQuery: QueryExtrasForReservationArgs | ExtrasForReservationArgs;
        filtered: boolean;
        sortMethod?: boolean;
        isExtras?: boolean;
      }>
    ) => ({
      ...state,
      loading: true,
      getExtrasRequest: action.payload.extrasQuery,
      activeFilters: state.activeFilters,
      availableFilters: state.availableFilters,
      minRemainingTagsInLists: state.minRemainingTagsInLists,
      remainingChipFilters: state.remainingChipFilters,
      tagIdLookup: state.tagIdLookup,
      defaultSort: state.defaultSort,
    }),
    getExtrasSuccess: (
      state,
      action: PayloadAction<{
        extrasResponse: BasketAndAvailableExtras;
        filtered: boolean;
        sortMethod?: boolean;
        isExtras: boolean;
      }>
    ) => {
      const { payload } = action;

      const availableFilters = action.payload.filtered
        ? state.availableFilters
        : (action.payload.extrasResponse.availableFilters as Category[]);
      const activeFilters = action.payload.filtered ? state.activeFilters : [];

      const sortOptions =
        action.payload.extrasResponse?.availableSortOptions?.sortOptions;

      return {
        ...state,
        data: {
          ...payload.extrasResponse,
          basketExtras: getBasketExtrasWithPriceCheck(
            (payload.extrasResponse.availableExtras as Extra[]) || [],
            (payload.extrasResponse.basketExtras as ReservationExtra[]) || []
          ),
        },
        extrasData: action.payload.isExtras
          ? state.extrasData
          : {
              ...payload.extrasResponse,
              basketExtras: getBasketExtrasWithPriceCheck(
                (payload.extrasResponse.availableExtras as Extra[]) || [],
                (payload.extrasResponse.basketExtras as ReservationExtra[]) ||
                  []
              ),
            },
        loaded: true,
        loading: false,
        totalPrice: calculateTotalExtrasPrice(
          (payload.extrasResponse.basketExtras as ReservationExtra[]) || []
        ),
        cartExpiry: 0, // todo: this should come from backend somewhere?
        availableFilters,
        activeFilters,
        minRemainingTagsInLists: payload.extrasResponse.minRemainingTagsInLists,
        remainingChipFilters: payload.extrasResponse.remainingChipFilters,
        tagIdLookup: payload.extrasResponse.tagIdLookup,
        hasMore: payload.extrasResponse.hasMore || false,
        totalCount: payload.extrasResponse.totalCount || 0,
        defaultSort: action.payload.sortMethod
          ? state.defaultSort
          : sortOptions
              ?.filter((so) => so.isDefault)
              .map((option) => option.sortValue)
              ?.toString(),
      };
    },
    getExtrasFail: (state, action: PayloadAction<BasketAndAvailableExtras>) => {
      state.hasError = true;
      state.errorMessage =
        action?.payload || "An error occurred. Please try again.";
      state.activeFilters = [];
      state.availableFilters = [];
      state.cartExpiry = 0;
      state.data = null;
      state.extrasData = null;
      state.totalPrice = 0;
      state.defaultSort = "";
      state.hasMore = false;
      state.loaded = false;
      state.loading = false;
      state.minRemainingTagsInLists = 0;
      state.remainingChipFilters = [];
      state.tagIdLookup = [{ longId: "", shortKey: "" }];
      state.totalCount = 0;
    },
    setExtraSearchFilter: (
      state,
      action: PayloadAction<{
        sortMethod: string | false;
        filters: string[] | false;
      }>
    ) => ({
      ...state,
      activeFilters: action.payload.filters || state.activeFilters,
      defaultSort: action.payload.sortMethod || state.defaultSort,
    }),
    clearExtraSearchFilter: (state, action) => {
      state.activeFilters = [];
      state.defaultSort = "";
    },
    resetExtrasList: (
      state,
      action: PayloadAction<{
        filtered: boolean;
        sortMethod?: boolean;
        isExtras: boolean;
      }>
    ) => ({
      ...initialState,
      activeFilters: action.payload.filtered ? state.activeFilters : [],
      extrasData: action.payload.isExtras ? state.extrasData : null,
    }),
  },
});

export const {
  getExtras,
  getExtrasFail,
  getExtrasSuccess,
  setExtraSearchFilter,
  clearExtraSearchFilter,
  resetExtrasList,
} = extraListSlice.actions;

export default extraListSlice.reducer;
