import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState = null;
export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    createLoginRememberDetails: (
      state,
      action: PayloadAction<{ token: string }>
    ) => {
      state;
    },
    createLoginRememberDetailsSuccess: (state) => {
      state;
    },
    createLoginRememberDetailsFailed: (state) => {
      state;
    },
    createAutoLoginSuccess: (state) => ({
      ...state,
      hasError: false,
      loading: false,
      loaded: false,
      hasAutoLoginError: false,
    }),
    createLoginFromSession: (state, action: PayloadAction<void>) => {
      state;
    },
    createLogoutFromSession: (state, action: PayloadAction<void>) => {
      state;
    },
  },
});

export const {
  createLoginRememberDetails,
  createAutoLoginSuccess,
  createLoginFromSession,
  createLoginRememberDetailsFailed,
  createLoginRememberDetailsSuccess,
  createLogoutFromSession,
} = loginSlice.actions;
