import { RootState } from "../slices";
import { useSelector } from "react-redux";

export const locationsSelector = (state: RootState) => state.locations;
export const useLocationsSelector = () => useSelector(locationsSelector);

export const locationsBlockInfoSelector = (state: RootState) =>
  state.locationBlockInfo;
export const useLocationsBlockInfoSelector = () =>
  useSelector(locationsBlockInfoSelector);

export const searchLocationsSelector = (state: RootState) =>
  state.locationsSearch;
export const useSearchLocationsSelector = () =>
  useSelector(searchLocationsSelector);

export const locationsSearchLocations = (state: RootState) =>
  state.locationsSearch.data?.locations;
export const useLocationsSearchLocationsSelector = () =>
  useSelector(locationsSearchLocations);

export const locationSortSelector = (state: RootState) =>
  state.locationsSearch.sortMethod;
export const useLocationSortSelector = () => useSelector(locationSortSelector);

export const locationFilterSelector = (state: RootState) =>
  state.locationsSearch.activeFilters;
export const useLocationFilterSelector = () =>
  useSelector(locationFilterSelector);

export const locationAvailableTags = (state: RootState) =>
  state.locationsSearch.availableFilters;
export const useLocationAvailableTagsSelector = () =>
  useSelector(locationAvailableTags);

export const planYourVisitSelector = (state: RootState) => state.planYourVisit;
export const usePlanYourVisitSelector = () =>
  useSelector(planYourVisitSelector);

const getLocationLowDepositDetails =
  (locationId: string) => (state: RootState) => {
    const locationDetails = state.locationsSearch.data?.locations?.find(
      (locationDetails) => locationDetails?.locationId == locationId
    );
    let standardDeposit = 0,
      overrideDeposit = 0;
    if (locationDetails?.pricing?.bestPrice?.standardDeposit > 0) {
      standardDeposit = locationDetails?.pricing?.bestPrice?.standardDeposit;
    } else if (locationDetails?.pricing?.price?.standardDeposit > 0) {
      standardDeposit = locationDetails?.pricing?.price?.standardDeposit;
    }
    if (locationDetails?.pricing?.bestPrice?.overrideDeposit > 0) {
      overrideDeposit = locationDetails?.pricing?.bestPrice?.overrideDeposit;
    } else if (locationDetails?.pricing?.price?.overrideDeposit > 0) {
      overrideDeposit = locationDetails?.pricing?.price?.overrideDeposit;
    }

    return overrideDeposit < standardDeposit ? overrideDeposit : 0;
  };

export const useGetLocationLowDepositDetails = (locationId: string) =>
  useSelector(getLocationLowDepositDetails(locationId));
