import { createSlice } from "@reduxjs/toolkit";
import {
  AddSpecificCabinResponse,
  MutationSwapSpecificCabinArgs,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type SwapSpecificCabinState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<AddSpecificCabinResponse>;
};

const initialState: SwapSpecificCabinState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const cabinsSpecificSwapSlice = createSlice({
  name: "cabinsSpecificSwap",
  initialState,
  reducers: {
    swapSpecificCabin: (
      state,
      action: PayloadAction<{
        swapSpecificCabinInput: MutationSwapSpecificCabinArgs;
        isPostBookingJourney: boolean;
      }>
    ) => {
      state.loading = true;
      state.loaded = false;
      state.data = null;
      state.errorMessage = null;
      state.hasError = false;
    },
    swapSpecificCabinSuccess: (
      state,
      action: PayloadAction<{
        swapSpecificCabinResponse: AddSpecificCabinResponse;
        isPostBookingJourney: boolean;
      }>
    ) => {
      state.data = action.payload.swapSpecificCabinResponse;
      state.loaded = true;
      state.loading = false;
      state.errorMessage = null;
      state.hasError = false;
    },
    swapSpecificCabinFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loading = false;
      state.loaded = false;
      state.data = null;
    },
    clearSwapCabins: (state, action) => {
      initialState;
    },
  },
});

export const {
  swapSpecificCabin,
  swapSpecificCabinSuccess,
  swapSpecificCabinFail,
  clearSwapCabins,
} = cabinsSpecificSwapSlice.actions;

export default cabinsSpecificSwapSlice.reducer;
