import { createSlice } from "@reduxjs/toolkit";
import {
  BookingSummary_S as BookingSummary,
  MutationPopulateAbandonedBasketBookingArgs,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type AbandonedBasketState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<BookingSummary>;
};

const initialState: AbandonedBasketState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const abandonedBasketSlice = createSlice({
  name: "abandonedBasket",
  initialState,
  reducers: {
    abandonedBasket: (
      state,
      action: PayloadAction<MutationPopulateAbandonedBasketBookingArgs>
    ) => {
      state.loading = true;
      state.errorMessage = null;
      state.hasError = false;
      state.data = null;
      state.loaded = false;
    },
    abandonedBasketSuccess: (state, action: PayloadAction<BookingSummary>) => {
      state.data = action?.payload;
      state.loaded = true;
      state.loading = false;
      state.errorMessage = null;
      state.hasError = false;
    },
    abandonedBasketFail: (state, action: PayloadAction<string>) => {
      state.loaded = true;
      state.loading = false;
      state.errorMessage =
        action?.payload || "An error occurred. Please try again.";
      state.hasError = true;
      state.data = null;
    },
  },
});

export const { abandonedBasket, abandonedBasketSuccess, abandonedBasketFail } =
  abandonedBasketSlice.actions;

export default abandonedBasketSlice.reducer;
