import { useSelector } from "react-redux";
import { RootState } from "../slices";

export const addCabinStateSelector = (state: RootState) => state.cabinAdd;
export const useAddCabinSelector = () => useSelector(addCabinStateSelector);

export const getCabinInfoSelector = (state: RootState) => state.cabinInfo;
export const useGetCabinInfoSelector = () => useSelector(getCabinInfoSelector);

export const getReservationFromReferralSelector = (state: RootState) =>
  state.reservationFromReferral;
export const useGetReservationFromReferralSelector = () =>
  useSelector(getReservationFromReferralSelector);

export const searchCabinStateSelector = (state: RootState) => state.cabinSearch;
export const useSearchCabinStateSelector = () =>
  useSelector(searchCabinStateSelector);

export const searchCabinHasErrorSelector = (state: RootState) =>
  state.cabinSearch.hasError;
export const useSearchCabinHasErrorSelector = () =>
  useSelector(searchCabinHasErrorSelector);
export const searchCabinErrorMessageSelector = (state: RootState) =>
  state.cabinSearch.errorMessage;
export const useSearchCabinErrorMessageSelector = () =>
  useSelector(searchCabinErrorMessageSelector);

export const allCabinTypesStateSelector = (state: RootState) =>
  state.cabinAllTypes;
export const useAllCabinTypesSelector = () =>
  useSelector(allCabinTypesStateSelector);

export const cabinTypesAtLocationStateSelector = (state: RootState) =>
  state.cabinTypesAtLocation;
export const useCabinTypesAtLocationSelector = () =>
  useSelector(cabinTypesAtLocationStateSelector);

export const cabinsForReservationStateSelector = (state: RootState) =>
  state.cabinsForReservation;
export const useCabinsForReservationSelector = () =>
  useSelector(cabinsForReservationStateSelector);

export const addSpecificCabinStateSelector = (state: RootState) =>
  state.cabinSpecificAdd;
export const useAddSpecificCabinSelector = () =>
  useSelector(addSpecificCabinStateSelector);

export const removeSpecificCabinStateSelector = (state: RootState) =>
  state.cabinSpecificRemove;
export const useRemoveSpecificCabinSelector = () =>
  useSelector(removeSpecificCabinStateSelector);

export const swapSpecificCabinStateSelector = (state: RootState) =>
  state.cabinSpecificSwap;
export const useSwapSpecificCabinSelector = () =>
  useSelector(swapSpecificCabinStateSelector);

export const cabinSortSelector = (state: RootState) =>
  state.cabinSearch.cabinSort;
export const useCabinSortSelector = () => useSelector(cabinSortSelector);

export const cabinFilterSelector = (state: RootState) =>
  state.cabinSearch.activeFilters;
export const useCabinFilterSelector = () => useSelector(cabinFilterSelector);

export const cabinAvailableTags = (state: RootState) =>
  state.cabinSearch.availableFilters;
export const useCabinAvailableTagsSelector = () =>
  useSelector(cabinAvailableTags);

export const useIsOverrideDepositAvailableForCabin = (cabinTypeId: string) =>
  useSelector(isOverrideDepositAvailableForCabin(cabinTypeId));

const isOverrideDepositAvailableForCabin =
  (cabinTypeId: string) => (state: RootState) => {
    const cabinDetails = state.cabinSearch.data?.cabinPanels?.find(
      (cabinPanels) => cabinPanels?.cabinTypeId === cabinTypeId
    );
    const isLowDepositAvailableWithPetFriendlyOptions =
      cabinDetails?.petFriendlyBedroomOptions?.find(
        (bedroomOptions) =>
          bedroomOptions?.overrideDeposit &&
          bedroomOptions?.overrideDeposit < bedroomOptions?.standardDeposit
      );
    const isLowDepositAvailableWoPetFriendlyOptions =
      cabinDetails?.petFreeBedroomOptions?.find(
        (bedroomOptions) =>
          bedroomOptions?.overrideDeposit &&
          bedroomOptions?.overrideDeposit < bedroomOptions?.standardDeposit
      );

    return (
      !!isLowDepositAvailableWithPetFriendlyOptions ||
      !!isLowDepositAvailableWoPetFriendlyOptions
    );
  };
