export enum PaymentTypes {
  deposit = "deposit",
  full = "full",
  instalment = "instalment",
}

export enum PaymentScenarios {
  lowDepositAndFinalBalance = "Scenario1", // deposit
  standardDepositPayInInstalments = "Scenario2", // instalment
  zeroDepositPayInInstalments = "Scenario3", // instalment
  standardDepositAndFinalBalance = "Scenario4", // deposit
  lowDepositPayInInstalments = "Scenario5", // instalment
  lowDepositRemainingDepositAndFinalBalance = "Scenario6", // deposit
  fullPayment = "Scenario7", // full
}

export enum PaymentDepositDates {
  INSTALMENTDAYOFMONTH = "##INSTALMENTDAYOFMONTH##",
  FINALINSTALMENTDATE = "##FINALINSTALMENTDATE##",
  BALANCEDUEDATE = "##BALANCEDUEDATE##",
}

export enum PayMonthlyLearnMoreModel {
  remainingDeposit = "##remainingDeposit##",
  cancelationText = "##cancelationText##",
  scheduledPayments = "##scheduledPayments##",
}

export enum PayDepositLearnMoreModel {
  remainingDeposit = "##remainingDeposit##",
}

export enum paymentDepositAttributes {
  depositValue = "##depositValue##",
  remainingValue = "##remainingValue##",
  dueDate = "##dueDate##",
}

export enum ScheduledPaymentStatus {
  complete = "Success",
  failed = "Failed",
  upcoming = "Pending",
}
export enum CompletedPayment {
  paymentValue = "##PAYMENTVALUE##",
}

export enum BookingPaymentStatePlaceholders {
  balancePaid = "##BALANCEPAID##",
  days = "##DAYS##",
  paymentAmount = "##PAYMENTAMOUNT##",
  dueDate = "##DUEDATE##",
  resolutionDate = "##RESOLUTIONDATE##",
  bookingDueDate = "##BOOKINGDUEDATE##",
}
