import { createSlice } from "@reduxjs/toolkit";
import {
  GenericCabinTypeContent,
  GenericCabinTypesResponse,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type CabinTypesState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<GenericCabinTypeContent[]>;
};

const initialState: CabinTypesState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const cabinsTypeSlice = createSlice({
  name: "cabinsSearch",
  initialState,
  reducers: {
    getAllCabinTypes: (state, action: PayloadAction<void>) => {
      state.loading = true;
      state.hasError = false;
      state.loaded = false;
      state.errorMessage = null;
      state.data = null;
    },
    getAllCabinTypesSuccess: (
      state,
      action: PayloadAction<GenericCabinTypesResponse>
    ) => {
      state.data = action.payload.cabinTypes as GenericCabinTypeContent[];
      state.loaded = true;
      state.loading = false;
      state.hasError = false;
      state.errorMessage = null;
    },
    getAllCabinTypesFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loaded = false;
      state.loading = false;
      state.data = null;
    },
  },
});

export const {
  getAllCabinTypes,
  getAllCabinTypesSuccess,
  getAllCabinTypesFail,
} = cabinsTypeSlice.actions;

export default cabinsTypeSlice.reducer;
