import { createSlice } from "@reduxjs/toolkit";
import {
  addTrailingSlashToUrl,
  createPathFromUrl,
  Nullable,
} from "../../utils";
import {
  GiftVoucherSettingsResponse,
  ProgressTrackerStep_S as ProgressTrackerStep,
} from "@generated/types";
import { GvJourneyStep } from "../../interfaces/giftvouchers";
import type { PayloadAction } from "@reduxjs/toolkit";

export type GVSettingsState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  gvSettings: Nullable<Required<GiftVoucherSettingsResponse>>;
  gvBookingSteps: Nullable<GvJourneyStep[]>;
  activeGvStepIndex: number;
};

const initialState: GVSettingsState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: true,
  gvSettings: null,
  gvBookingSteps: null,
  activeGvStepIndex: 0,
});

export const gvSettingsSlice = createSlice({
  name: "gvSettings",
  initialState,
  reducers: {
    getGvSettingsAction: (state) => {
      state.loading = true;
    },
    getGvSettingsSuccessAction: (
      state,
      action: PayloadAction<Required<GiftVoucherSettingsResponse>>
    ) => {
      const progressTrackerSteps =
        (action.payload.giftVoucherJourneyStepper?.progressTrackerSteps?.map(
          (step) => {
            step.url = addTrailingSlashToUrl(step?.url);
            return step;
          }
        ) || []) as ProgressTrackerStep[];
      const currentSteps = state.gvBookingSteps;

      const progressTrackerStepsWithoutNulls: ProgressTrackerStep[] =
        progressTrackerSteps.filter(
          (step) => step !== null && step.title !== null
        );

      const newSteps = progressTrackerStepsWithoutNulls.map(
        (step) =>
          ({
            label: step.title,
            url: createPathFromUrl(step?.url as string),
            tag: step.tag as string,
            disableClick: step.tag === "login",
            completed:
              currentSteps?.find((s) => s.tag === step.tag)?.completed || false,
          } as GvJourneyStep)
      );
      state.gvSettings = action.payload;
      state.gvBookingSteps = newSteps;
      state.loaded = true;
      state.loading = false;
      state.errorMessage = null;
      state.hasError = false;
      state.activeGvStepIndex = 0;
    },
    setActiveGvStepByUrlAction: (state, action: PayloadAction<string>) => {
      const steps = state.gvBookingSteps;
      const url = action.payload;
      const index =
        steps?.findIndex(
          (step) => step?.url === url || step?.url?.includes(url)
        ) ?? 0;

      state.activeGvStepIndex = index;
    },
    confirmActiveGvStepAction: (state) => {
      const gvSteps = state.gvBookingSteps;
      if (gvSteps !== null) {
        const gvActiveStep = gvSteps[state.activeGvStepIndex];
        gvActiveStep.completed = true;
      }

      gvSteps;
    },
    getGvSettingsFailAction: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.loaded = true;
      state.errorMessage =
        action?.payload || "An error occurred. Please try again.";
      state.loading = true;
      state.activeGvStepIndex = 0;
      state.gvBookingSteps = null;
      state.gvSettings = null;
    },
  },
});

export const {
  getGvSettingsAction,
  getGvSettingsSuccessAction,
  getGvSettingsFailAction,
  confirmActiveGvStepAction,
  setActiveGvStepByUrlAction,
} = gvSettingsSlice.actions;

export default gvSettingsSlice.reducer;
