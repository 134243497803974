import { createSlice } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import {
  AddGiftVoucherToBasketResponse,
  MutationAddGiftVoucherToBasketArgs,
} from "@generated/types";
import type { PayloadAction } from "@reduxjs/toolkit";

export type GiftVoucherAddState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<AddGiftVoucherToBasketResponse>;
};

const initialState: GiftVoucherAddState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const giftVoucherAddSlice = createSlice({
  name: "giftVoucherAdd",
  initialState,
  reducers: {
    addGiftVoucher: (
      state,
      action: PayloadAction<MutationAddGiftVoucherToBasketArgs>
    ) => {
      state.loading = true;
      state.loaded = false;
      state.errorMessage = null;
      state.hasError = false;
      state.data = null;
    },
    addGiftVoucherSuccess: (
      state,
      action: PayloadAction<AddGiftVoucherToBasketResponse>
    ) => {
      state.data = action.payload;
      state.loading = false;
      state.loaded = true;
      state.errorMessage = null;
      state.hasError = false;
    },
    addGiftVoucherFail: (
      state,
      action: PayloadAction<GiftVoucherAddState["errorMessage"]>
    ) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loaded = false;
      state.loading = false;
      state.data = null;
    },
  },
});

export const { addGiftVoucher, addGiftVoucherSuccess, addGiftVoucherFail } =
  giftVoucherAddSlice.actions;

export default giftVoucherAddSlice.reducer;
