import { createSlice } from "@reduxjs/toolkit";

export type MandatoryExtrasState = {
  breakInteractedWith: boolean;
  flexInteractedWith: boolean;
  iceInteractedWith: boolean;
};

const initialState: MandatoryExtrasState = Object.freeze({
  breakInteractedWith: false,
  flexInteractedWith: false,
  iceInteractedWith: false,
});

export const mandatoryExtrasSlice = createSlice({
  name: "mandatoryExtras",
  initialState,
  reducers: {
    interactWithBreak: (state) => {
      return {
        ...state,
        breakInteractedWith: true,
      };
    },
    interactWithFlex: (state) => {
      return {
        ...state,
        flexInteractedWith: true,
      };
    },
    interactWithIce: (state) => {
      return {
        ...state,
        iceInteractedWith: true,
      };
    },
    resetMandatoryExtraInteractions: () => {
      return {
        ...initialState,
      };
    },
  },
});

export const {
  interactWithBreak,
  interactWithFlex,
  interactWithIce,
  resetMandatoryExtraInteractions,
} = mandatoryExtrasSlice.actions;

export default mandatoryExtrasSlice.reducer;
