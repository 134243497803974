import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { GuestLabels, Guests } from "../../interfaces/guests";
import { IDateRange } from "../../interfaces/calendar";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type BookingSearchState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  bookingChannelId?: string;
  selectedLocationIds: string[];
  tempSelectedLocationIds: string[];
  currentYear: number;
  currentMonth: number;
  dateRange: IDateRange;
  guests: Guests;
  guestLabels?: GuestLabels;
  guestLabel?: string;
  locationsLabel?: string;
  dda: boolean;
  promoCode: Nullable<string>;
  minNoOfBedrooms: number;
};

const initialState: BookingSearchState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  bookingChannelId: process.env.NEXT_PUBLIC_BOOKING_CHANNEL_ID,
  currentMonth: parseInt(moment(new Date()).format("M"), 10),
  currentYear: parseInt(moment(new Date()).format("YYYY"), 10),
  selectedLocationIds: [],
  tempSelectedLocationIds: [],
  dateRange: {
    startDateISO: null,
    endDateISO: null,
  },
  guests: {
    guestTotal: 1,
    adults: 1,
    children: 0,
    infants: 0,
    pets: 0,
    bedrooms: 1,
  },
  guestLabels: {
    multipleGuestsLabel: "",
    singleGuestLabel: "",
    multipleInfantsLabel: "",
    singleInfantLabel: "",
    multiplePetsLabel: "",
    singlePetLabel: "",
    accessibleLabel: "",
  },
  minNoOfBedrooms: 1,
  dda: false,
  promoCode: null,
});

export const bookingSearch = createSlice({
  name: "bookingSearch",
  initialState,
  reducers: {
    setSelectedLocationIds: (state, action) => {
      state.selectedLocationIds = action.payload as string[];
      state.locationsLabel = makeLocationsLabel(action.payload);
    },
    setTempSelectedLocationIds: (state, action) => {
      state.tempSelectedLocationIds = action.payload as string[];
    },
    setBookingChannelId: (state, action: PayloadAction<string>) => {
      state.bookingChannelId = action.payload;
    },
    setSelectedDates: (state, action: PayloadAction<IDateRange>) => {
      state.dateRange = action.payload;
    },
    setPresentMonth: (state, action: PayloadAction<number>) => {
      state.currentMonth = action.payload;
    },
    setPresentYear: (state, action: PayloadAction<number>) => {
      state.currentYear = action.payload;
    },
    createGuestLabels: (state, action: PayloadAction<GuestLabels>) => {
      state.guestLabels = action.payload;
    },
    setAdults: (state, action: PayloadAction<number>) => {
      const guests = {
        ...state.guests,
        adults: action.payload,
      };

      const guestCount = calculateNumberOfGuests(guests);
      guests.guestTotal = guestCount;
      const minNoOfBedrooms = calculateMinNumberOfBedrooms(guestCount);
      guests.bedrooms = minNoOfBedrooms;
      state.guests = {
        ...guests,
        bedrooms: minNoOfBedrooms,
      };
      state.guestLabel = makeGuestsLabel(guests, state.dda, state.guestLabels);
      state.minNoOfBedrooms = minNoOfBedrooms;
    },
    setChildren: (state, action: PayloadAction<number>) => {
      const guests = {
        ...state.guests,
        children: action.payload,
      };

      const guestCount = calculateNumberOfGuests(guests);
      guests.guestTotal = guestCount;
      const minNoOfBedrooms = calculateMinNumberOfBedrooms(guestCount);
      guests.bedrooms = minNoOfBedrooms;

      state.guests = {
        ...guests,
        guestTotal: guestCount,
        bedrooms: minNoOfBedrooms,
      };
      state.guestLabel = makeGuestsLabel(guests, state.dda, state.guestLabels);
      state.minNoOfBedrooms = minNoOfBedrooms;
    },
    setInfants: (state, action: PayloadAction<number>) => {
      const guests = {
        ...state.guests,
        infants: action.payload,
      };

      state.guests = guests;
      state.guestLabel = makeGuestsLabel(guests, state.dda, state.guestLabels);
    },
    setPets: (state, action: PayloadAction<number>) => {
      const guests = {
        ...state.guests,
        pets: action.payload,
      };

      state.guests = guests;
      state.guestLabel = makeGuestsLabel(guests, state.dda, state.guestLabels);
    },
    setBedrooms: (state, action: PayloadAction<number>) => {
      const guests = {
        ...state.guests,
        bedrooms: action.payload,
      };

      state.guests = guests;
      state.guestLabel = makeGuestsLabel(guests, state.dda, state.guestLabels);
    },
    setDda: (state, action: PayloadAction<boolean>) => {
      state.dda = action.payload;
      state.guestLabel = makeGuestsLabel(
        state.guests,
        action.payload,
        state.guestLabels,
      );
    },
    setPromoCode: (state, action: PayloadAction<string>) => {
      state.promoCode = action.payload;
    },
    setSingleSelectedLocationName: (state, action: PayloadAction<string>) => {
      state.locationsLabel = action.payload;
    },
    resetBookingSearch: (state) => ({
      ...initialState,
    }),
    updateGuestLabel: (state, action: PayloadAction<string>) => {
      state.guestLabel = action.payload;
    },
    createLocationSearch: (state) => {
      state.loading = true;
    },
  },
});

export const makeGuestsLabel = (
  guests: Guests,
  dda: boolean,
  labels: GuestLabels,
) => {
  const isEmpty = !Object.values(labels).some((x) => x !== null && x !== "");
  if (isEmpty) return "Guests";
  let guestsLabel = Object.values(guests).filter((n) => n > 0).length
    ? Object.entries(guests)
        .filter(
          ([key, value]) => value > 0 && key !== "adults" && key !== "children",
        )
        .reduce((arr: string[], [key, value]) => {
          const strings: { [key: string]: string[] } = {
            guestTotal: [labels.singleGuestLabel, labels.multipleGuestsLabel],
            infants: [labels.singleInfantLabel, labels.multipleInfantsLabel],
            pets: [labels.singlePetLabel, labels.multiplePetsLabel],
            bedrooms: ["bedroom", "bedrooms"],
          };
          return [
            ...arr,
            `${value} ${value === 1 ? strings[key][0] : strings[key][1]}`,
          ];
        }, [])
        .join(", ")
    : "Guests";
  if (dda) {
    guestsLabel += guestsLabel.length
      ? ", " + labels.accessibleLabel
      : labels.accessibleLabel;
  }
  return guestsLabel;
};

const makeLocationsLabel = (ids: string[]) => {
  if (ids.length > 1) return `${ids.length} locations`;
};

const calculateNumberOfGuests = (guests: Guests) =>
  guests.adults + guests.children;
const calculateMinNumberOfBedrooms = (guestCount: number) =>
  Math.round(guestCount / 2);

export const {
  setSelectedLocationIds,
  setTempSelectedLocationIds,
  setBookingChannelId,
  setSelectedDates,
  setPresentMonth,
  setPresentYear,
  createGuestLabels,
  setAdults,
  setChildren,
  setInfants,
  setPets,
  setBedrooms,
  setDda,
  setPromoCode,
  setSingleSelectedLocationName,
  resetBookingSearch,
  createLocationSearch,
  updateGuestLabel,
} = bookingSearch.actions;

export default bookingSearch.reducer;
