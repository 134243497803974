import { createSlice } from "@reduxjs/toolkit";
import {
  ExtraDetailsPage_S as ExtraDetailsPage,
  QueryExtraDetailsDirectArgs,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type FeaturedExtraDetailsContentState = {
  loaded: boolean;
  loading: boolean;
  extraDetails: Nullable<ExtraDetailsPage>;
  hasError: boolean;
  errorMessage: Nullable<string>;
};

const initialState: FeaturedExtraDetailsContentState = Object.freeze({
  loaded: false,
  loading: false,
  extraDetails: null,
  hasError: false,
  errorMessage: null,
});

export const featuredExtraDetailsContentSlice = createSlice({
  name: "featuredExtraDetails",
  initialState,
  reducers: {
    getFeaturedExtraDetailsContent: (
      state,
      action: PayloadAction<QueryExtraDetailsDirectArgs>
    ) => {
      state.loading = true;
    },
    getFeaturedExtraDetailsContentSuccess: (
      state,
      action: PayloadAction<ExtraDetailsPage>
    ) => {
      state.loading = false;
      state.loaded = true;
      state.extraDetails = action.payload;
    },
    getFeaturedExtraDetailsContentFail: (
      state,
      action: PayloadAction<string>
    ) => {
      state.loading = false;
      state.hasError = true;
      state.errorMessage = action.payload;
      state.extraDetails = null;
      state.loaded = false;
    },
  },
});

export const {
  getFeaturedExtraDetailsContent,
  getFeaturedExtraDetailsContentSuccess,
  getFeaturedExtraDetailsContentFail,
} = featuredExtraDetailsContentSlice.actions;

export default featuredExtraDetailsContentSlice.reducer;
