import { createSlice } from "@reduxjs/toolkit";
import { MutationRemoveExtraFromBasketArgs } from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RemoveExtraFromBasketResponse } from "../../graphql/bsl/gql-custom/types";

export type RemoveFeaturedExtraState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<any>;
  removeExtraRequest?: MutationRemoveExtraFromBasketArgs;
  isPostBookingJourney?: boolean;
};

const initialState: RemoveFeaturedExtraState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
  isPostBookingJourney: false,
});

export const featuredExtraRemoveSlice = createSlice({
  name: "RemoveFeaturedExtra",
  initialState,
  reducers: {
    removeFeaturedExtra: (
      state,
      action: PayloadAction<{
        mutationRemoveFeaturedExtraFromBasketArgs: MutationRemoveExtraFromBasketArgs;
        isPostBookingJourney: boolean;
      }>
    ) => {
      state.removeExtraRequest =
        action.payload.mutationRemoveFeaturedExtraFromBasketArgs;
      state.loading = true;
      state.errorMessage = null;
      state.loaded = false;
      state.data = null;
      state.isPostBookingJourney = false;
      state.hasError = false;
    },
    removeFeaturedExtraSuccess: (
      state,
      action: PayloadAction<{
        removeFeaturedExtraFromBasketResponse: RemoveExtraFromBasketResponse;
        isPostBookingJourney: boolean;
      }>
    ) => ({
      ...initialState,
      removeExtraRequest: state.removeExtraRequest,
      data: action.payload.removeFeaturedExtraFromBasketResponse,
      loaded: true,
      isPostBookingJourney: action.payload.isPostBookingJourney,
    }),
    removeFeaturedExtraFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loading = false;
      state.loaded = false;
      state.data = null;
      state.isPostBookingJourney = false;
    },
  },
});

export const {
  removeFeaturedExtra,
  removeFeaturedExtraSuccess,
  removeFeaturedExtraFail,
} = featuredExtraRemoveSlice.actions;

export default featuredExtraRemoveSlice.reducer;
