import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import { DeleteVehicleResponse, VehicleInput } from "@generated/types";

export type VehicleRemoveState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<DeleteVehicleResponse>;
};

const initialState: VehicleRemoveState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const vehicleRemoveSlice = createSlice({
  name: "vehicleRemove",
  initialState,
  reducers: {
    removeVehicle: (state, action: PayloadAction<VehicleInput>) => {
      state.loading = true;
      state.loaded = false;
      state.errorMessage = null;
      state.hasError = false;
      state.data = null;
    },
    removeVehicleSuccess: (
      state,
      action: PayloadAction<DeleteVehicleResponse>
    ) => {
      state.data = action.payload;
      state.loading = false;
      state.loaded = true;
      state.errorMessage = null;
      state.hasError = false;
    },
    removeVehicleFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loaded = false;
      state.loading = false;
      state.data = null;
    },
  },
});

export const { removeVehicle, removeVehicleFail, removeVehicleSuccess } =
  vehicleRemoveSlice.actions;
export default vehicleRemoveSlice.reducer;
