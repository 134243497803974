import axios from "axios";
import * as Sentry from "@sentry/nextjs";
import { getCookie, setCookie } from "cookies-next";
import moment from "moment";
import { IDateRange } from "src/interfaces/calendar";
import { FHCookies } from "src/interfaces/cookies";
import { Guests } from "src/interfaces/guests";
import {
  FHComplianceAction,
  FHEventType,
  NextApiRequestWithSST,
  SSTAncillaryTransaction,
  SSTAnonBookingTransaction,
  SSTAnonSearch,
  SSTBookingSearchDetails,
  SSTBookingTransaction,
  SSTCabin,
  SSTComplianceAction,
  SSTEvent,
  SSTExtraOption,
  SSTLoginRegister,
  SSTPageView,
  SSTSearch,
  SSTSessionStorageProps,
  SSTUserSession,
  User,
  SessionEventData,
} from "src/interfaces/serverSideTracking";
import {
  ConsentType,
  generateBookingQueryString,
  getAnalyticsConsentPreference,
  getBrowser,
  getDeviceType,
  isAnalyticsConsentDeclined,
  isAnalyticsConsentUnspecified,
  parseUTMFromUrl,
} from "src/utils/common";
import { v4 as uuidv4 } from "uuid";
import {
  BookingConfirmation_S as BookingConfirmation,
  BookingSummary_S as BookingSummary,
  Customer,
  ReservationExtra,
  ReservationExtraOption,
} from "@generated/types";
import { Nullable } from "src/utils";
import TagManager from "react-gtm-module";
import { pushActiveExperimentsToDataLayer } from "src/utils/experiments";
import _sodium from "libsodium-wrappers";
// import { postToDataLayer } from "./google-analytics";

const touchSession = async (
  sstSession: SSTSessionStorageProps,
  req: NextApiRequestWithSST,
): Promise<SSTSessionStorageProps> => {
  try {
    const now = moment().utc();
    const storedExpiry = moment(sstSession.sessionExpiry).utc();
    const isSessionExpired = storedExpiry.isBefore(now);

    if (isSessionExpired) {
      sstSession = await createNewSession(sstSession, req);
      return sstSession;
    } else {
      sstSession.sessionExpiry = moment().add(1, "h").toISOString();
      sessionStorage.setItem(sstSession.sessionId, JSON.stringify(sstSession));
    }
  } catch (e) {
    Sentry.captureException(`SST touchSession error - ${e}`);
  }

  return sstSession;
};

const getCommonEventData = (
  req?: NextApiRequestWithSST,
): {
  url: string;
  urlWithoutQueryString: string;
  referrer: string;
  headers: any;
} => {
  const url = req?.url as string;
  const urlWithoutQueryString = window.location.href.split("?")[0];
  const referrer = req?.referrer as string;
  const headers = req?.headers;

  return { url, urlWithoutQueryString, referrer, headers };
};

const getDefaultSSTSessionStorageProps = (): SSTSessionStorageProps => {
  return {
    sessionId: "",
    sessionExpiry: "",
    pageViewId: "",
    previousPageViewId: "",
    pageOrderNumber: "0",
    bookingFunnelId: "",
    bookingFunnelOrderNumber: "0",
    bookingFunnelSearchId: "",
    bookingFunnelSearchOrderNumber: "0",
    bookingSearchDetails: {
      bookingFunnelLocationIds: [],
      bookingSearchLocationIds: [],
      duration: "",
      startDate: "",
      adults: "",
      bedrooms: "",
      children: "",
      dda: "",
      infants: "",
      pets: "",
      cabinTypeId: "",
      noOfBedsAttemptedToBook: "0",
    },
  };
};

export const getSessionData = async (
  req: NextApiRequestWithSST,
): Promise<SSTSessionStorageProps> => {
  let sstSession = getDefaultSSTSessionStorageProps();
  try {
    const storedSessionData = JSON.parse(sessionStorage.getItem(window.name));
    if (!storedSessionData) {
      sstSession = await createNewSession(sstSession, req);
    } else {
      sstSession = await touchSession(storedSessionData, req);
    }
  } catch (e) {
    Sentry.captureException(
      `Unable to parse session storage data - ${e.message}`,
    );
    sstSession = await createNewSession(sstSession, req);
  }
  return sstSession;
};

const getUserId = () => {
  let userId = getCookie(FHCookies.user);
  if (!userId) {
    const fourHundredDayExpiry = new Date();
    fourHundredDayExpiry.setDate(fourHundredDayExpiry.getDate() + 400);
    userId = uuidv4();
    setCookie(FHCookies.user, userId, { expires: fourHundredDayExpiry });
  }
  return (userId as string) || "";
};

const createNewSession = async (
  sstSession: SSTSessionStorageProps,
  req: NextApiRequestWithSST,
): Promise<SSTSessionStorageProps> => {
  const { url, urlWithoutQueryString, referrer, headers } =
    getCommonEventData(req);
  const isSykesReferrer = referrer?.includes("sykes");

  const previousSessionIdToExpire = sstSession.sessionId || "";
  if (!!previousSessionIdToExpire) {
    sessionStorage.removeItem(previousSessionIdToExpire);
  }
  let newSessionId = uuidv4();
  sstSession.sessionId = newSessionId;
  sstSession.sessionExpiry = "";
  sstSession.pageOrderNumber = "0";
  sstSession = await touchSession(sstSession, req);
  window.name = newSessionId;

  const anonSessionData: SSTEvent = {
    eventType: FHEventType.anonSession,
    sessionId: sstSession.sessionId,
    url: urlWithoutQueryString,
  };
  postSSTEvent(anonSessionData, FHEventType.anonSession);

  if (isAnalyticsConsentDeclined()) {
    return sstSession;
  }

  const trackingId = +getCookie(FHCookies.interstitalTracking);
  const { publicKey } = await getConsentKeyPair();
  const { gad, gclid, pageURLQueryString } = await getUrlData(url);
  const analyticsUnspecified = isAnalyticsConsentUnspecified();
  const userSessionData: SSTUserSession = {
    eventType: await encryptMe(FHEventType.userSession, "eventType (session)"),
    url: await encryptMe(url, "url"),
    referrer: await encryptMe(referrer, "referrer"),
    sessionId: await encryptMe(sstSession.sessionId, "sessionId"),
    userId: await encryptMe(getUserId(), "userId"),
    trackingId: isSykesReferrer
      ? await encryptMe(trackingId, "trackingId")
      : undefined,
    publicKey: analyticsUnspecified ? publicKey : undefined,
    complianceAction: analyticsUnspecified
      ? FHComplianceAction.unspecifiedTracking
      : undefined,
    meta: await getMetaData(url, headers),
    gad,
    gclid,
    pageURLQueryString,
  };
  postSSTEvent(userSessionData, FHEventType.userSession);

  return sstSession;
};

const postPageView = async (
  sstSession: SSTSessionStorageProps,
  req: NextApiRequestWithSST,
) => {
  // need to push experiments to dataLayer before pageView
  pushActiveExperimentsToDataLayer();

  const { url, urlWithoutQueryString, referrer, headers } =
    getCommonEventData(req);
  const { userData } = req;

  const isLoggedIn = userData?.customerReference || "";
  const userInfo: User = {
    user_status: isLoggedIn ? "logged-in" : "logged-out",
    ...(!!isLoggedIn && {
      cust_ref: userData?.customerReference || "",
      booked_stays: userData?.bookedStays || 0,
      departed_stays: userData?.departedStays || 0,
    }),
    ...(userData?.daystoNextStay > 0 && {
      days_to_next_stay: userData.daystoNextStay,
    }),
    ...(userData?.daysSinceLastStay > 0 && {
      days_since_last_stay: userData.daysSinceLastStay,
    }),
  };

  const anonPageViewData: SSTEvent = {
    eventType: FHEventType.anonPageView,
    pageViewId: sstSession.pageViewId,
    url: urlWithoutQueryString,
  };
  postSSTEvent(anonPageViewData, FHEventType.anonPageView);

  const sessionId = sstSession.sessionId || "";
  const userId = getUserId();
  userInfo._clear = true;
  // Push the new pageView event data
  TagManager.dataLayer({
    dataLayer: {
      event: FHEventType.pageView,
      url: window.location.pathname + window.location.search,
      session_Id: sessionId,
      user_Id: userId,
      ...userInfo,
    },
  });

  if (isAnalyticsConsentDeclined()) {
    return;
  }

  const { pageURLQueryString } = await getUrlData(url);
  const { publicKey } = await getConsentKeyPair();
  const analyticsUnspecified = isAnalyticsConsentUnspecified();
  const pageViewData: SSTPageView = {
    eventType: await encryptMe(FHEventType.pageView, "eventType - pageView"),
    url: await encryptMe(url, "url"),
    referrer: await encryptMe(referrer, "referrer"),
    sessionId: await encryptMe(sstSession.sessionId, "sessionId"),
    pageOrderNumber: await encryptMe(
      sstSession.pageOrderNumber,
      "pageOrderNumber",
    ),
    pageViewId: await encryptMe(sstSession.pageViewId, "pageViewId"),
    bookingFunnelId: await encryptMe(
      sstSession.bookingFunnelId,
      "bookingFunnelId",
    ),
    pageURLQueryString,
    publicKey: analyticsUnspecified ? publicKey : undefined,
    complianceAction: analyticsUnspecified
      ? FHComplianceAction.unspecifiedTracking
      : undefined,
  };
  postSSTEvent(pageViewData, FHEventType.pageView);
};

export const encryptMe = async (value: any, description?: string) => {
  if (isAnalyticsConsentUnspecified()) {
    const jsonStringValue =
      value === undefined || value === null || value === ""
        ? ""
        : JSON.stringify(value);
    await _sodium.ready;
    const sodium = _sodium;
    const { publicKey } = await getConsentKeyPair();
    const publicKeyBuffer = convertKeyStringToBuffer(publicKey);
    try {
      const cipherText = Buffer.from(
        sodium.crypto_box_seal(jsonStringValue, publicKeyBuffer),
      );
      const cipherTextHex = cipherText.toString("hex");
      return cipherTextHex || "";
    } catch (e) {
      Sentry.captureException(`SST encryptMe error - ${description} ${e}`);
    }
  } else {
    return value;
  }
};

const createKeysAndStoreInCookie = async (): Promise<{
  privateKey: string;
  publicKey: string;
}> => {
  await _sodium.ready;
  const sodium = _sodium;
  const keyPair = sodium.crypto_box_keypair();
  const publicKey = keyPair.publicKey.toString();
  const privateKey = keyPair.privateKey.toString();
  const encodedPrivateKey = window.btoa(privateKey);
  const encodedPublicKey = window.btoa(publicKey);
  setCookie(FHCookies.optanonKeys, `${encodedPrivateKey}/${encodedPublicKey}`);
  return { privateKey: encodedPrivateKey, publicKey: encodedPublicKey };
};

const convertKeyStringToBuffer = (key: string) => {
  const unencodedKey = window.atob(key);
  const convertedToArray = unencodedKey.split(",");
  const convertedToBuffer = Buffer.from(convertedToArray as any);
  return convertedToBuffer;
};

const getConsentKeyPair = async (): Promise<{
  privateKey: string;
  publicKey: string;
}> => {
  // returns keypair from cookie (base64 encoded)
  let cookieKeyPair = getCookie(FHCookies.optanonKeys);
  let consentPrivateKey;
  let consentPublicKey;

  if (!!cookieKeyPair) {
    // key pair cookie exists
    const cookieKeyPairArray = (cookieKeyPair as string).split("/");
    const cookiePrivateKey = cookieKeyPairArray[0];
    const cookiePublicKey = cookieKeyPairArray[1];
    consentPrivateKey = cookiePrivateKey;
    consentPublicKey = cookiePublicKey;
  } else {
    // key pair cookie doesn't exist
    if (isAnalyticsConsentUnspecified()) {
      // consent unspecified
      const { privateKey, publicKey } = await createKeysAndStoreInCookie();
      consentPrivateKey = privateKey;
      consentPublicKey = publicKey;
    }
  }
  return { privateKey: consentPrivateKey, publicKey: consentPublicKey };
};

const getServerSideTracking = async (req: NextApiRequestWithSST) => {
  await getConsentKeyPair();

  let sstSession = getDefaultSSTSessionStorageProps();

  if (window?.name) {
    // existing session
    sstSession = await getSessionData(req);
  } else {
    // new session
    sstSession = await createNewSession(sstSession, req);
  }

  const newPageViewId = uuidv4();
  sstSession.previousPageViewId = !sstSession?.previousPageViewId
    ? newPageViewId
    : sstSession.pageViewId;
  sstSession.pageViewId = newPageViewId;
  sstSession.pageOrderNumber = (+sstSession.pageOrderNumber + 1).toString();
  sstSession = await touchSession(sstSession, req);
  await postPageView(sstSession, req);
};

const getUrlFromRouter = (router: string) => {
  const url = `${process.env.NEXT_PUBLIC_BASE_URL}${router?.replace("/", "")}`;
  return url;
};

const getSSTSearchData = async (
  locationIds: string[],
  dateRange: IDateRange,
  guests: Guests,
  dda: boolean,
  router: string,
  referrer: string,
  cabinTypeId?: string,
  noOfBedsAttemptedToBook?: string,
): Promise<
  Nullable<{ bookingSearch: SSTSearch; anonSearch: SSTAnonSearch }>
> => {
  const url = getUrlFromRouter(router);

  let sstSession = await getSessionData({ url });
  const result = isItANewBookingSearch(
    sstSession,
    locationIds,
    dateRange,
    guests,
    dda,
  );
  if (result.newBookingSearch || result.newBookingFunnel) {
    sstSession = result.sstSession;
    const { newBookingFunnel } = result;
    if (newBookingFunnel) {
      sstSession.bookingFunnelId = uuidv4();
      sstSession.bookingFunnelOrderNumber = (
        +sstSession.bookingFunnelOrderNumber + 1
      ).toString();
      sstSession.bookingFunnelSearchOrderNumber = "0";
    }
    sstSession.bookingFunnelSearchId = uuidv4();
    sstSession.bookingFunnelSearchOrderNumber = (
      +sstSession.bookingFunnelSearchOrderNumber + 1
    ).toString();

    const startDate = new Date(dateRange.startDateISO);
    const endDate = new Date(dateRange.endDateISO);
    var diff = endDate.getTime() - startDate.getTime();
    var dayDiff = diff / (1000 * 60 * 60 * 24);

    sstSession = await touchSession(sstSession, { url, referrer });
    const { publicKey } = await getConsentKeyPair();
    const analyticsUnspecified = isAnalyticsConsentUnspecified();

    const searchData: SSTSearch = {
      eventType: await encryptMe(FHEventType.bookingSearch),
      sessionId: await encryptMe(sstSession.sessionId),
      url: await encryptMe(url),
      bookingFunnelId: await encryptMe(sstSession.bookingFunnelId),
      bookingFunnelOrderNumber: await encryptMe(
        sstSession.bookingFunnelOrderNumber,
      ),
      bookingFunnelSearchId: await encryptMe(sstSession.bookingFunnelSearchId),
      bookingFunnelSearchOrderNumber: await encryptMe(
        sstSession.bookingFunnelSearchOrderNumber,
      ),
      pageViewId: await encryptMe(sstSession.previousPageViewId),
      locationIds: await encryptMe(locationIds),
      checkInDate: await encryptMe(dateRange.startDateISO || ""),
      checkOutDate: await encryptMe(dateRange.endDateISO || ""),
      duration: await encryptMe(dayDiff ?? 0),
      adults: await encryptMe(guests?.adults ?? 0),
      children: await encryptMe(guests?.children ?? 0),
      infants: await encryptMe(guests?.infants ?? 0),
      bedrooms: await encryptMe(guests?.bedrooms),
      pets: await encryptMe(guests?.pets ?? 0),
      dda: await encryptMe(dda.toString()),
      cabinTypeId: await encryptMe(cabinTypeId || ""),
      noOfBedsAttemptedToBook: await encryptMe(noOfBedsAttemptedToBook || "0"),
      publicKey: analyticsUnspecified ? publicKey : undefined,
      complianceAction: analyticsUnspecified
        ? FHComplianceAction.unspecifiedTracking
        : undefined,
    };

    const anonSearchData: SSTAnonSearch = {
      eventType: FHEventType.anonSearch,
      bookingFunnelSearchId: sstSession.bookingFunnelSearchId,
      locationIds,
      checkInDate: dateRange.startDateISO || "",
      checkOutDate: dateRange.endDateISO || "",
      duration: dayDiff ?? 0,
      adults: guests?.adults ?? 0,
      children: guests?.children ?? 0,
      infants: guests?.infants ?? 0,
      bedrooms: guests?.bedrooms ?? 0,
      pets: guests?.pets ?? 0,
      dda,
    };

    return { bookingSearch: searchData, anonSearch: anonSearchData };
  }
  return null;
};

export const postSSTCookiePreferences = async (req: NextApiRequestWithSST) => {
  const analyticsConsentPreference: ConsentType =
    getAnalyticsConsentPreference();
  const consentSpecified =
    analyticsConsentPreference !== ConsentType.unspecified;

  if (consentSpecified) {
    const complianceAction =
      analyticsConsentPreference === ConsentType.approved
        ? FHComplianceAction.acceptTracking
        : FHComplianceAction.declineTracking;
    const { publicKey, privateKey } = await getConsentKeyPair();
    const specifiedConsentData: SSTComplianceAction = {
      complianceAction,
      publicKey,
      privateKey:
        analyticsConsentPreference === ConsentType.approved
          ? privateKey
          : undefined,
    };
    postSSTEvent(specifiedConsentData, complianceAction);
  }
};

export const postSSTBookingSearch = async (
  locationIds: string[],
  dateRange: IDateRange,
  guests: Guests,
  dda: boolean,
  router: string,
  referrer: string,
  cabinTypeId?: string,
  noOfBedsAttemptedToBook?: string,
) => {
  const data = await getSSTSearchData(
    locationIds,
    dateRange,
    guests,
    dda,
    router,
    referrer,
    cabinTypeId,
    noOfBedsAttemptedToBook,
  );

  if (!!data?.anonSearch) {
    postSSTEvent(data.anonSearch, FHEventType.anonSearch);
  }

  if (isAnalyticsConsentDeclined()) {
    return;
  }
  if (!!data?.bookingSearch) {
    postSSTEvent(data.bookingSearch, FHEventType.bookingSearch);
  }
};

export const generateBookingSearchDetailsAsStringFromQueryParams =
  (): string => {
    const urlSearch = filterXSS(window.location.search);
    const query = new URLSearchParams(urlSearch);

    const parsed = {
      l: decodeURIComponent(query.get("l") || ""), // location Ids
      d: decodeURIComponent(query.get("d") || ""), // duration
      sd: decodeURIComponent(query.get("sd") || ""), // start date
      a: decodeURIComponent(query.get("a") || "0"), // adults
      b: decodeURIComponent(query.get("b") || "0"), // bedrooms
      c: decodeURIComponent(query.get("c") || "0"), // children
      i: decodeURIComponent(query.get("i") || "0"), // infants
      p: decodeURIComponent(query.get("p") || "0"), // pets
      dda: decodeURIComponent(query.get("dda") || "false"), // dda
    };

    const duration = +parsed.d;
    const momentStartDate = moment(parsed.sd, "YYYY/MM/DD");
    const queryDateRange = {
      startDateISO: moment(momentStartDate).format("YYYY-MM-DD"),
      endDateISO: momentStartDate.add(duration, "days").format("YYYY-MM-DD"),
    };

    const locationIds = parsed.l.split(",");
    const adults = +parsed.a;
    const bedrooms = +parsed.b;
    const children = +parsed.c;
    const infants = +parsed.i;
    const pets = +parsed.p;
    const dda = parsed.dda === "true";

    const searchDetailsAsStringForComparingToSessionStorage =
      generateBookingQueryString({
        startDate: queryDateRange.startDateISO || "",
        endDate: queryDateRange.endDateISO || "",
        locationIds: locationIds,
        guests: {
          adults: adults,
          bedrooms: bedrooms,
          children: children,
          infants: infants,
          pets: pets,
          guestTotal: adults + children,
        },
        dda: dda,
      }).replace("?", "");

    return searchDetailsAsStringForComparingToSessionStorage;
  };

const getBookingSearchDetails = (
  detailsAsString: string,
  locationIds?: string[],
  dateRange?: IDateRange,
  guests?: Guests,
  dda?: boolean,
  cabinTypeId?: string,
): SSTBookingSearchDetails => {
  const parts = detailsAsString.split("&");
  let bookingSearchDetails: SSTBookingSearchDetails =
    getDefaultSSTSessionStorageProps().bookingSearchDetails;
  let hasLocationsInQueryString = false;

  for (let i = 0; i < parts.length; i++) {
    const part = parts[i].split("=");
    const key = part[0];
    const value = part[1];
    if (key === "l") {
      const locations = decodeURIComponent(value || "")?.split(",");
      hasLocationsInQueryString = locations?.[0] !== "";
      if (!hasLocationsInQueryString) {
        break;
      }
      bookingSearchDetails.bookingSearchLocationIds = locations;
      bookingSearchDetails.bookingFunnelLocationIds = locations;
    }
    if (key === "d") {
      bookingSearchDetails.duration = decodeURIComponent(value || "");
    }
    if (key === "sd") {
      bookingSearchDetails.startDate = decodeURIComponent(value || "");
    }
    if (key === "a") {
      bookingSearchDetails.adults = decodeURIComponent(value || "");
    }
    if (key === "b") {
      bookingSearchDetails.bedrooms = decodeURIComponent(value || "");
    }
    if (key === "c") {
      bookingSearchDetails.children = decodeURIComponent(value || "");
    }
    if (key === "i") {
      bookingSearchDetails.infants = decodeURIComponent(value || "");
    }
    if (key === "p") {
      bookingSearchDetails.pets = decodeURIComponent(value || "");
    }
    if (key === "dda") {
      bookingSearchDetails.dda = decodeURIComponent(value || "");
    }
  }
  if (!hasLocationsInQueryString) {
    if (!!locationIds?.[0]) {
      bookingSearchDetails.bookingSearchLocationIds = locationIds;
      bookingSearchDetails.bookingFunnelLocationIds = locationIds;
    }
    if (!!dateRange) {
      bookingSearchDetails.duration = moment(dateRange.endDateISO)
        ?.diff(moment(dateRange.startDateISO), "days")
        ?.toString();
      bookingSearchDetails.startDate = dateRange.startDateISO;
    }
    if (!!guests) {
      bookingSearchDetails.adults = guests.adults?.toString();
      bookingSearchDetails.bedrooms = guests.bedrooms?.toString();
      bookingSearchDetails.children = guests.children?.toString();
      bookingSearchDetails.infants = guests.infants?.toString();
      bookingSearchDetails.pets = guests.pets?.toString();
    }
    if (!!dda) {
      bookingSearchDetails.dda = dda.toString();
    }
    if (!!cabinTypeId) {
      bookingSearchDetails.cabinTypeId = cabinTypeId;
    }
  }
  return bookingSearchDetails;
};

const isItANewBookingSearch = (
  sstSession: SSTSessionStorageProps,
  locationIds?: string[],
  dateRange?: IDateRange,
  guests?: Guests,
  dda?: boolean,
): {
  sstSession: SSTSessionStorageProps;
  newBookingFunnel: boolean;
  newBookingSearch: boolean;
} => {
  const { bookingSearchDetails: sessionDetails } = sstSession;
  const urlDetailsString =
    generateBookingSearchDetailsAsStringFromQueryParams();
  const newDetails = getBookingSearchDetails(
    urlDetailsString,
    locationIds,
    dateRange,
    guests,
    dda,
  );
  const allUrlLocationIdsAreInOriginalFunnelDetails =
    newDetails.bookingSearchLocationIds?.every((locationId) =>
      sessionDetails.bookingFunnelLocationIds?.includes(locationId),
    );
  const locationsMatch =
    JSON.stringify(newDetails.bookingSearchLocationIds?.slice()?.sort()) ===
    JSON.stringify(sessionDetails.bookingSearchLocationIds?.slice()?.sort());
  const durationsMatch = newDetails.duration === sessionDetails.duration;
  const startDatesMatch = newDetails.startDate === sessionDetails.startDate;
  const adultsMatch = newDetails.adults === sessionDetails.adults;
  const bedroomsMatch = newDetails.bedrooms === sessionDetails.bedrooms;
  const childrenMatch = newDetails.children === sessionDetails.children;
  const infantsMatch = newDetails.infants === sessionDetails.infants;
  const petsMatch = newDetails.pets === sessionDetails.pets;
  const ddaMatch = newDetails.dda === sessionDetails.dda;

  const bookingFunnelMatches =
    durationsMatch &&
    startDatesMatch &&
    allUrlLocationIdsAreInOriginalFunnelDetails;

  const bookingSearchMatches =
    locationsMatch &&
    durationsMatch &&
    startDatesMatch &&
    adultsMatch &&
    bedroomsMatch &&
    childrenMatch &&
    infantsMatch &&
    petsMatch &&
    ddaMatch;

  sstSession.bookingSearchDetails = {
    ...newDetails,
    bookingFunnelLocationIds: allUrlLocationIdsAreInOriginalFunnelDetails
      ? sstSession.bookingSearchDetails.bookingFunnelLocationIds
      : newDetails.bookingFunnelLocationIds,
  };

  return {
    sstSession,
    newBookingFunnel: !bookingFunnelMatches,
    newBookingSearch: !bookingSearchMatches,
  };
};

const getBookingTransactionDetails = async (
  confirmation: BookingConfirmation,
): Promise<{
  bookingTransaction: SSTBookingTransaction;
  anonBookingTransaction: SSTAnonBookingTransaction;
}> => {
  let sstSession = await getSessionData({ url: "" });
  const { publicKey } = await getConsentKeyPair();
  const { sessionId, bookingFunnelSearchId, bookingFunnelId } = sstSession;
  const cabinReservations = confirmation.cabinReservations;

  const sstCabins: SSTCabin[] = [];
  if (!!cabinReservations.length) {
    cabinReservations.map((cabin: BookingSummary) => {
      if (!!cabin) {
        const preDiscountCabinPrice = (cabin.preDiscountCabinPrice ??
          0) as number;
        const discountPrice = (cabin.discountPrice ?? 0) as number;

        const sstCabin: SSTCabin = {
          bedrooms: cabin.noOfBedrooms ?? 0,
          cabinReservationId: cabin.cabinReservationId || ("" as string),
          cabinRevenueGrossDiscount: preDiscountCabinPrice - discountPrice,
          cabinRevenueGrossPreDiscount: preDiscountCabinPrice,
          cabinRevenueGrossSelling: (cabin.sellingPrice ?? 0) as number,
          cabinTypeId: cabin.cabinTypeId as string,
          checkInDate: cabin.reservationStartDate as string,
          checkOutDate: cabin.reservationEndDate as string,
          dda: cabin.disabledAccess || false,
          discountCode: cabin.discountCode || "",
          locationId: cabin.locationId || "",
          pets: cabin.petFriendly,
        };

        sstCabins.push(sstCabin);
      }
    });
  }

  let sstExtraOptions: SSTExtraOption[] = [];
  if (!!cabinReservations.length) {
    cabinReservations.map((cabin: BookingSummary) => {
      if (!!cabin) {
        const cabinReservationId = cabin.cabinReservationId;
        const extras = cabin.reservationExtras;
        sstExtraOptions = getSSTExtraOptions(extras, cabinReservationId);
      }
    });
  }

  if (!bookingFunnelId) {
    Sentry.captureException(
      `SST bookingFunnelId missing for ${
        FHEventType.bookingTransaction
      } event. ${JSON.stringify(sstSession)}`,
    );
  }

  const analyticsUnspecified = isAnalyticsConsentUnspecified();

  const transaction: SSTBookingTransaction = {
    eventType: await encryptMe(FHEventType.bookingTransaction),
    bookingReference: await encryptMe(confirmation.bookingReference),
    bookingFunnelId: await encryptMe(bookingFunnelId),
    bookingFunnelSearchId: await encryptMe(bookingFunnelSearchId),
    cabins: await encryptMe(sstCabins),
    extras: await encryptMe(sstExtraOptions),
    sessionId: await encryptMe(sessionId),
    publicKey: analyticsUnspecified ? publicKey : undefined,
    complianceAction: analyticsUnspecified
      ? FHComplianceAction.unspecifiedTracking
      : undefined,
  };

  const anonTransaction: SSTAnonBookingTransaction = {
    eventType: FHEventType.anonBookingTransaction,
    bookingReference: confirmation.bookingReference,
    cabins: sstCabins,
    extras: sstExtraOptions,
  };

  return {
    bookingTransaction: transaction,
    anonBookingTransaction: anonTransaction,
  };
};

export const setAdditionalBookingSearchValues = async (
  cabinTypeId: string,
  noOfBedsAttemptedToBook: string,
  router: string,
  referrer: string,
) => {
  const url = getUrlFromRouter(router);
  let sstSession = await getSessionData({ url });
  sstSession.bookingSearchDetails.cabinTypeId = cabinTypeId || "";
  sstSession.bookingSearchDetails.noOfBedsAttemptedToBook =
    noOfBedsAttemptedToBook || "0";
  sstSession = await touchSession(sstSession, { url, referrer });
};

export const postSSTBookingTransaction = async (
  confirmation: BookingConfirmation,
): Promise<void> => {
  if (!!confirmation) {
    const { bookingTransaction, anonBookingTransaction } =
      await getBookingTransactionDetails(confirmation);
    postSSTEvent(anonBookingTransaction, FHEventType.anonBookingTransaction);

    if (isAnalyticsConsentDeclined()) {
      return;
    }
    postSSTEvent(bookingTransaction, FHEventType.bookingTransaction);
  } else {
    Sentry.captureException(
      `SST confirmation undefined for ${FHEventType.bookingTransaction} event`,
    );
  }
};

const getLoginRegisterData = async (
  eventType: FHEventType,
  customerId: string,
): Promise<SSTLoginRegister> => {
  const sessionData = await getSessionData({ url: "" });
  const { publicKey } = await getConsentKeyPair();
  const { sessionId, pageViewId } = sessionData;
  const userId = getUserId();
  const analyticsUnspecified = isAnalyticsConsentUnspecified();

  const loginRegisterData: SSTLoginRegister = {
    eventType: await encryptMe(eventType),
    customerId: await encryptMe(customerId),
    pageViewId: await encryptMe(pageViewId),
    userId: await encryptMe(userId),
    sessionId: await encryptMe(sessionId),
    publicKey: analyticsUnspecified ? publicKey : undefined,
    complianceAction: analyticsUnspecified
      ? FHComplianceAction.unspecifiedTracking
      : undefined,
  };

  return loginRegisterData;
};

export const postSSTLoginRegisterEvent = async (
  eventType: FHEventType,
  customerId: string,
): Promise<void> => {
  if (isAnalyticsConsentDeclined()) {
    return;
  }
  const data = await getLoginRegisterData(eventType, customerId);
  postSSTEvent(data, eventType);
};

export const postSSTFirstInteractionEvent = async (
  req: NextApiRequestWithSST,
  eventType: FHEventType,
) => {
  if (isAnalyticsConsentDeclined()) {
    return;
  }
  const { publicKey } = await getConsentKeyPair();
  const sstSession: SSTSessionStorageProps = await getSessionData(req);
  const eventId = uuidv4();
  const analyticsUnspecified = isAnalyticsConsentUnspecified();

  const firstInteractionData: SessionEventData = {
    eventType: await encryptMe(eventType, "eventType - firstInteraction"),
    eventId: await encryptMe(eventId, "eventId"),
    sessionId: await encryptMe(sstSession.sessionId, "sessionId"),
    pageViewId: await encryptMe(sstSession.pageViewId, "pageViewId"),
    publicKey: analyticsUnspecified ? publicKey : undefined,
    complianceAction: analyticsUnspecified
      ? FHComplianceAction.unspecifiedTracking
      : undefined,
  };
  postSSTEvent(firstInteractionData, eventType);
};

export const resetBookingFunnel = async (req: NextApiRequestWithSST) => {
  let sstSession = await getSessionData({ url: req.url });
  const defaults = getDefaultSSTSessionStorageProps();
  sstSession = {
    ...sstSession,
    bookingFunnelId: defaults.bookingFunnelId,
    bookingFunnelSearchId: defaults.bookingFunnelSearchId,
    bookingSearchDetails: defaults.bookingSearchDetails,
  };
  await touchSession(sstSession, req);
};

const getSSTExtraOptions = (
  extras: ReservationExtra[],
  cabinReservationId?: string,
): SSTExtraOption[] => {
  const sstExtraOptions: SSTExtraOption[] = [];
  if (!!extras) {
    extras.map((extra: ReservationExtra) => {
      if (!!extra) {
        extra.reservationExtraOptions.map((option: ReservationExtraOption) => {
          if (!!option) {
            const totalPrice = (option.totalPrice ?? 0) as number;
            const sellingPrice = (option.sellingPrice ?? 0) as number;

            const sstOption: SSTExtraOption = {
              ancillaryRevenueGrossDiscount: totalPrice - sellingPrice,
              ancillaryRevenueGrossPreDiscount: totalPrice,
              ancillaryRevenueGrossSelling: sellingPrice,
              cabinReservationExtraID: option.cabinReservationExtraId || "",
              cabinReservationId: cabinReservationId || "",
              discountCode: option.extraPromotionCode || "",
              extraId: option.extraId || "",
              extraOptionId: option.extraOptionId || "",
              optionDate: extra.calendarDate || "",
              optionTime: extra.aMPM || "",
              quantity: option.quantity ?? 0,
            };

            sstExtraOptions.push(sstOption);
          }
        });
      }
    });
  }
  return sstExtraOptions;
};

const getAncillaryTransactionDetails = async (
  cabinReservationId: string,
  confirmation: BookingConfirmation,
  obtainingQuoteExtras: ReservationExtra[],
): Promise<{
  transaction: SSTAncillaryTransaction;
  anonTransaction: SSTAncillaryTransaction;
}> => {
  const { publicKey } = await getConsentKeyPair();
  const analyticsUnspecified = isAnalyticsConsentUnspecified();
  const cabin = (
    confirmation?.cabinReservations || ([] as BookingSummary[])
  ).find((c) => c?.cabinReservationId === cabinReservationId);
  const sstExtraOptions = getSSTExtraOptions(
    obtainingQuoteExtras,
    cabinReservationId,
  );

  const transaction: SSTAncillaryTransaction = {
    eventType: await encryptMe(FHEventType.ancillaryTransaction),
    bedrooms: await encryptMe(cabin?.noOfBedrooms),
    bookingReference: await encryptMe(confirmation.bookingReference),
    cabinTypeId: await encryptMe(cabin?.cabinTypeId as string),
    checkInDate: await encryptMe(cabin?.reservationStartDate as string),
    checkOutDate: await encryptMe(cabin?.reservationEndDate as string),
    dda: await encryptMe(cabin?.disabledAccess || false),
    extras: await encryptMe(sstExtraOptions),
    locationId: await encryptMe(cabin?.locationId as string),
    pets: await encryptMe(cabin?.petFriendly || false),
    sessionId: await encryptMe(window.name || ""),
    publicKey: analyticsUnspecified ? publicKey : undefined,
    complianceAction: analyticsUnspecified
      ? FHComplianceAction.unspecifiedTracking
      : undefined,
  };

  const anonTransaction: SSTAncillaryTransaction = {
    eventType: FHEventType.anonAncillaryTransaction,
    bedrooms: cabin?.noOfBedrooms,
    bookingReference: confirmation.bookingReference,
    cabinTypeId: cabin?.cabinTypeId as string,
    checkInDate: cabin?.reservationStartDate as string,
    checkOutDate: cabin?.reservationEndDate as string,
    dda: cabin?.disabledAccess || false,
    extras: sstExtraOptions,
    locationId: cabin?.locationId as string,
    pets: cabin?.petFriendly || false,
  };
  return { transaction, anonTransaction };
};

export const postSSTAncillaryTransaction = async (
  cabinReservationId: string,
  confirmation: BookingConfirmation,
  obtainingQuoteExtras: ReservationExtra[],
) => {
  const { transaction, anonTransaction } = await getAncillaryTransactionDetails(
    cabinReservationId,
    confirmation,
    obtainingQuoteExtras,
  );
  postSSTEvent(anonTransaction, FHEventType.anonAncillaryTransaction);
  if (isAnalyticsConsentDeclined()) {
    return;
  }
  postSSTEvent(transaction, FHEventType.ancillaryTransaction);
};

const getMetaData = async (url: string, headers: any) => {
  const urlObj = parseUTMFromUrl(url || "");
  const medium = urlObj.medium || null;
  const source = urlObj.source || null;
  const campaign = urlObj.campaign || null;
  const deviceType = getDeviceType() || null;
  const city = headers?.["city"] || null;
  const regionCode = headers?.["regionCode"] || null;
  const postalCode = headers?.["postalCode"] || null;
  const latitude = headers?.["latitude"] || null;
  const longitude = headers?.["longitude"] || null;
  const country = headers?.["cf-ipcountry"] || null;
  const userAgent = headers?.["user-agent"] || null;
  const browser = !!userAgent ? getBrowser(userAgent) : null;
  const ip = headers?.["cf-connecting-ip"] || null;
  const origin = headers?.["x-original-host"] || null;
  const metaData = {
    ip: await encryptMe(ip, "ip"),
    userAgent: await encryptMe(userAgent, "userAgent"),
    city: await encryptMe(city, "city"),
    regionCode: await encryptMe(regionCode, "regionCode"),
    postalCode: await encryptMe(postalCode, "postalCode"),
    latitude: await encryptMe(latitude, "latitude"),
    longitude: await encryptMe(longitude, "longitude"),
    country: await encryptMe(country, "country"),
    deviceType: await encryptMe(deviceType, "deviceType"),
    origin: await encryptMe(origin, "origin"),
    browser: await encryptMe(browser, "browser"),
    medium: await encryptMe(medium, "medium"),
    source: await encryptMe(source, "source"),
    campaign: await encryptMe(campaign, "campaign"),
  };

  return metaData;
};

const getUrlData = async (url: string) => {
  const urlObj = parseUTMFromUrl(url);
  const gad = await encryptMe(urlObj?.gad, "gad");
  const gclid = await encryptMe(urlObj.gclid, "gclid");
  const pageURLQueryString = await encryptMe(urlObj.queryString, "queryString");

  return { gad, gclid, pageURLQueryString };
};

export default getServerSideTracking;

const isEventAnon = (eventType: string) => {  
  return eventType.toLocaleLowerCase().includes("anon");
};

const postSSTEvent = async (data: any, eventType: string) => {
  const sstUrl = process.env.NEXT_PUBLIC_SERVER_SIDE_TRACKING_URL || "";

  const currentUtcTime = new Date(Date.now());
  const offset = currentUtcTime.getTimezoneOffset();
  const offsetMinutes = offset || 0;
  const offsetMilliseconds = offsetMinutes * 60 * 1000;
  const localTime = new Date(currentUtcTime.getTime() - offsetMilliseconds);

  data.utcTime = isEventAnon(eventType)
    ? currentUtcTime
    : await encryptMe(currentUtcTime, "utcTime");
  data.time = isEventAnon(eventType)
    ? localTime
    : await encryptMe(localTime, "localTime");

  if (!!sstUrl) {
    if (!!data) {
      axios
        .post(sstUrl, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .catch((error) => {
          Sentry.captureException(
            `SST ${eventType} error - ${error?.message} `,
          );
        });
    } else {
      Sentry.captureException(`SST no data provided for ${eventType} event`);
    }
  } else {
    Sentry.captureException("SST url missing");
  }
};

export const hasExistingBookingFunnel = async (router: string) => {
  const url = getUrlFromRouter(router);
  let sstSession = await getSessionData({ url });
  if (!sstSession || !sstSession?.bookingFunnelId) return false;
  return true;
};
