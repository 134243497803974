import { useState } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { HttpStatusCode } from "axios";
import { FHCookies } from "src/interfaces/cookies";
import { getCookie, setCookie } from "cookies-next";
import { useRouter } from "next/router";

const useStyles = () =>
  makeStyles((theme) => ({
    container: {
      position: "absolute",
      left: 10,
      top: 10,
      zIndex: 1500,
    },
    button: {
      color: "white",
      textDecoration: "underline",
      "&:hover": {
        backgroundColor: "white",
        textDecoration: "underline",
      },
    },
  }))();

export interface ConfirmationDialogRawProps {
  id: string;
  keepMounted: boolean;
  value: string;
  open: boolean;
  onClose: (value?: string) => void;
}

export const DeveloperTools = () => {
  const classes = useStyles();
  const forcedExperimentCookie = getCookie(FHCookies.forced);
  const router = useRouter();

  const checkedExperiments = forcedExperimentCookie
    ? JSON.parse(forcedExperimentCookie as string)
    : [];

  const [change, setChange] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [checked, setChecked] = useState([]);
  const [experimentCount, setExperimentCount] = useState(0);
  const [activeCount, setActiveCount] = useState(0);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    if (change) {
      router.reload();
    }
  };

  const getExperiments = async () => {
    try {
      const response = await fetch("/api/get-experiments", {
        headers: {
          accept: "application/json",
        },
      });

      if (HttpStatusCode.Ok === response.status) {
        const data = await response.json();
        setOptions(data);
        setExperimentCount(data.length);
        setChecked(checkedExperiments);
        setActiveCount(checkedExperiments.length);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  if ("undefined" !== typeof window && 0 == options.length) {
    getExperiments();
  }

  const handleChange = (value: any) => {
    const currentIndex = checkedExperiments.indexOf(value);
    const newChecked = [...checkedExperiments];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChange(true);
    setChecked(newChecked);
    setActiveCount(newChecked.length);
    setCookie(FHCookies.forced, JSON.stringify(newChecked), {});
  };

  return (
    <div className={classes.container}>
      <Button variant="contained" size="large" onClick={handleClickOpen}>
        Experiments ({activeCount}/{experimentCount})
      </Button>
      <Dialog open={open}>
        <DialogTitle>
          Experiments ({activeCount}/{experimentCount})
        </DialogTitle>
        <DialogContent dividers>
          <List>
            {options.map((option) => (
              <ListItem key={option} disablePadding>
                <ListItemButton
                  dense
                  role={undefined}
                  onClick={() => handleChange(option)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checked.indexOf(option) !== -1}
                      tabIndex={-1}
                      disableRipple
                      inputProps={{
                        "aria-labelledby": `checkbox-list-label-${option}`,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={`checkbox-list-label-${option}`}
                    primary={option}
                  />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
