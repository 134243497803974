import { createSlice } from "@reduxjs/toolkit";
import {
  AddSpecificCabinResponse,
  MutationRemoveSpecificCabinArgs,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type RemoveSpecificCabinState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<AddSpecificCabinResponse>;
};

const initialState: RemoveSpecificCabinState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const cabinsSpecificRemoveSlice = createSlice({
  name: "cabinsSpecificRemove",
  initialState,
  reducers: {
    removeSpecificCabin: (
      state,
      action: PayloadAction<{
        removeSpecificCabinInput: MutationRemoveSpecificCabinArgs;
        isPostBookingJourney: boolean;
      }>
    ) => {
      state.loading = true;
      state.loaded = false;
      state.data = null;
      state.errorMessage = null;
      state.hasError = false;
    },
    removeSpecificCabinSuccess: (
      state,
      action: PayloadAction<{
        removeSpecificCabinResponse: AddSpecificCabinResponse;
        isPostBookingJourney: boolean;
      }>
    ) => {
      state.data = action.payload.removeSpecificCabinResponse;
      state.loaded = true;
      state.loading = false;
      state.errorMessage = null;
      state.hasError = false;
    },
    removeSpecificCabinFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loading = false;
      state.loaded = false;
      state.data = null;
    },
  },
});

export const {
  removeSpecificCabin,
  removeSpecificCabinSuccess,
  removeSpecificCabinFail,
} = cabinsSpecificRemoveSlice.actions;

export default cabinsSpecificRemoveSlice.reducer;
