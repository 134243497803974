import { createSlice } from "@reduxjs/toolkit";
import { ScheduledPayments } from "../../components/SemanticTheme/ScheduledPayments";
import {
  QueryScheduledPaymentsByBookingIdArgs,
  ScheduledPayment,
  ScheduledPaymentsByBookingIdResponse,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";
import { getFilteredScheduledPaymentsForBooking } from "./scheduled-payments-by-booking.slice";

export type ScheduledPaymentsByBookingIdState = {
  loading: boolean;
  loaded: boolean;
  data: Nullable<ScheduledPaymentsByBookingIdResponse>;
  errorMessage: Nullable<string>;
  upcomingScheduledPayments: ScheduledPayments;
  lastFailedPayment: Nullable<ScheduledPayment>;
  successfulPayment: Nullable<number>;
  hasError: boolean;
};

const initialState: ScheduledPaymentsByBookingIdState = Object.freeze({
  loading: false,
  loaded: false,
  data: null,
  errorMessage: null,
  hasError: false,
  upcomingScheduledPayments: { totalValue: 0, items: [] },
  lastFailedPayment: null,
  successfulPayment: null,
});

export const scheduledPaymentsByBookingIdSlice = createSlice({
  name: "scheduledPaymentsByBookingId",
  initialState,
  reducers: {
    getScheduledPaymentsByBookingIdAction: (
      state,
      action: PayloadAction<QueryScheduledPaymentsByBookingIdArgs>
    ) => {
      state.loading = true;
      state.data = null;
      state.errorMessage = null;
      state.loaded = false;
      state.lastFailedPayment = null;
      state.successfulPayment = null;
      state.upcomingScheduledPayments = { totalValue: 0, items: [] };
      state.hasError = false;
    },
    getScheduledPaymentsByBookingIdSuccessAction: (
      state,
      action: PayloadAction<ScheduledPaymentsByBookingIdResponse>
    ) => {
      const data = action.payload;
      const upcomingScheduledPayments: ScheduledPayments =
        getFilteredScheduledPaymentsForBooking(data?.upcomingPayments);
      return {
        ...initialState,
        data: data,
        loaded: true,
        upcomingScheduledPayments:
          upcomingScheduledPayments?.items?.length > 0
            ? upcomingScheduledPayments
            : initialState.upcomingScheduledPayments,
        lastFailedPayment:
          (data?.lastFailedPayment as ScheduledPayment) || null,
      };
    },
    getScheduledPaymentsByBookingIdFailAction: (
      state,
      action: PayloadAction<string>
    ) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.data = null;
      state.loaded = false;
      state.loading = false;
      state.lastFailedPayment = null;
      state.successfulPayment = null;
      state.upcomingScheduledPayments = { totalValue: 0, items: [] };
    },
    setSuccessfulFailedPaymentValue: (state, action) => {
      state.successfulPayment = action.payload;
    },
    successfulPaymentMessageOnClose: (state, action) => {
      state.successfulPayment = null;
      state.loading = false;
      state.data = null;
      state.errorMessage = null;
      state.loaded = false;
      state.lastFailedPayment = null;
      state.upcomingScheduledPayments = { totalValue: 0, items: [] };
      state.hasError = false;
    },
  },
});

export const {
  getScheduledPaymentsByBookingIdAction,
  getScheduledPaymentsByBookingIdFailAction,
  getScheduledPaymentsByBookingIdSuccessAction,
  setSuccessfulFailedPaymentValue,
  successfulPaymentMessageOnClose,
} = scheduledPaymentsByBookingIdSlice.actions;

export default scheduledPaymentsByBookingIdSlice.reducer;
