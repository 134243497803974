import { createSlice } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  BookingDataForGaResponse,
  QueryBookingDataForGaArgs,
} from "@generated/types";

export type BookingDataForGAState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  getBookingDataForGARequest?: QueryBookingDataForGaArgs;
  bookingDataForGA: Nullable<BookingDataForGaResponse>;
};

const initialState: BookingDataForGAState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  bookingDataForGA: null,
});

export const bookingDataForGASlice = createSlice({
  name: "bookingDataForGA",
  initialState,
  reducers: {
    getBookingDataForGAAction: (
      state,
      action: PayloadAction<QueryBookingDataForGaArgs>
    ) => {
      state.getBookingDataForGARequest = action.payload;
      state.loading = true;
    },
    getBookingDataForGASuccessAction: (
      state,
      action: PayloadAction<BookingDataForGaResponse>
    ) => {
      state.bookingDataForGA = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    getBookingDataForGAFailAction: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.loaded = true;
      state.errorMessage =
        action?.payload || "An error occurred. Please try again.";
      state.loading = false;
      state.bookingDataForGA = null;
    },
  },
});

export const {
  getBookingDataForGAAction,
  getBookingDataForGAFailAction,
  getBookingDataForGASuccessAction,
} = bookingDataForGASlice.actions;

export default bookingDataForGASlice.reducer;
