import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import {
  ApplyGiftVoucherResponse,
  MutationApplyGiftVoucherToBookingArgs,
} from "@generated/types";

export type ApplyVoucherState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<ApplyGiftVoucherResponse>;
};

const initialState: ApplyVoucherState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const applyVoucherSlice = createSlice({
  name: "voucherApply",
  initialState,
  reducers: {
    applyVoucher: (
      state,
      action: PayloadAction<MutationApplyGiftVoucherToBookingArgs>,
    ) => {
      state.loading = true;
      state.data = null;
      state.loaded = false;
      state.errorMessage = null;
      state.hasError = false;
    },
    applyVoucherSuccess: (
      state,
      action: PayloadAction<{
        response: ApplyGiftVoucherResponse;
        bookingId: string;
      }>,
    ) => {
      state.data = action.payload.response;
      state.loaded = true;
      state.loading = false;
    },
    applyVoucherFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.loaded = true;
      state.loading = false;
      state.errorMessage = action.payload;
      state.data = null;
    },
  },
});

export const { applyVoucher, applyVoucherFail, applyVoucherSuccess } =
  applyVoucherSlice.actions;

export default applyVoucherSlice.reducer;
