import { RootState } from "../slices";
import { useSelector } from "react-redux";

export const createPaymentStateSelector = (state: RootState) =>
  state.createPayment;
export const paymentOptionsStateSelector = (state: RootState) =>
  state.paymentOptions;
export const useCreatePaymentStateSelector = () =>
  useSelector(createPaymentStateSelector);
export const usePaymentOptionsStateSelector = () =>
  useSelector(paymentOptionsStateSelector);
