import { createSlice } from "@reduxjs/toolkit";
import {
  QuerySingleFutureCabinReservationArgs,
  SingleFutureCabinReservationResponse,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type SingleFutureCabinReservationState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<SingleFutureCabinReservationResponse>;
};

const initialState: SingleFutureCabinReservationState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const singleFutureBookingSlice = createSlice({
  name: "singleFutureBooking",
  initialState,
  reducers: {
    getSingleFutureCabinReservation: (
      state,
      action: PayloadAction<QuerySingleFutureCabinReservationArgs>
    ) => {
      state.loading = true;
    },
    getSingleFutureCabinReservationSuccess: (
      state,
      action: PayloadAction<SingleFutureCabinReservationResponse>
    ) => {
      state.data = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    getSingleFutureCabinReservationFail: (
      state,
      action: PayloadAction<string>
    ) => {
      state.hasError = true;
      state.loaded = true;
      state.errorMessage =
        action?.payload || "An error occurred. Please try again.";
      state.data = null;
      state.loading = false;
    },
  },
});

export const {
  getSingleFutureCabinReservation,
  getSingleFutureCabinReservationSuccess,
  getSingleFutureCabinReservationFail,
} = singleFutureBookingSlice.actions;

export default singleFutureBookingSlice.reducer;
