import { createSlice } from "@reduxjs/toolkit";
import {
  BookingSummary_S,
  MutationCreateReservationFromReferralArgs,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";
import { InterstitialBookingSearch } from "src/interfaces/guests";

export type ReservationFromReferralState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<BookingSummary_S>;
  referrer: Nullable<string>;
  bookingSearch: Nullable<InterstitialBookingSearch>;
};

const initialState: ReservationFromReferralState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
  referrer: null,
  bookingSearch: null,
});

export const reservationFromReferralSlice = createSlice({
  name: "reservationFromReferral",
  initialState,
  reducers: {
    getReservationFromReferral: (
      state,
      action: PayloadAction<MutationCreateReservationFromReferralArgs>
    ) => {
      state.hasError = false;
      state.loaded = false;
      state.errorMessage = null;
      state.loading = true;
      state.data = null;
    },
    getReservationFromReferralSuccess: (
      state,
      action: PayloadAction<BookingSummary_S>
    ) => {
      state.hasError = false;
      state.loaded = true;
      state.errorMessage = null;
      state.loading = false;
      state.data = action.payload;
    },
    getReservationFromReferralFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.loaded = false;
      state.errorMessage = action.payload;
      state.loading = false;
      state.data = null;
    },
    setReferrer: (state, action: PayloadAction<string>) => {
      state.referrer = action.payload;
    },
    setRefererBookingSearch: (
      state,
      action: PayloadAction<InterstitialBookingSearch>
    ) => {
      state.bookingSearch = action.payload;
    },
    resetReservationFromReferral: (state) => {
      state.hasError = false;
      state.loaded = false;
      state.errorMessage = null;
      state.loading = false;
      state.bookingSearch = null;
      state.referrer = null;
    },
  },
});

export const {
  getReservationFromReferral,
  getReservationFromReferralSuccess,
  getReservationFromReferralFail,
  setRefererBookingSearch,
  setReferrer,
  resetReservationFromReferral,
} = reservationFromReferralSlice.actions;

export default reservationFromReferralSlice.reducer;
