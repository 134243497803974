import { Nullable } from "../../utils";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  GetAddressFromPostCodeResponse,
  GetPostCodeArgs,
} from "../../graphql/bsl/gql-generated/custom-types";

export type PostCodeAddressesState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<GetAddressFromPostCodeResponse>;
};

const initialState: PostCodeAddressesState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const postCodeAddressesSlice = createSlice({
  name: "postCodeAddresses",
  initialState,
  reducers: {
    getPostCodeAddresses: (state, action: PayloadAction<GetPostCodeArgs>) => {
      state.loading = true;
      state.errorMessage = null;
      state.hasError = false;
      state.loaded = false;
      state.data = null;
    },
    getPostCodeAddressesSuccess: (
      state,
      action: PayloadAction<GetAddressFromPostCodeResponse>
    ) => {
      state.data = action.payload;
      state.loaded = true;
      state.loading = false;
      state.errorMessage = null;
      state.hasError = false;
    },
    getPostCodeAddressesFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loading = false;
      state.loaded = false;
      state.data = null;
    },
  },
});

export const {
  getPostCodeAddresses,
  getPostCodeAddressesSuccess,
  getPostCodeAddressesFail,
} = postCodeAddressesSlice.actions;

export default postCodeAddressesSlice.reducer;
