import { createSlice } from "@reduxjs/toolkit";
import {
  AddSpecificCabinResponse,
  MutationAddSpecificCabinArgs,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type AddSpecificCabinState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<AddSpecificCabinResponse>;
};

const initialState: AddSpecificCabinState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const cabinsSpecificAddSlice = createSlice({
  name: "cabinsSpecificAdd",
  initialState,
  reducers: {
    addSpecificCabin: (
      state,
      action: PayloadAction<{
        addSpecificCabinInput: MutationAddSpecificCabinArgs;
        isPostBookingJourney: boolean;
      }>
    ) => {
      state.loading = true;
      state.loaded = false;
      state.data = null;
      state.errorMessage = null;
      state.hasError = false;
    },
    addSpecificCabinSuccess: (
      state,
      action: PayloadAction<{
        addSpecificCabinResponse: AddSpecificCabinResponse;
        isPostBookingJourney: boolean;
      }>
    ) => {
      state.data = action.payload.addSpecificCabinResponse;
      state.loaded = true;
      state.loading = false;
      state.errorMessage = null;
      state.hasError = false;
    },
    addSpecificCabinFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loading = false;
      state.loaded = false;
      state.data = null;
    },
    clearCabins: (state, action) => {
      initialState;
    },
  },
});

export const {
  addSpecificCabin,
  addSpecificCabinSuccess,
  addSpecificCabinFail,
  clearCabins,
} = cabinsSpecificAddSlice.actions;

export default cabinsSpecificAddSlice.reducer;
