import { createSlice } from "@reduxjs/toolkit";
import {
  Category_S as Category,
  LocationAvailabilityRequestInput,
  LocationAvailabilityResponse,
  QueryLocationAvailabilityArgs,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type SearchLocationState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<LocationAvailabilityResponse>;
  sortMethod: string;
  availableFilters: Category[];
  activeFilters: string[];
  locationSearchData: LocationAvailabilityRequestInput;
};

const initialState: SearchLocationState = {
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
  sortMethod: "CABIN AVAILABILITY", // TODO: default should ideally be set on EPI in case it needs to change
  availableFilters: [],
  activeFilters: [],
  locationSearchData: null,
};

export const locationSearchSlice = createSlice({
  name: "locationSearch",
  initialState,
  reducers: {
    getAvailableLocations: (
      state,
      action: PayloadAction<{
        locationsQuery: QueryLocationAvailabilityArgs;
        filtered: boolean;
      }>
    ) => {
      state.loading = true;
      state.locationSearchData = action?.payload?.locationsQuery?.request;
    },
    getAvailableLocationsSuccess: (
      state,
      action: PayloadAction<{
        summaryResponse: LocationAvailabilityResponse;
        filtered: boolean;
      }>
    ) => {
      const availableFilters = action.payload.filtered
        ? state.availableFilters
        : (action.payload.summaryResponse.availableFilters as Category[]);
      const activeFilters = action.payload.filtered ? state.activeFilters : [];

      return {
        ...initialState,
        locationSearchData: state.locationSearchData,
        data: action.payload.summaryResponse,
        loaded: true,
        sortMethod: state.sortMethod,
        availableFilters,
        activeFilters,
      };
    },
    getAvailableLocationsFail: (state, action: PayloadAction<string>) => ({
      ...initialState,
      hasError: true,
      errorMessage: action.payload,
      sortMethod: state.sortMethod,
    }),
    setLocationSortMethod: (state, action: PayloadAction<string>) => {
      state.sortMethod = action.payload;
    },
    setLocationSearchFilter: (
      state,
      action: PayloadAction<{
        sortMethod: string | false;
        filters: string[] | false;
      }>
    ) => ({
      ...state,
      sortMethod: action.payload.sortMethod || state.sortMethod,
      activeFilters: action.payload.filters || state.activeFilters,
    }),
  },
});

export const {
  getAvailableLocations,
  getAvailableLocationsSuccess,
  getAvailableLocationsFail,
  setLocationSearchFilter,
  setLocationSortMethod,
} = locationSearchSlice.actions;

export default locationSearchSlice.reducer;
