import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import { Vehicle } from "../../components/SemanticTheme/interfaces/guests";
import { MutationAddUpdateVehicleArgs } from "@generated/types";

export type VehicleAddState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<Vehicle>;
};

const initialState: VehicleAddState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const vehicleAddSlice = createSlice({
  name: "vehicleAdd",
  initialState,
  reducers: {
    addVehicle: (
      state,
      action: PayloadAction<MutationAddUpdateVehicleArgs>
    ) => {
      state.loading = true;
      state.loaded = false;
      state.errorMessage = null;
      state.hasError = false;
      state.data = null;
    },
    addVehicleSuccess: (state, action: PayloadAction<Vehicle>) => {
      state.data = action.payload;
      state.loading = false;
      state.loaded = true;
      state.errorMessage = null;
      state.hasError = false;
    },
    addVehicleFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loaded = false;
      state.loading = false;
      state.data = null;
    },
  },
});

export const { addVehicle, addVehicleFail, addVehicleSuccess } =
  vehicleAddSlice.actions;

export default vehicleAddSlice.reducer;
