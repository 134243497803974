import { useSelector } from "react-redux";
import { RootState } from "../slices";

export const voucherStateSelector = (state: RootState) => state.voucher;
export const voucherRemoveStateSelector = (state: RootState) =>
  state.voucherRemove;

export const useVoucherStateSelector = () => useSelector(voucherStateSelector);
export const useVoucherRemoveStateSelector = () =>
  useSelector(voucherRemoveStateSelector);
