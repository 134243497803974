export enum FHCookies {
  user = "fh.userId",
  sessionId = "fh.sessionId",
  cookieId = "fh.cookieId",
  active = "fh.active",
  developerTools = "fh.developerTools",
  forced = "fh.forcedExperiments",
  mouseMovement = "fh.mouseMovement",
  interstitalTracking = "fh.interstitalTracking",
  bookingId = "fh.bookingId",
  optanonKeys = "fh.optanonKeys",
}
