import { createSlice } from "@reduxjs/toolkit";
import {
  ConfirmedBookingCabin,
  ConfirmedBookingInfo,
  ConfirmedBookingInfoResponse,
  QueryConfirmedBookingInfoArgs,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type ConfirmedBookingInfoState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  getConfirmedBookingInfoRequest?: QueryConfirmedBookingInfoArgs;
  confirmedBookingInfo: Nullable<ConfirmedBookingInfo>;
  cabin: Nullable<ConfirmedBookingCabin>;
};

const initialState: ConfirmedBookingInfoState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  confirmedBookingInfo: null,
  cabin: null,
});

export const confirmedBookingInfoSlice = createSlice({
  name: "confirmedBookingInfo",
  initialState,
  reducers: {
    getConfirmedBookingInfoAction: (
      state,
      action: PayloadAction<QueryConfirmedBookingInfoArgs>
    ) => {
      state.getConfirmedBookingInfoRequest = action.payload;
      state.loading = true;
    },
    getConfirmedBookingInfoSuccessAction: (
      state,
      action: PayloadAction<ConfirmedBookingInfoResponse>
    ) => {
      const confirmedBookingInfo = action.payload
        .confirmedBookingInfo as ConfirmedBookingInfo;
      const cabin =
        !!confirmedBookingInfo.cabinReservations &&
        !!confirmedBookingInfo.cabinReservations[0]
          ? (confirmedBookingInfo.cabinReservations[0] as ConfirmedBookingCabin)
          : null;

      state.confirmedBookingInfo = confirmedBookingInfo;
      state.cabin = cabin;
      state.loaded = true;
      state.loading = false;
    },
    getConfirmedBookingInfoFailAction: (
      state,
      action: PayloadAction<string>
    ) => {
      state.hasError = true;
      state.errorMessage =
        action?.payload ||
        "An error occurred with confirmed booking info. Please try again.";
      state.loaded = false;
      state.loading = false;
      state.cabin = null;
      state.confirmedBookingInfo = null;
    },
  },
});

export const {
  getConfirmedBookingInfoAction,
  getConfirmedBookingInfoSuccessAction,
  getConfirmedBookingInfoFailAction,
} = confirmedBookingInfoSlice.actions;

export default confirmedBookingInfoSlice.reducer;
