import { useSelector } from "react-redux";
import { RootState } from "../slices";

// Featured Extras List
export const featuredExtraSelector = (state: RootState) => state.featuredExtra;
export const useFeaturedExtraSelector = () =>
  useSelector(featuredExtraSelector);

export const featuredExtraListSelector = (state: RootState) =>
  state.featuredExtra?.featuredExtrasList;

export const useFeaturedExtraListSelector = () =>
  useSelector(featuredExtraListSelector);

// Add Featured Extra
export const useAddFeaturedExtraSelector = () =>
  useSelector((state: RootState) => state.featuredExtra?.addFeaturedExtra);

// Remove Featured Extra
export const useRemoveFeaturedExtraSelector = () =>
  useSelector((state: RootState) => state.featuredExtra?.removeFeaturedExtra);

//Featured Extras Details
export const useFeaturedExtraDetailsContentSelector = () =>
  useSelector((state: RootState) => state.featuredExtraDetails);

// Extra stock availability
export const featuredExtraStockSelector = (state: RootState) =>
  state.featuredExtraStock;
export const useFeaturedExtraStockSelector = () =>
  useSelector(featuredExtraStockSelector);
