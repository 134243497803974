import { RootState } from "../slices";
import { useSelector } from "react-redux";
import { getErrorMessageByKey } from "../../components/Utils";

export const errorMessagesStateSelector = (state: RootState) =>
  state.errorMessages;
export const useErrorMessagesStateSelector = () =>
  useSelector(errorMessagesStateSelector);

export const errorMessagesDataSelector = (state: RootState) =>
  state.errorMessages.errorMessages;

export const useFindErrorMessageSelector = (key: string) =>
  getErrorMessageByKey(key, useSelector(errorMessagesDataSelector));
