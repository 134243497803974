import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import {
  AttendeesSummary,
  QueryAttendeesSummaryArgs,
  ReservedCabinInformation,
} from "@generated/types";
import {
  AttendeesGuest,
  ValidAgeGroups,
  Vehicle,
} from "../../components/SemanticTheme/interfaces/guests";

export type AttendeeSummaryState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<AttendeesSummary>;
  lead?: AttendeesGuest;
  guests: AttendeesGuest[];
  vehicles: Vehicle[];
  ageGroups?: ValidAgeGroups;
};

const initialState: AttendeeSummaryState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
  guests: [],
  vehicles: [],
});

export const attendeeSummarySlice = createSlice({
  name: "attendeeSummary",
  initialState,
  reducers: {
    getAttendeeSummary: (
      state,
      action: PayloadAction<QueryAttendeesSummaryArgs>
    ) => {
      state.loading = true;
      state.loaded = false;
      state.errorMessage = null;
      state.hasError = false;
      state.data = null;
      state.guests = [];
      state.vehicles = [];
    },
    getAttendeeSummarySuccess: (
      state,
      action: PayloadAction<AttendeesSummary>
    ) => {
      const firstCabin: ReservedCabinInformation = (action.payload.cabinsInfo ||
        [])[0] as ReservedCabinInformation;
      state.data = action.payload;
      state.loaded = true;
      state.lead = firstCabin?.leadGuest as AttendeesGuest;
      state.guests = (firstCabin?.otherGuests || []) as AttendeesGuest[];
      state.vehicles = (firstCabin?.vehicles || []) as Vehicle[];
      state.loading = false;
      state.errorMessage = null;
      state.hasError = false;
    },
    getAttendeeSummaryFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loaded = false;
      state.loading = false;
      state.data = null;
      state.data = null;
      state.guests = [];
      state.vehicles = [];
    },
  },
});

export const {
  getAttendeeSummary,
  getAttendeeSummaryFail,
  getAttendeeSummarySuccess,
} = attendeeSummarySlice.actions;

export default attendeeSummarySlice.reducer;
