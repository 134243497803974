import { createSlice } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";
import { QueryExtraDetailsPageUrlArgs } from "@generated/types";

export type ExtraDetailsPageState = {
  loaded: boolean;
  loading: boolean;
  extraDetailsPageUrl: Nullable<string>;
  hasError: boolean;
  errorMessage: Nullable<string>;
};

const initialState: ExtraDetailsPageState = Object.freeze({
  loaded: false,
  loading: false,
  extraDetailsPageUrl: null,
  hasError: false,
  errorMessage: null,
});

export const extraDetailsPageSlice = createSlice({
  name: "extraDetailsPage",
  initialState,
  reducers: {
    getExtraDetailsPageUrl: (
      state,
      action: PayloadAction<QueryExtraDetailsPageUrlArgs>
    ) => {
      state.loading = true;
    },
    getExtraDetailsPageUrlSuccess: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.loaded = true;
      state.extraDetailsPageUrl = action.payload;
    },
    getExtraDetailsPageUrlFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loaded = false;
      state.loading = false;
      state.extraDetailsPageUrl = null;
    },
    removeExtraDetailsPageContent: () => ({
      ...initialState,
    }),
  },
});

export const {
  getExtraDetailsPageUrl,
  getExtraDetailsPageUrlSuccess,
  getExtraDetailsPageUrlFail,
  removeExtraDetailsPageContent,
} = extraDetailsPageSlice.actions;

export default extraDetailsPageSlice.reducer;
