import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import {
  QueryScheduledPaymentsArgs,
  ScheduledPaymentsResponse,
} from "@generated/types";

export type ScheduledPaymentsState = {
  loading: boolean;
  data: Nullable<ScheduledPaymentsResponse>;
  errorMessage: Nullable<string>;
  loaded: boolean;
  hasError: boolean;
};

const initialState: ScheduledPaymentsState = Object.freeze({
  loading: false,
  data: null,
  errorMessage: null,
  loaded: false,
  hasError: false,
});

export const scheduledPaymentsSlice = createSlice({
  name: "scheduledPayments",
  initialState,
  reducers: {
    getScheduledPaymentAction: (
      state,
      action: PayloadAction<QueryScheduledPaymentsArgs>
    ) => {
      state.loading = true;
      state.data = null;
      state.errorMessage = null;
      state.loaded = false;
      state.hasError = false;
    },
    getScheduledPaymentsSuccess: (
      state,
      action: PayloadAction<ScheduledPaymentsResponse>
    ) => {
      state.data = action.payload;
      state.loaded = true;
      state.loading = false;
      state.errorMessage = null;
      state.hasError = false;
    },
    getScheduledPaymentsFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loaded = false;
      state.loading = false;
      state.data = null;
    },
  },
});

export const {
  getScheduledPaymentAction,
  getScheduledPaymentsFail,
  getScheduledPaymentsSuccess,
} = scheduledPaymentsSlice.actions;
export default scheduledPaymentsSlice.reducer;
