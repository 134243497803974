import { createTheme } from "@mui/material/styles";
import {
  ernestineW05Bold,
  goldplayW05Medium,
  goldplayW05SemiBold,
} from "../localFonts";

// Create a theme instance.
const theme = createTheme({
  spacing: 10,
  palette: {
    common: {
      black: "#000000",
      white: "#FFFFFF",
    },
    custom: {
      experimentChangeBgColourToGrey: "#F5F5F5",
    },
    // Shades of green ONLY
    primary: {
      main: "#22A672", // Grass Green
      dark: "#055845", // Forest Green
      light: "#25C495", // Fern Green
      contrastText: "#fff",
    },
    // Shades of orange ONLY
    secondary: {
      main: "#F04E23", // Call to action orange - Pumpkin
      dark: "#D0021B", // Red
      light: "#FDC652", // Dandelion
    },
    error: {
      main: "#d32f2f",
      light: "#ef5350",
      dark: "#c62828",
    },
    warning: {
      main: "#e6735e", // Autumn Leaf
      light: "#ffe500", // Sunflower
      dark: "#f5a623", // Amber
    },
    text: {
      primary: "#2B2B2B", // almost black
      secondary: "#474444", // Charcoal
      disabled: "#989391", // Ash
    },
    info: {
      main: "#307974", // Pine
      light: "#89d0c8", // Duck Egg
      dark: "#004540", // Spruce
    },
    success: {
      main: "#bed62f", // Spring Green
      light: "#005675", // Twilight
      dark: "#78230b", // Nut Brown
    },
    action: {
      active: "#b3b3b3", // Legacy Light Grey
      disabled: "#D8D8D8",
      hover: "#f7f7f7",
    },
    background: {
      default: "#ffffff", // White
      paper: "#ffffff", // White
    },
    grey: {
      50: "#f6f6f6", // Legacy Grey
      100: "#989391", // Ash
      200: "#474444", // Charcoal
      300: "#E0E0E0", // Grey Black
      400: "#8c8c8c",
      500: "#CECECE",
      600: "#5C5C5C", // Dark Grey
      700: "#e7d7d3",
      800: "#FBFBFB", // Off White
      900: "#F2F5F5",
      A100: "#FEFBF4",
      A200: "#FEF0CD", // Cream
      A400: "#71634e", // Dark Oak
      A700: "#949494", // Gravel
    },
    divider: "#F2F2F2", // Light Grey
  },
  typography: {
    fontFamily: goldplayW05Medium.style.fontFamily,
    allVariants: {
      color: "#2B2B2B",
    },
    button: {
      fontSize: "16px",
      fontWeight: 600,
    },
    body1: {
      fontSize: "16px",
      fontFamily: goldplayW05Medium.style.fontFamily,
      fontWeight: 500,
      lineHeight: "24px",
      "& strong, & b": {
        fontFamily: goldplayW05SemiBold.style.fontFamily,
        fontWeight: 600,
      },
    },
    body2: {
      fontSize: "16px",
      fontFamily: goldplayW05Medium.style.fontFamily,
      fontWeight: 500,
      lineHeight: "22px",
      "& strong, & b": {
        fontFamily: goldplayW05SemiBold.style.fontFamily,
        fontWeight: 600,
      },
    },
    caption: {
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "20px",
      "& strong, & b": {
        fontFamily: goldplayW05SemiBold.style.fontFamily,
        fontWeight: 600,
      },
    },
    h1: {
      fontSize: "30px",
      fontWeight: 700,
      fontFamily: ernestineW05Bold.style.fontFamily,
      lineHeight: "35px",
      "@media (max-width:600px)": {
        fontSize: "30px",
        lineHeight: "35px",
      },
    },
    h2: {
      fontSize: "25px",
      fontWeight: 600,
      fontFamily: goldplayW05SemiBold.style.fontFamily,
      lineHeight: "35px",
      "@media (max-width:600px)": {
        fontSize: "25px",
        lineHeight: "35px",
      },
    },
    h3: {
      fontSize: "22px",
      fontFamily: goldplayW05SemiBold.style.fontFamily,
      lineHeight: "24px",
      "@media (max-width:600px)": {
        fontSize: "20px",
        lineHeight: "24px",
      },
    },
    h4: {
      fontSize: "20px",
      fontFamily: goldplayW05SemiBold.style.fontFamily,
      lineHeight: "22px",
      "& strong, & b": {
        fontWeight: 600,
      },
      "@media (max-width:600px)": {
        fontSize: "18px",
        lineHeight: "22px",
      },
    },
    h5: {
      fontSize: "17px",
      fontFamily: goldplayW05SemiBold.style.fontFamily,
      lineHeight: "22px",
      "& strong, & b": {
        fontWeight: 600,
      },
    },
    subtitle1: {
      fontSize: "22px",
      lineHeight: "24px",
      "@media (max-width:600px)": {
        fontSize: "20px",
      },
    }, // h2.alt
    subtitle2: {
      fontSize: "20px",
      lineHeight: "26px",
      "@media (max-width:600px)": {
        fontSize: "18px",
        lineHeight: "25px",
      },
    }, // h4.alt
  },
  shape: {
    borderRadius: 5,
  },
  shadows: [
    "none",
    "0px 1px 7px rgba(0, 0, 0, 0.2)",
    "0px 1px 7px rgba(0,0,0,0.3)",
    "0px 3px 6px rgba(0,0,0,0.8)",
    "0px 3px 6px rgba(0,0,0,0.3)",
    "inset 0px 3px 6px #00000080, 0px 3px 6px #00000029",
    "0px 3px 6px rgba(0,0,0,0.36)",
    "3px 3px 6px rgba(0,0,0,0.36)",
    "0px 0px 5px rgba(0,0,0,0.36)",
    "0px 5px 6px rgba(0,0,0,0.5)",
    "0px 1px 7px rgba(0, 0, 0, 0.16)",
    "0px 1px 7px rgba(0, 0, 0, 0.3)",
    "0px 10px 20px #00000054",
    "0px 10px 30px #D9D9D966",
    "0px 0px 10px #00000080",
    "0px 5px 6px rgba(0, 0, 0, 0.12)",
    "0px 1px 7px rgba(0, 0, 0, 0.3)",
    "0px 1px 7px #00000033",
    "0px 1px 7px rgba(0, 0, 0, 0.3)",
    "0px 1px 7px rgba(0, 0, 0, 0.3)",
    "0px 1px 7px rgba(0, 0, 0, 0.3)",
    "0px 1px 7px rgba(0, 0, 0, 0.3)",
    "0px 1px 7px rgba(0, 0, 0, 0.3)",
    "0px 1px 7px rgba(0, 0, 0, 0.3)",
    "0px 1px 7px rgba(0, 0, 0, 0.3)",
  ],
  zIndex: {
    modal: 5005,
    tooltip: 5006,
    drawer: 5005,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderWidth: 2,
          textTransform: "none",
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          marginBottom: 20,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        elevation1: {
          boxShadow: "0px 1px 7px 0 rgba(0,0,0,0.2)",
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

export default theme;
