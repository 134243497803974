import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import {
  GiftVoucherIsValidResponse,
  MutationRemoveGiftVoucherFromBookingArgs,
} from "@generated/types";
import { Voucher } from "../../components/SemanticTheme/VoucherForm";

export type VoucherState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<GiftVoucherIsValidResponse>;
  currentVoucherCode?: string;
  voucherToRemove?: string | null;
  vouchers: Voucher[];
  totalVoucherDiscount: number;
  totalVoucherValueLost: number;
};

const initialState: VoucherState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
  vouchers: [],
  totalVoucherDiscount: 0,
  totalVoucherValueLost: 0,
});

export const voucherSlice = createSlice({
  name: "voucher",
  initialState,
  reducers: {
    checkVoucher: (
      state,
      action: PayloadAction<{
        giftVoucherCode: string;
        bookingId: string;
      }>,
    ) => {
      return {
        ...initialState,
        currentVoucherCode: action.payload.giftVoucherCode,
        loading: true,
        vouchers: state.vouchers,
        totalVoucherDiscount: state.totalVoucherDiscount,
        totalVoucherValueLost: state.totalVoucherValueLost,
      };
    },
    checkVoucherSuccess: (
      state,
      action: PayloadAction<GiftVoucherIsValidResponse>,
    ) => {
      state.data = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    checkVoucherFail: (state, action: PayloadAction<string>) => {
      return {
        ...initialState,
        hasError: true,
        errorMessage: action.payload,
        vouchers: state.vouchers,
        totalVoucherDiscount: state.totalVoucherDiscount,
        totalVoucherValueLost: state.totalVoucherValueLost,
      };
    },
    addVoucher: (state, action: PayloadAction<Voucher>) => {
      return {
        ...state,
        vouchers: [...state.vouchers, action.payload],
      };
    },
    removeVoucher: (
      state,
      action: PayloadAction<{
        voucherCode: MutationRemoveGiftVoucherFromBookingArgs;
        bookingId: string;
      }>,
    ) => {
      state.voucherToRemove = action.payload.voucherCode.giftVoucherCode;
    },
    removeVoucherSuccess: (
      state,
      action: PayloadAction<{ bookingId: string }>,
    ) => {
      const vouchers = state.vouchers.filter(
        (voucher) => voucher.code !== state.voucherToRemove,
      );
      return {
        ...state,
        vouchers,
        totalVoucherDiscount: state.totalVoucherDiscount,
        totalVoucherValueLost: state.totalVoucherValueLost,
        voucherToRemove: null,
      };
    },
    removeVouchersFromCurrentBooking: (state) => {
      state.vouchers = [];
      state.data = null;
      state.totalVoucherDiscount = 0;
      state.totalVoucherValueLost = 0;
      state.currentVoucherCode = "";
    },
    setTotalVoucherDiscount: (state, action: PayloadAction<number>) => {
      state.totalVoucherDiscount = action.payload;
    },
    setTotalVoucherValueLost: (state, action: PayloadAction<number>) => {
      state.totalVoucherValueLost = action.payload;
    },
  },
});

export const {
  addVoucher,
  checkVoucher,
  checkVoucherFail,
  checkVoucherSuccess,
  removeVoucher,
  removeVoucherSuccess,
  removeVouchersFromCurrentBooking,
  setTotalVoucherDiscount,
  setTotalVoucherValueLost,
} = voucherSlice.actions;

export default voucherSlice.reducer;
