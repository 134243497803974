import { createSlice } from "@reduxjs/toolkit";
import {
  CabinTypeContent,
  CabinTypesAtLocationResponse,
  QueryCabinTypesAtLocationArgs,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type CabinTypesAtLocationState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<CabinTypeContent[]>;
};

const initialState: CabinTypesAtLocationState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const cabinsTypeLocationSlice = createSlice({
  name: "cabinsSearch",
  initialState,
  reducers: {
    getCabinTypesAtLocation: (
      state,
      action: PayloadAction<QueryCabinTypesAtLocationArgs>
    ) => {
      state.loading = true;
      state.hasError = false;
      state.loaded = false;
      state.errorMessage = null;
      state.data = null;
    },
    getCabinTypesAtLocationSuccess: (
      state,
      action: PayloadAction<CabinTypesAtLocationResponse>
    ) => {
      state.data = action.payload.cabinTypes as CabinTypeContent[];
      state.loaded = true;
      state.loading = false;
      state.hasError = false;
      state.errorMessage = null;
    },
    getCabinTypesAtLocationFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loaded = false;
      state.loading = false;
      state.data = null;
    },
  },
});

export const {
  getCabinTypesAtLocation,
  getCabinTypesAtLocationSuccess,
  getCabinTypesAtLocationFail,
} = cabinsTypeLocationSlice.actions;

export default cabinsTypeLocationSlice.reducer;
