import { createSlice } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import { MutationMakeCardPaymentArgs, PaymentResponse } from "@generated/types";
import type { PayloadAction } from "@reduxjs/toolkit";

export type CreatePaymentState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<PaymentResponse>;
};

const initialState: CreatePaymentState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const createPaymentSlice = createSlice({
  name: "createPayment",
  initialState,
  reducers: {
    createCardPayment: (
      state,
      action: PayloadAction<{
        paymentInputRequest: MutationMakeCardPaymentArgs;
        isBookingJourney: boolean;
      }>
    ) => {
      state.loading = true;
      state.data = null;
      state.errorMessage = null;
      state.loaded = false;
      state.hasError = false;
    },
    createCardPaymentSuccess: (
      state,
      action: PayloadAction<{
        paymentResponse: PaymentResponse;
        isBookingJourney: boolean;
      }>
    ) => {
      state.data = action.payload.paymentResponse;
      state.loaded = true;
      state.loading = false;
      state.errorMessage = null;
      state.hasError = false;
    },
    createCardPaymentFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loaded = false;
      state.loading = false;
      state.data = null;
    },
    createCardPaymentReset: (state) => ({
      ...initialState,
      data: null,
    }),
  },
});

export const {
  createCardPayment,
  createCardPaymentFail,
  createCardPaymentReset,
  createCardPaymentSuccess,
} = createPaymentSlice.actions;

export default createPaymentSlice.reducer;
