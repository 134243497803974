import { createSlice } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import {
  Breadcrumb_S as Breadcrumb,
  BreadcrumbsResponse,
  QueryBreadcrumbsArgs,
} from "@generated/types";
import type { PayloadAction } from "@reduxjs/toolkit";
export type BreadcrumbState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  getBreadcrumbRequest?: string;
  breadcrumb: Breadcrumb[];
};

const initialState: BreadcrumbState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  breadcrumb: [],
});

export const breadcrumbSlice = createSlice({
  name: "breadCrumb",
  initialState,
  reducers: {
    getBreadcrumbAction: (
      state,
      action: PayloadAction<QueryBreadcrumbsArgs>
    ) => {
      state.loading = true;
    },
    getBreadcrumbSuccessAction: (
      state,
      action: PayloadAction<BreadcrumbsResponse>
    ) => {
      state.breadcrumb = action.payload.breadcrumbs
        ?.breadcrumbPages as Breadcrumb[];
      state.loaded = true;
      state.loading = false;
    },
    getBreadcrumbFailAction: (
      state,
      action: PayloadAction<BreadcrumbState["errorMessage"]>
    ) => {
      state.hasError = true;
      state.loaded = true;
      state.errorMessage =
        action?.payload || "An error occurred. Please try again.";
      state.loading = false;
      state.breadcrumb = [];
    },
  },
});

export const {
  getBreadcrumbAction,
  getBreadcrumbSuccessAction,
  getBreadcrumbFailAction,
} = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;
