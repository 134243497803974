import { createSlice } from "@reduxjs/toolkit";
import {
  BookingSummary_S as BookingSummary,
  CabinReservationBookingInput,
  QueryRefreshBookingSummaryArgs,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type AddCabinState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<BookingSummary>;
};

const initialState: AddCabinState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const cabinAddSlice = createSlice({
  name: "cabinAdd",
  initialState,
  reducers: {
    addCabin: (state, action: PayloadAction<CabinReservationBookingInput>) => {
      state.hasError = false;
      state.loaded = false;
      state.errorMessage = null;
      state.loading = true;
      state.data = null;
    },
    addCabinSuccess: (state, action: PayloadAction<BookingSummary>) => {
      state.hasError = false;
      state.loaded = true;
      state.errorMessage = null;
      state.loading = false;
      state.data = action.payload;
    },
    addCabinFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.loaded = false;
      state.errorMessage = action.payload;
      state.loading = false;
      state.data = null;
    },
    getRefreshBookingSummaryAction: (
      state,
      action: PayloadAction<QueryRefreshBookingSummaryArgs>
    ) => {
      state.hasError = false;
      state.loaded = false;
      state.errorMessage = null;
      state.loading = true;
      state.data = null;
    },
    getRefreshBookingSummarySuccessAction: (
      state,
      action: PayloadAction<BookingSummary>
    ) => {
      state.hasError = false;
      state.loaded = true;
      state.errorMessage = null;
      state.loading = false;
      state.data = action.payload;
    },
    getRefreshBookingSummaryFailAction: (
      state,
      action: PayloadAction<string>
    ) => {
      state.hasError = true;
      state.loaded = false;
      state.errorMessage = action.payload;
      state.loading = false;
      state.data = null;
    },
  },
});

export const {
  addCabin,
  addCabinSuccess,
  addCabinFail,
  getRefreshBookingSummaryAction,
  getRefreshBookingSummarySuccessAction,
  getRefreshBookingSummaryFailAction,
} = cabinAddSlice.actions;

export default cabinAddSlice.reducer;
