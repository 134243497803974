import { createSlice } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import { RemoveGiftVoucherFromBasketResponseUnion } from "@generated/types";
import type { PayloadAction } from "@reduxjs/toolkit";

export type GiftVoucherRemoveState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<RemoveGiftVoucherFromBasketResponseUnion>;
};

const initialState: GiftVoucherRemoveState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const giftVoucherRemoveSlice = createSlice({
  name: "giftVoucherRemove",
  initialState,
  reducers: {
    removeGiftVoucher: (state, action: PayloadAction<number>) => {
      state.loading = true;
      state.errorMessage = null;
      state.hasError = false;
      state.loaded = false;
      state.data = null;
    },
    removeGiftVoucherSuccess: (
      state,
      action: PayloadAction<RemoveGiftVoucherFromBasketResponseUnion>
    ) => {
      state.data = action.payload;
      state.loaded = true;
      state.loading = false;
      state.errorMessage = null;
      state.hasError = false;
    },
    removeGiftVoucherFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loading = false;
      state.loaded = false;
      state.data = null;
    },
  },
});
export const {
  removeGiftVoucher,
  removeGiftVoucherFail,
  removeGiftVoucherSuccess,
} = giftVoucherRemoveSlice.actions;

export default giftVoucherRemoveSlice.reducer;
