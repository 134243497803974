import { createSlice } from "@reduxjs/toolkit";
import { RemoveExtraFromBasketResponse } from "../../graphql/bsl/gql-custom/types";
import { MutationRemoveExtraFromBasketArgs } from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type RemoveExtraState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<RemoveExtraFromBasketResponse>;
  removeExtraRequest?: MutationRemoveExtraFromBasketArgs;
  isPostBookingJourney?: boolean;
  removedExtraId: Nullable<string>;
};

const initialState: RemoveExtraState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
  isPostBookingJourney: false,
  removedExtraId: null,
});

export const extraRemoveSlice = createSlice({
  name: "extraRemove",
  initialState,
  reducers: {
    removeExtra: (
      state,
      action: PayloadAction<{
        mutationRemoveExtraFromBasketArgs: MutationRemoveExtraFromBasketArgs;
        isPostBookingJourney: boolean | null;
      }>
    ) => {
      state.removeExtraRequest =
        action.payload.mutationRemoveExtraFromBasketArgs;
      state.loading = true;
      state.loaded = false;
      state.errorMessage = null;
      state.hasError = false;
      state.isPostBookingJourney = false;
      state.removedExtraId = null;
    },
    setRemovedExtraId: (state, action: PayloadAction<string>) => {
      state.removedExtraId = action.payload;
      state.loading = false;
      state.loaded = false;
      state.errorMessage = null;
      state.hasError = false;
      state.isPostBookingJourney = false;
    },
    removeExtraSuccess: (
      state,
      action: PayloadAction<{
        removeExtraFromBasketResponse: RemoveExtraFromBasketResponse;
        isPostBookingJourney: boolean;
      }>
    ) => ({
      ...initialState,
      removeExtraRequest: state.removeExtraRequest,
      removedExtraId: state.removedExtraId,
      data: action.payload.removeExtraFromBasketResponse,
      loaded: true,
      isPostBookingJourney: action.payload.isPostBookingJourney,
    }),
    removeExtraFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.data = null;
      state.loaded = false;
      state.loading = false;
      state.isPostBookingJourney = false;
    },
  },
});

export const {
  removeExtra,
  removeExtraSuccess,
  removeExtraFail,
  setRemovedExtraId,
} = extraRemoveSlice.actions;

export default extraRemoveSlice.reducer;
