export type BookingConfirmation = {
  balance: number;
  balanceDueDate: Date;
  bookingCountdownDays: number;
  bookingId: string;
  bookingReference: number;
  depositAmount: number;
  cabinReservations: [CabinReservation];
  confirmationEmailStatus: "PENDING" | "SENT";
  totalPayments: number;
  totalPrice: number;
};

type Extra = BaseExtra & {
  extraOptions?: [ExtraOption];
};

export type ReservationExtra = BaseExtra & {
  reservationExtraOptions?: [ReservationExtraOption];
};

type BaseExtra = {
  bookableOnArrivalDay?: boolean;
  bookableOnDepartureDay?: boolean;
  extraCategory?: string;
  extraCode?: string;
  extraDateFri?: boolean;
  extraDateMon?: boolean;
  extraDateSat?: boolean;
  extraDateSun?: boolean;
  extraDateThurs?: boolean;
  extraDateTues?: boolean;
  extraDateWed?: boolean;
  extraDetails?: string;
  extraId: string;
  extraName?: string;
  hasAMPM?: boolean;
  hasCalendar?: boolean;
  isRefundable?: boolean;
  isThirdPartyExtra?: boolean;
  maximumQuantity?: number;
  nonRefundableWithinDays?: number;
  offLocation?: boolean;
  onLocation?: boolean;
  paymentInFull?: boolean;
  purchaseCutoffDays?: number;
  sortOrder?: number;
  tagIds?: [string];
  thumbnailFilename?: string;
  unitName?: string;
  unitNamePlural?: string;
};

type ExtraOption = {
  chargeType?: string;
  currentStockLevel?: number;
  extraId?: string;
  extraOptionId?: string;
  extraOptionName?: string;
  extraOptionPrice?: number;
  isAvailable?: boolean;
  isBundle: boolean;
  isCompletingBundle: boolean;
  isECI?: boolean;
  isEntertainment?: boolean;
  isIncludedInReservation?: boolean;
  isLCO?: boolean;
  isPet: boolean;
  priceCheck: string;
  priceExpiry?: Date;
  saving: number;
  sortOrder?: number;
  stockControlled?: boolean;
  stockItemsPerBooking?: number;
};

type ReservationExtraOption = {
  aMPM?: string;
  cabinReservationExtraId: string;
  calendarDate?: Date;
  chargeType?: string;
  currentStockLevel?: number;
  extraId?: string;
  extraOptionId?: string;
  extraOptionName?: string;
  extraOptionPrice?: number;
  extraSelectedDate?: string;
  extraStatus: string;
  isAvailable: boolean;
  isECI?: boolean;
  isEntertainment?: boolean;
  isIncludedInReservation?: boolean;
  isLCO?: boolean;
  isPet: boolean;
  price: number;
  priceCheck: string;
  priceExpiry: Date;
  quantity?: number;
  sellingPrice?: number;
  sortOrder?: number;
  stockControlled?: boolean;
  stockItemsPerBooking?: number;
  totalPrice?: number;
};

export type CabinReservation = {
  availableExtras: [Extra];
  bookingId: string;
  cabinNumber: number;
  cabinReservationId: string;
  cabinTypeName: string;
  customerId: string;
  dateCreated: Date;
  dateModified: Date;
  disabledAccess: boolean;
  discountCode: string;
  discountPrice: number;
  discountTitle: string;
  essentialExtras: [Extra];
  locationId: string;
  locationName: string;
  noOfAdults: number;
  noOfBedrooms: number;
  noOfChildren: number;
  noOfInfants: number;
  noOfNights: number;
  noOfPetsBooked: number;
  petFriendly: boolean;
  promoCodeApplied: string;
  reservationDateBooked: Date;
  reservationEndDate: string;
  reservationExtras: [ReservationExtra];
  reservationProcessStage: string;
  reservationStartDate: string;
  sellingPrice: number;
  success: boolean;
  cabinTypeFeatures?: CabinTypeFeatures[];
  cabinTypeImages?: CabinTypeImages[];
  preDiscountCabinPrice: number;
};

export type CabinTypeFeatures = {
  label: string;
  url: string;
};

export type CabinTypeImages = {
  url: string;
  description: string;
};

export type UpcomingBookingsResponse = {
  cabins: UpcomingBookingCabin[];
  upcomingBookingSummary: UpcomingBookingSummary;
};

export type UpcomingBookingSummary = {
  balanceDue: number;
  balanceDueDate: string;
  balanceDueDays: number;
  bookingId: string;
  bookingReference: string;
  endDate: string;
  numberOfBookings: number;
  numberOfDaysRemaining: number;
  startDate: string;
};

export type UpcomingBookingCabin = {
  cabinReservationId: string;
  cabinTypeName: string;
  disabledAccess: boolean;
  locationName: string;
  numberOfBedrooms: number;
  petFriendly: boolean;
};

export type GA4TransactionProduct = {
  item_id: string;
  item_name: string;
  item_category: string;
  price: number;
  quantity: number;
  coupon?: string;
  discount?: number;
  index: number;
  item_category2?: string;
  item_list_id?: string;
  item_list_name?: string;
};

export type GA4Transaction = {
  transaction_id: number;
  value: number;
  currency: string;
  coupon: string;
  items: GA4TransactionProduct[];
};

export enum StepperTag { // booking journey stepper tag names
  locations = "locations",
  cabins = "cabins",
  yourbooking = "yourbooking",
  extras = "extras",
  login = "login",
  payment = "payment",
  confirm = "confirm",
}

export type BookingJourneyStep = {
  label: string;
  url?: string;
  tag?: string;
  completed?: boolean;
};

export type ProgressTrackerTypes = {
  title: string;
  url: string;
  tag: string;
  skipStepCTALabel?: string;
  skipStepMessage?: string;
  __typename?: "ProgressTrackerStep";
};

export type CurrentCabin = {
  bookingId: string;
  cabinReservationId: string;
};
