import { Customer } from "@generated/types";

export enum FHEventType {
  pageView = "pageView",
  userSession = "userSession",
  bookingSearch = "bookingSearch",
  bookingTransaction = "bookingTransaction",
  login = "login",
  register = "registration",
  click = "click",
  scroll = "scroll",
  ancillaryTransaction = "ancillaryTransaction",
  anonSession = "anonSession",
  anonPageView = "anonPageView",
  anonSearch = "anonSearch",
  anonBookingTransaction = "anonBookingTransaction",
  anonAncillaryTransaction = "anonAncillaryTransaction",
}

export enum FHComplianceAction {
  acceptTracking = "acceptTracking",
  declineTracking = "declineTracking",
  unspecifiedTracking = "unspecifiedTracking",
}

export type SSTBookingSearchDetails = {
  bookingFunnelLocationIds: string[];
  bookingSearchLocationIds: string[];
  duration: string;
  startDate: string;
  adults: string;
  children: string;
  infants: string;
  pets: string;
  dda: string;
  bedrooms: string;
  cabinTypeId: string;
  noOfBedsAttemptedToBook: string;
};

export type SSTBookingFunnelDetails = {
  locationIds: string[];
  duration: string;
  startDate: string;
};

export type SSTSessionStorageProps = {
  sessionId: string;
  sessionExpiry: string;
  pageViewId: string;
  previousPageViewId: string;
  pageOrderNumber: string;
  bookingFunnelId: string;
  bookingFunnelOrderNumber: string;
  bookingFunnelSearchId: string;
  bookingFunnelSearchOrderNumber: string;
  bookingSearchDetails: SSTBookingSearchDetails;
};

export type SSTEvent = {
  eventType: FHEventType;
  sessionId?: string;
  url?: string;
  referrer?: string;
  trackingId?: number;
  pageViewId?: string;
  publicKey?: string;
  complianceAction?: FHComplianceAction;
};

export type SSTComplianceAction = {
  complianceAction: FHComplianceAction;
  publicKey?: string;
  privateKey?: string;
};

export interface SSTPageView extends SSTEvent {
  bookingFunnelId: string;
  pageOrderNumber: string;
  pageURLQueryString?: string;
}
export interface SessionEventData extends SSTEvent {
  eventId: string;
}

export interface SSTUserSession extends SSTEvent {
  userId: string;
  gad?: boolean | string;
  gclid?: string;
  pageURLQueryString?: string;
  meta?: any;
}

export interface SSTAnonSearch extends SSTEvent {
  bookingFunnelSearchId: string;
  locationIds: string[];
  checkInDate: string;
  checkOutDate: string;
  duration: number;
  adults: number;
  children: number;
  infants: number;
  bedrooms: number;
  pets: number;
  dda: boolean;
}

export interface SSTSearch extends SSTAnonSearch {
  bookingFunnelId: string;
  bookingFunnelOrderNumber: number;
  bookingFunnelSearchOrderNumber: number;
  cabinTypeId: string;
  noOfBedsAttemptedToBook: string;
}

export interface SSTCabin {
  cabinReservationId: string;
  locationId: string;
  checkInDate: string;
  checkOutDate: string;
  cabinTypeId: string;
  bedrooms: number;
  pets: boolean;
  dda: boolean;
  discountCode: string;
  cabinRevenueGrossPreDiscount: number;
  cabinRevenueGrossDiscount: number;
  cabinRevenueGrossSelling: number;
}

export interface SSTExtraOption {
  cabinReservationId: string;
  cabinReservationExtraID: string;
  extraId: string;
  extraOptionId: string;
  quantity: number;
  discountCode: string;
  optionDate: string;
  optionTime: string;
  ancillaryRevenueGrossPreDiscount: number;
  ancillaryRevenueGrossDiscount: number;
  ancillaryRevenueGrossSelling: number;
}

export interface SSTAnonBookingTransaction extends SSTEvent {
  bookingReference: string;
  cabins: SSTCabin[] | string;
  extras: SSTExtraOption[] | string;
}

export interface SSTBookingTransaction extends SSTAnonBookingTransaction {
  bookingFunnelId: string;
  bookingFunnelSearchId: string;
}

export interface SSTAncillaryTransaction extends SSTEvent {
  bookingReference: string;
  locationId: string;
  checkInDate: string;
  checkOutDate: string;
  cabinTypeId: string;
  bedrooms: number;
  pets: boolean;
  dda: boolean;
  extras: SSTExtraOption[] | string;
}

export interface SSTLoginRegister extends SSTEvent {
  userId: string;
  customerId: string;
}

export type User = {
  user_status: string;
  cust_ref: string;
  booked_stays: number;
  departed_stays: number;
  days_to_next_stay: number;
  days_since_last_stay: number;
  _clear?: boolean;
};

export type NextApiRequestWithSST = {
  url: string;
  referrer?: string;
  userData?: Customer;
  headers?: any;
};
