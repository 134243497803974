import { createSlice } from "@reduxjs/toolkit";
import { CabinInfo, QueryCabinInfoFromCabinIdArgs } from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type CabinInfoState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<CabinInfo>;
};

const initialState: CabinInfoState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const cabinInfoSlice = createSlice({
  name: "cabinInfo",
  initialState,
  reducers: {
    getCabinInfo: (
      state,
      action: PayloadAction<QueryCabinInfoFromCabinIdArgs>
    ) => {
      state.hasError = false;
      state.loaded = false;
      state.errorMessage = null;
      state.loading = true;
      state.data = null;
    },
    getCabinInfoSuccess: (state, action: PayloadAction<CabinInfo>) => {
      state.hasError = false;
      state.loaded = true;
      state.errorMessage = null;
      state.loading = false;
      state.data = action.payload;
    },
    getCabinInfoFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.loaded = false;
      state.errorMessage = action.payload;
      state.loading = false;
      state.data = null;
    },
    resetCabinIfo: (state) => {
      state.hasError = false;
      state.loaded = false;
      state.errorMessage = null;
      state.loading = false;
      state.data = null;
    },
  },
});

export const {
  getCabinInfo,
  getCabinInfoSuccess,
  getCabinInfoFail,
  resetCabinIfo,
} = cabinInfoSlice.actions;

export default cabinInfoSlice.reducer;
