import { RootState } from "../slices";
import { useSelector } from "react-redux";

export const getPaymentCardssStateSelector = (state: RootState) =>
  state.paymentCards;
export const addCardStateSelector = (state: RootState) => state.addCard;
export const getRemoveCardsStateSelector = (state: RootState) =>
  state.removedCards;
export const getScheduledPaymentsStateSelector = (state: RootState) =>
  state.scheduledPayments;

export const changeCardStateSelector = (state: RootState) => state.changeCard;

export const useGetPaymentCardssStateSelector = () =>
  useSelector(getPaymentCardssStateSelector);

export const useAddCardStateSelector = () => useSelector(addCardStateSelector);

export const useGetRemoveCardssStateSelector = () =>
  useSelector(getRemoveCardsStateSelector);

export const useGetScheduledPaymentsStateSelector = () =>
  useSelector(getScheduledPaymentsStateSelector);

export const useChangeCardStateSelector = () =>
  useSelector(changeCardStateSelector);
