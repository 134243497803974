import { createSlice } from "@reduxjs/toolkit";
import { ReservationExtra } from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type ExtrasPurchasedState = {
  purchasedExtras: Nullable<ReservationExtra[]>;
  purchasedExtrasTotalPrice: number;
  vouchersTotalPrice: number;
};

const initialState: ExtrasPurchasedState = Object.freeze({
  purchasedExtras: null,
  purchasedExtrasTotalPrice: 0,
  vouchersTotalPrice: 0,
});

export const extraPurchasedSlice = createSlice({
  name: "extraPurchased",
  initialState,
  reducers: {
    setExtras: (
      state,
      action: PayloadAction<{
        purchasedExtras: Nullable<ReservationExtra[]>;
        purchasedExtrasTotalPrice: number;
        vouchersTotalPrice: number;
      }>
    ) => {
      state.purchasedExtras = action.payload.purchasedExtras;
      state.purchasedExtrasTotalPrice =
        action.payload.purchasedExtrasTotalPrice;
      state.vouchersTotalPrice = action.payload.vouchersTotalPrice;
    },
    resetExtras: (state) => ({
      ...initialState,
    }),
  },
});

export const { setExtras, resetExtras } = extraPurchasedSlice.actions;

export default extraPurchasedSlice.reducer;
