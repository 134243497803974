import moment from "moment";
import { NextPayment } from "../../components/SemanticTheme/ManageScheduledPayments";
import {
  ScheduledPayment as ScheduledPaymentUIKit,
  ScheduledPayments,
} from "../../components/SemanticTheme/ScheduledPayments";
import { ScheduledPaymentStatus } from "../../constants/paymentForm";
import {
  BookingsWithScheduledPaymentsResponse,
  BookingWithScheduledPayments,
  QueryBookingsWithScheduledPaymentsArgs,
  ScheduledPayment,
} from "@generated/types";
import { formatDate, Nullable } from "../../utils";
import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type ScheduledPaymentsByBooking = {
  bookingId: string;
  upcomingScheduledPayments: ScheduledPayments;
  completedScheduledPayments: ScheduledPayments;
  failedScheduledPayments: ScheduledPayments;
  upcomingAndFailedScheduledPayments: ScheduledPayments;
  lastPaymentFailed?: ScheduledPayment;
  nextPayment: Nullable<NextPayment>;
  pendingPaymentToday: boolean;
};

export type ScheduledPaymentsByBookingState = {
  loading: boolean;
  loaded: boolean;
  hasError: boolean;
  data: Nullable<BookingsWithScheduledPaymentsResponse>;
  errorMessage: Nullable<string>;
  scheduledPaymentsByBooking: ScheduledPaymentsByBooking[];
};

const initialState: ScheduledPaymentsByBookingState = Object.freeze({
  loading: false,
  loaded: false,
  data: null,
  errorMessage: null,
  hasError: false,
  scheduledPaymentsByBooking: [],
});

export const scheduledPaymentsByBookingSlice = createSlice({
  name: "scheduledPaymentsByBooking",
  initialState,
  reducers: {
    getScheduledPaymentsByBookingAction: (
      state,
      action: PayloadAction<QueryBookingsWithScheduledPaymentsArgs>
    ) => {
      state.loading = true;
      state.data = null;
      state.errorMessage = null;
      state.loaded = false;
      state.scheduledPaymentsByBooking = [];
      state.hasError = false;
    },
    getScheduledPaymentsByBookingSuccessAction: (
      state,
      action: PayloadAction<BookingsWithScheduledPaymentsResponse>
    ) => {
      const bookings =
        action?.payload?.bookings || ([] as BookingWithScheduledPayments[]);
      const scheduledPaymentsByBooking: ScheduledPaymentsByBooking[] = [];
      if (bookings.length > 0) {
        bookings.map((booking) => {
          if (!!booking) {
            const payments: ScheduledPayment[] = booking.payments || [];
            scheduledPaymentsByBooking.push({
              bookingId: booking.bookingId,
              nextPayment: getNextPayment(payments),
              upcomingScheduledPayments: getFilteredScheduledPaymentsForBooking(
                payments,
                ScheduledPaymentStatus.upcoming
              ),
              completedScheduledPayments:
                getFilteredScheduledPaymentsForBooking(
                  payments,
                  ScheduledPaymentStatus.complete
                ),
              failedScheduledPayments: getFilteredScheduledPaymentsForBooking(
                payments,
                ScheduledPaymentStatus.failed
              ),
              upcomingAndFailedScheduledPayments:
                getFilteredScheduledPaymentsForBooking(
                  payments,
                  ScheduledPaymentStatus.upcoming,
                  ScheduledPaymentStatus.failed
                ),
              lastPaymentFailed: booking.lastFailedPayment,
              pendingPaymentToday: booking.pendingPaymentToday,
            } as ScheduledPaymentsByBooking);
          }
        });
      }

      return {
        ...initialState,
        data: action.payload,
        loaded: true,
        scheduledPaymentsByBooking: scheduledPaymentsByBooking,
      };
    },
    getScheduledPaymentsByBookingFailAction: (
      state,
      action: PayloadAction<string>
    ) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.data = null;
      state.loaded = false;
      state.loading = false;
      state.scheduledPaymentsByBooking = [];
    },
  },
});

const calculateTotalValue = (payments: ScheduledPayment[]): number => {
  return payments.reduce((total, payment) => {
    return total + payment.paymentValue;
  }, 0);
};

const getScheduledPayments = (
  payments: ScheduledPayment[],
  hideStatus: boolean
): ScheduledPaymentUIKit[] => {
  const scheduledPayments: ScheduledPaymentUIKit[] = payments.map(
    (payment) =>
      ({
        date: formatDate(payment.dateDue),
        value: payment.paymentValue,
        status: hideStatus ? undefined : payment.status,
        takenOnDate: payment.paymentTakenDate
          ? formatDate(payment.paymentTakenDate)
          : undefined,
        paymentType: payment.paymentType,
      } as ScheduledPaymentUIKit)
  );
  return scheduledPayments;
};

export const getFilteredScheduledPaymentsForBooking = (
  scheduledPayments: ScheduledPayment[],
  scheduledPaymentStatus?: ScheduledPaymentStatus,
  secondScheduledPaymentStatus?: ScheduledPaymentStatus
): ScheduledPayments => {
  let updatedScheduledPayments = scheduledPayments;
  if (!!scheduledPaymentStatus) {
    updatedScheduledPayments =
      scheduledPayments?.filter(
        (payment) => payment.status === scheduledPaymentStatus
      ) || [];
  }
  if (!!secondScheduledPaymentStatus) {
    const secondFilteredList =
      scheduledPayments?.filter(
        (payment) => payment.status === secondScheduledPaymentStatus
      ) || [];
    updatedScheduledPayments = secondFilteredList.concat(
      updatedScheduledPayments
    );
  }

  return {
    totalValue: calculateTotalValue(updatedScheduledPayments),
    items: getScheduledPayments(updatedScheduledPayments, false),
  };
};

const getNextPayment = (payments: ScheduledPayment[]): NextPayment | null => {
  const todaysDate = moment().utc();

  // return first payment that is after todays date and is upcoming
  const nextUpcomingPayment = payments.find(
    (payment) =>
      moment(payment.dateDue).utc() > todaysDate &&
      payment.status === ScheduledPaymentStatus.upcoming
  );

  if (!!nextUpcomingPayment) {
    const nextPayment: NextPayment = {
      cardTokenPaymentId: nextUpcomingPayment.cardTokenPaymentId,
      cardTokenId: nextUpcomingPayment.cardTokenId.toString(),
      default: nextUpcomingPayment.defaultCard,
      expiryDate: moment(nextUpcomingPayment.expiryDate).utc().format("MM/YY"),
      paymentValue: nextUpcomingPayment.paymentValue,
      cardType: nextUpcomingPayment.cardType as string,
      last4Digits: nextUpcomingPayment.last4Digits as string,
      cardImage: nextUpcomingPayment.tokenisedCard
        ?.cardTypeSmallImage as string,
      dateDue: nextUpcomingPayment.dateDue as string,
    };
    return nextPayment;
  }
  return null;
};

export const {
  getScheduledPaymentsByBookingAction,
  getScheduledPaymentsByBookingFailAction,
  getScheduledPaymentsByBookingSuccessAction,
} = scheduledPaymentsByBookingSlice.actions;

export default scheduledPaymentsByBookingSlice.reducer;
