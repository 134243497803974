import { createSlice } from "@reduxjs/toolkit";
import { Customer, CustomerRegistrationRequestInput } from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type RegisterState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<Customer>;
};

const initialState: RegisterState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loading: false,
  loaded: false,
  data: null,
});

export const registerSlice = createSlice({
  name: "register",
  initialState,
  reducers: {
    createRegister: (
      state,
      action: PayloadAction<CustomerRegistrationRequestInput>
    ) => {
      state.loading = true;
      state.errorMessage = null;
      state.hasError = false;
      state.loaded = false;
      state.data = null;
    },
    createRegisterSuccess: (state, action: PayloadAction<Customer>) => {
      state.data = action.payload;
      state.loaded = true;
      state.loading = false;
      state.errorMessage = null;
      state.hasError = false;
    },
    createRegisterFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loading = false;
      state.loaded = false;
      state.data = null;
    },
  },
});

export const { createRegister, createRegisterSuccess, createRegisterFail } =
  registerSlice.actions;

export default registerSlice.reducer;
