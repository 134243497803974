import { spawn, all, takeLatest, put, call } from "redux-saga/effects";
import { siteSettingsSagas } from "./site-settings.sagas";
import bookingSagas from "./booking.sagas";
import cabinsSagas from "./cabins";
import extrasSagas from "./extras.sagas";
import futureBookingSagas from "./future-bookings.sagas";
import locationSagas from "./location.sagas";
import stickySagas from "./sticky.sagas";
import accountSagas from "./account.sagas";
import breadcrumbSagas from "./breadcrumb.sagas";
import bookingcustomerSagas from "./booking-customer.sagas";
import locationMapSagas from "./location-map.sagas";
import errorMessagesSagas from "./error-messages.sagas";
import datesSagas from "./date.saga";
import promoCodeSagas from "./promo-code.sagas";
import paymentSagas from "./payment.sagas";
import { faqSagas } from "./faq.sagas";
import gvSettingsSagas from "./gv-settings.sagas";
import { blogSagas } from "./blog.sagas";
import postCodeSagas from "./post-code.sagas";
import { authSagas } from "./auth.sagas";
import bookingDataForGaSagas from "./booking-data-for-ga.sagas";
import { getSiteSettingsAction } from "../slices/site-settings.slice";
import { getErrorMessages } from "../slices/error-messages.slice";
import TagManager from "react-gtm-module";
import { isBrowser } from "../../components/Utils";
import {
  getBreadcrumbAction,
  getBreadcrumbFailAction,
} from "../slices/breadcrumb.slice";
import gvCustomerSagas from "./gv-customer.sagas";
import changePasswordSagas from "./change-password.sagas";
import updateMyDetailsSaga from "./update-mydetails.sagas";
import { featuredExtraSagas } from "./featured-extras.sagas";
import scheduledPaymentsByBookingSagas from "./scheduled-payments-by-booking.sagas";
import scheduledPaymentsByBookingIdSagas from "./scheduled-payments-by-booking-id.sagas";
import ManagePaymentsSagas from "./manage-payments.sagas";
import paymentCardsSagas from "./payment-cards.sagas";
import { abandonedBasketSagas } from "./abandoned-basket.saga";
import attendeeSagas from "./attendee.sagas";
import vehicleSagas from "./vehicle.sagas";
import RemoveCardsSagas from "./remove-cards.sagas";
import extraPaymentSagas from "./extra-payment.sagas";
import { confirmFreeExtrasSagas } from "./confirm-freeextras.sagas";
import { voucherSagas } from "./voucher.sagas";
import { addTrailingSlashToUrl } from "src/utils";
import calendarSummarySagas from "./calendar-summary.saga";

// eslint-disable-next-line require-yield
function* initGoogleTagManagerSaga() {
  if (isBrowser()) {
    const gtmId =
      typeof $cmsSettings !== "undefined"
        ? $cmsSettings?.gtmId
        : process.env.NEXT_PUBLIC_GTM_ID;

    if (!gtmId) return;

    TagManager.initialize({
      gtmId,
    });
  }
}

function* initSaga() {
  yield put(getSiteSettingsAction());
  yield put(getErrorMessages());
  yield call(initGoogleTagManagerSaga);
}

function* windowLocationUpdatedSaga() {
  if (isBrowser()) {
    const pathname = window.location.pathname.replace(/\//, "");
    let breadcrumbUrl =
      process.env.NEXT_PUBLIC_STRAPI_BASE_URL.concat(pathname);

    breadcrumbUrl = findAndRemoveSpecialUriSegmentsFromPath(
      breadcrumbUrl || ""
    );

    if (!!breadcrumbUrl) {
      yield put(
        getBreadcrumbAction({
          url: addTrailingSlashToUrl(breadcrumbUrl),
        })
      );
    } else {
      yield put(
        getBreadcrumbFailAction(
          "Failed to get breadcrumb due to missing base url."
        )
      );
    }
  }
}

const findAndRemoveSpecialUriSegmentsFromPath = (breadcrumbUrl: string) => {
  let finalUrl = breadcrumbUrl;
  const splitWithFirstCombination = breadcrumbUrl.split("#:~:text=");
  if (splitWithFirstCombination.length >= 2)
    finalUrl = splitWithFirstCombination[0];
  else {
    const splitWithSecondCombination = finalUrl.split("#.");
    if (splitWithSecondCombination.length >= 2)
      finalUrl = splitWithSecondCombination[0];
  }
  return finalUrl;
};

export default function* rootSaga() {
  yield spawn(siteSettingsSagas);
  yield spawn(bookingSagas);
  yield spawn(cabinsSagas);
  yield spawn(confirmFreeExtrasSagas);
  yield spawn(extrasSagas);
  yield spawn(futureBookingSagas);
  yield spawn(locationSagas);
  yield spawn(stickySagas);
  yield spawn(accountSagas);
  yield spawn(breadcrumbSagas);
  yield spawn(bookingcustomerSagas);
  yield spawn(locationMapSagas);
  yield spawn(errorMessagesSagas);
  yield spawn(datesSagas);
  yield spawn(promoCodeSagas);
  yield spawn(paymentSagas);
  yield spawn(faqSagas);
  yield spawn(gvSettingsSagas);
  yield spawn(blogSagas);
  yield spawn(postCodeSagas);
  yield spawn(authSagas);
  yield spawn(bookingDataForGaSagas);
  yield spawn(gvCustomerSagas);
  yield spawn(changePasswordSagas);
  yield spawn(updateMyDetailsSaga);
  yield spawn(featuredExtraSagas);
  yield spawn(scheduledPaymentsByBookingSagas);
  yield spawn(scheduledPaymentsByBookingIdSagas);
  yield spawn(paymentCardsSagas);
  yield spawn(ManagePaymentsSagas);
  yield spawn(abandonedBasketSagas);
  yield spawn(attendeeSagas);
  yield spawn(vehicleSagas);
  yield spawn(RemoveCardsSagas);
  yield spawn(extraPaymentSagas);
  yield spawn(voucherSagas);
  yield spawn(initSaga);
  yield spawn(calendarSummarySagas);

  yield all([
    takeLatest(
      "windowLocation/onWindowLocationUpdated",
      windowLocationUpdatedSaga
    ),
    takeLatest("appState/resetAppState", initSaga),
  ]);
}
