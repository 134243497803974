import { createSlice } from "@reduxjs/toolkit";
import { LocationBlockInfo, LocationBlockInfoResponse } from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type LocationBlockInfoState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<LocationBlockInfo[]>;
};

const initialState: LocationBlockInfoState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const locationBlockInfoSlice = createSlice({
  name: "locationBlockInfo",
  initialState,
  reducers: {
    getLocationBlockAction: (state, action: PayloadAction<null>) => {
      state.loading = true;
      state.hasError = false;
      state.loaded = false;
      state.errorMessage = null;
      state.data = null;
    },
    getLocationBlockActionSuccess: (
      state,
      action: PayloadAction<LocationBlockInfoResponse>
    ) => {
      state.data = action.payload.locations as LocationBlockInfo[];
      state.loaded = true;
      state.loading = false;
      state.hasError = false;
      state.errorMessage = null;
    },
    getLocationBlockActionFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loaded = false;
      state.loading = false;
      state.data = null;
    },
  },
});

export const {
  getLocationBlockAction,
  getLocationBlockActionSuccess,
  getLocationBlockActionFail,
} = locationBlockInfoSlice.actions;

export default locationBlockInfoSlice.reducer;
