import { createSlice } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import moment from "moment";
import {
  CabinPanelBedroomOption_S as CabinPanelBedroomOption,
  CabinPanelSummary_S as CabinPanelSummary,
  LocationAvailabilitySummary_S as LocationAvailabilitySummary,
} from "@generated/types";
import { IDateRange } from "../../interfaces/calendar";
import { Guests } from "../../interfaces/guests";
import type { PayloadAction } from "@reduxjs/toolkit";
import { BookingCabinBedroom } from "./reservation.slice";

export type StickyState = {
  cabin: Nullable<CabinPanelSummary>;
  cabinBedroom: Nullable<CabinPanelBedroomOption>;
  cabinDetailsUrl: Nullable<string>;
  location: Nullable<LocationAvailabilitySummary>;
  locationDetailsUrl: Nullable<string>;
  guests: Nullable<Guests>;
  dda: boolean;
  dateRange: Nullable<IDateRange>;
  expiry: Nullable<string>;
  confirmedCabinReservationId: string;
  isLearnMoreClicked: boolean;
  activeJourneyStep?: any;
  activeJourney?: any;
  isInterstitialBooking: boolean;
  interstitialPagePathName: string;
};
const initialState: StickyState = Object.freeze({
  cabin: null,
  cabinBedroom: null,
  cabinDetailsUrl: null,
  location: null,
  locationDetailsUrl: null,
  guests: null,
  dda: false,
  dateRange: null,
  expiry: null,
  confirmedCabinReservationId: "",
  isLearnMoreClicked: false,
  isInterstitialBooking: false,
  interstitialPagePathName: "",
});

type StickyAction = {
  dateRange: IDateRange;
  guests: Guests;
  dda: boolean;
};

export type ViewLocationDetailsWithSticky = {
  location: LocationAvailabilitySummary;
  locationDetailsUrl: string;
} & StickyAction;

export type ViewInterstitialPageSticky = {
  cabin: CabinPanelSummary;
  isInterstitialBooking: boolean;
  interstitialPagePathName: string;
} & StickyAction;

export type ViewCabinDetailsWithSticky = {
  cabinDetailsUrl: string;
} & BookingCabinBedroom &
  StickyAction;

export const stickySlice = createSlice({
  name: "sticky",
  initialState,
  reducers: {
    viewLocationDetailsWithSticky: (
      state,
      action: PayloadAction<ViewLocationDetailsWithSticky>,
    ) => {
      return {
        ...initialState,
        ...action.payload,
        cabin: state.cabin,
        cabinBedroom: state.cabinBedroom,
        expiry: moment().add(1, "hours").toISOString(),
      };
    },
    viewCabinDetailsWithSticky: (
      state,
      action: PayloadAction<ViewCabinDetailsWithSticky>,
    ) => {
      return {
        ...initialState,
        ...action.payload,
        expiry: moment().add(1, "hours").toISOString(),
      };
    },
    viewInterstitialPageSticky: (
      state,
      action: PayloadAction<ViewInterstitialPageSticky>,
    ) => {
      state.isInterstitialBooking = action.payload?.isInterstitialBooking;
      state.interstitialPagePathName = action.payload?.interstitialPagePathName;
      state.cabin = action.payload?.cabin;
      state.dateRange = action.payload?.dateRange;
      state.guests = action.payload?.guests;
      state.dda = action.payload?.dda;

      state.expiry = moment().add(1, "hours").toISOString();
    },
    viewExtraDetailsWithSticky: (state, action: PayloadAction<string>) => {
      return {
        ...initialState,
        confirmedCabinReservationId: action.payload,
      };
    },
    removeExtraSticky: (state, action: PayloadAction<string>) => {
      return {
        ...initialState,
        isLearnMoreClicked: false,
      };
    },
    removeSticky: (state, action?: PayloadAction<boolean | string>) => {
      return {
        ...initialState,
        guests: state.guests,
        dateRange: action.payload ? null : state.dateRange,
      };
    },
    viewExtraStickyWithLearnMore: (state, action: PayloadAction<boolean>) => {
      return {
        ...initialState,
        confirmedCabinReservationId: state.confirmedCabinReservationId,
        isLearnMoreClicked: true,
      };
    },
    removeExtraStickyWithLearnMore: (state, action: PayloadAction<void>) => {
      return {
        ...initialState,
        confirmedCabinReservationId: state.confirmedCabinReservationId,
        isLearnMoreClicked: false,
      };
    },
  },
});
export const {
  viewLocationDetailsWithSticky,
  viewCabinDetailsWithSticky,
  viewInterstitialPageSticky,
  viewExtraDetailsWithSticky,
  removeExtraSticky,
  removeSticky,
  viewExtraStickyWithLearnMore,
  removeExtraStickyWithLearnMore,
} = stickySlice.actions;

export default stickySlice.reducer;
