import { createSlice } from "@reduxjs/toolkit";
import {
  ExtraStockAvailabilityResponse,
  QueryExtraStockAvailabilityArgs,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type FeaturedExtraStockControlState = {
  featuredExtraStockControlItems: Nullable<ExtraStockAvailabilityResponse[]>;
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
};

const initialState: FeaturedExtraStockControlState = Object.freeze({
  featuredExtraStockControlItems: null,
  hasError: false,
  errorMessage: null,
  loaded: false,
  loading: false,
});

export const featuredExtraStockControlSlice = createSlice({
  name: "featuredExtrasStockControl",
  initialState,
  reducers: {
    getFeaturedExtraStock: (
      state,
      action: PayloadAction<QueryExtraStockAvailabilityArgs>
    ) => {
      state.loading = true;
    },
    getFeaturedExtraStockSuccess: (
      state,
      action: PayloadAction<ExtraStockAvailabilityResponse>
    ) => {
      const featuredExtraStockControlItems =
        state.featuredExtraStockControlItems ?? [action.payload];
      const idx =
        state.featuredExtraStockControlItems?.findIndex(
          (item) => item.extraId === action.payload.extraId
        ) ?? -1;

      if (!!state.featuredExtraStockControlItems?.length) {
        if (idx >= 0) {
          featuredExtraStockControlItems.splice(idx, 1, action.payload);
        } else {
          featuredExtraStockControlItems.push(action.payload);
        }
      }

      state.loading = false;
      state.loaded = true;
      state.featuredExtraStockControlItems = featuredExtraStockControlItems;
    },
    getFeaturedExtraStockFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.featuredExtraStockControlItems = null;
      state.loaded = false;
      state.loading = false;
    },
  },
});

export const {
  getFeaturedExtraStock,
  getFeaturedExtraStockSuccess,
  getFeaturedExtraStockFail,
} = featuredExtraStockControlSlice.actions;

export default featuredExtraStockControlSlice.reducer;
