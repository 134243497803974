import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type ActiveStepperPersistState = {
  activeJourneyStep: number | null;
  activeJourney: string | null;
};

const initialState: ActiveStepperPersistState = Object.freeze({
  activeJourneyStep: 0,
  activeJourney: "",
});

export const activeStepperPersistedSlice = createSlice({
  name: "activeStepperSetting",
  initialState,
  reducers: {
    setActiveStepperSetting: (
      state,
      action: PayloadAction<ActiveStepperPersistState>
    ) => {      
      state.activeJourneyStep = action.payload.activeJourneyStep;
      state.activeJourney = action.payload.activeJourney;
    },
  },
});

export const { setActiveStepperSetting } = activeStepperPersistedSlice.actions;
export default activeStepperPersistedSlice.reducer;
