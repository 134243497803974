import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import { AddSavedCardRequestInput, PaymentResponse } from "@generated/types";

export type CreateAddCardState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<PaymentResponse>;
};

const initialState: CreateAddCardState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const addCardCreateSlice = createSlice({
  name: "addCard",
  initialState,
  reducers: {
    createAddCard: (state, action: PayloadAction<AddSavedCardRequestInput>) => {
      state.loading = true;
      state.data = null;
      state.errorMessage = null;
      state.loaded = false;
      state.hasError = false;
    },
    createAddCardSuccess: (state, action: PayloadAction<PaymentResponse>) => {
      state.data = action.payload;
      state.loaded = true;
      state.loading = false;
      state.errorMessage = null;
      state.hasError = false;
    },
    createAddCardFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loaded = false;
      state.loading = false;
      state.data = null;
    },
    createAddCardReset: (state) => ({
      ...initialState,
    }),
  },
});

export const {
  createAddCard,
  createAddCardFail,
  createAddCardReset,
  createAddCardSuccess,
} = addCardCreateSlice.actions;

export default addCardCreateSlice.reducer;
