import { createSlice } from "@reduxjs/toolkit";
import {
  CabinEssentialPanel_S as CabinEssentialPanel,
  Extra,
  QueryRefreshBookingSummaryArgs,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";
import { mapEssentialExtras } from "./persist-booking.slice";

export type ConfirmedBookingCabinEssentialsState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  cabinEssentials: Array<CabinEssentialPanel & Extra>;
  data?: CabinEssentialPanel[];
};

const initialState: ConfirmedBookingCabinEssentialsState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  cabinEssentials: [],
});

export const confirmBookingCabinEssentialsSlice = createSlice({
  name: "confirmBookingCabinEssentials",
  initialState,
  reducers: {
    getConfirmedBookingCabinEssentialsAction: (
      state,
      action: PayloadAction<QueryRefreshBookingSummaryArgs>
    ) => {
      state.loading = true;
      state.loaded = false;
      state.errorMessage = null;
      state.hasError = false;
      state.cabinEssentials = [];
    },
    getConfirmedBookingCabinEssentialsSuccessAction: (
      state,
      action: PayloadAction<CabinEssentialPanel[]>
    ) => {
      state.data = action.payload;
      state.cabinEssentials = mapEssentialExtras(action.payload);
      state.loaded = true;
      state.loading = false;
      state.errorMessage = null;
      state.hasError = false;
    },
    getConfirmedBookingCabinEssentialsFailAction: (
      state,
      action: PayloadAction<string>
    ) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loaded = false;
      state.loading = false;
      state.cabinEssentials = [];
      state.data = null;
    },
  },
});

export const {
  getConfirmedBookingCabinEssentialsAction,
  getConfirmedBookingCabinEssentialsSuccessAction,
  getConfirmedBookingCabinEssentialsFailAction,
} = confirmBookingCabinEssentialsSlice.actions;

export default confirmBookingCabinEssentialsSlice.reducer;
