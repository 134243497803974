import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import { Guest, MutationAddUpdateGuestArgs } from "@generated/types";

export type AttendeeAddState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<Guest>;
};

const initialState: AttendeeAddState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const attendeeAddSlice = createSlice({
  name: "attendeeAdd",
  initialState,
  reducers: {
    addAttendee: (state, action: PayloadAction<MutationAddUpdateGuestArgs>) => {
      state.loading = true;
      state.loaded = false;
      state.errorMessage = null;
      state.hasError = false;
      state.data = null;
    },
    addAttendeeSuccess: (state, action: PayloadAction<Guest>) => {
      state.data = action.payload;
      state.loading = false;
      state.loaded = true;
      state.errorMessage = null;
      state.hasError = false;
    },
    addAttendeeFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loaded = false;
      state.loading = false;
      state.data = null;
    },
  },
});

export const { addAttendee, addAttendeeFail, addAttendeeSuccess } =
  attendeeAddSlice.actions;

export default attendeeAddSlice.reducer;
