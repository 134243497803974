import { createSlice } from "@reduxjs/toolkit";
import {
  ExtraDetailsPage_S as ExtraDetailsPage,
  QueryExtraDetailsArgs,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type ExtraDetailsContentState = {
  loaded: boolean;
  loading: boolean;
  extraDetails: Nullable<ExtraDetailsPage>;
  hasError: boolean;
  errorMessage: Nullable<string>;
};

const initialState: ExtraDetailsContentState = Object.freeze({
  loaded: false,
  loading: false,
  extraDetails: null,
  hasError: false,
  errorMessage: null,
});

export const extraDetailsContentSlice = createSlice({
  name: "extraDetailsContent",
  initialState,
  reducers: {
    getExtraDetailsContent: (
      state,
      action: PayloadAction<QueryExtraDetailsArgs>
    ) => {
      state.loading = true;
    },
    getExtraDetailsContentSuccess: (
      state,
      action: PayloadAction<ExtraDetailsPage>
    ) => {
      state.loading = false;
      state.loaded = true;
      state.extraDetails = action.payload;
    },
    getExtraDetailsContentFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loaded = false;
      state.loading = false;
      state.extraDetails = null;
    },
  },
});

export const {
  getExtraDetailsContent,
  getExtraDetailsContentSuccess,
  getExtraDetailsContentFail,
} = extraDetailsContentSlice.actions;

export default extraDetailsContentSlice.reducer;
