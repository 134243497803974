import { RootState } from '../slices';
import { useSelector } from "react-redux";

export const reservationStateSelector = (state: RootState) => state.reservation;
export const reservationStateCabinIdSelector = (state: RootState) =>
  state.reservation.cabinId;
export const reservationExpirySelector = (state: RootState) =>
  state.reservation.priceExpiry;

export const useReservationStateSelector = () =>
  useSelector(reservationStateSelector);

export const useReservationExpirySelector = () =>
  useSelector(reservationExpirySelector);
