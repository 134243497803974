import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { getFirstQueryResult, getQueryAsStringArray } from "../../utils/common";
import { isGuid } from "../../utils/common";
import { RootState } from "../slices";

export const extraListSelector = (state: RootState) => state?.extraList;
export const useExtraListSelector = () => useSelector(extraListSelector);
export const useAddExtraSelector = () =>
  useSelector((state: RootState) => state?.addExtra);
export const useRemoveExtraSelector = () =>
  useSelector((state: RootState) => state?.removeExtra);
export const extraPaymentStateSelector = (state: RootState) =>
  state.extraPayment;
export const useExtraPaymentStateSelector = () =>
  useSelector(extraPaymentStateSelector);

export const isAddedExtraSummaryExpanded = (state: RootState) =>
  state.extraUi.isAddedExtrasExpanded;

export const useIsAddedExtraSummaryExpandedSelector = () =>
  useSelector(isAddedExtraSummaryExpanded);

export const extraFilterSelector = (state: RootState) =>
  state.extraList.activeFilters;
export const useExtraFilterSelector = () => useSelector(extraFilterSelector);

export const extraAvailableTags = (state: RootState) =>
  state.extraList.availableFilters;
export const useExtraAvailableTagsSelector = () =>
  useSelector(extraAvailableTags);

// Extra stock availability
export const extraStockSelector = (state: RootState) => state.extraStock;
export const useExtraStockSelector = () => useSelector(extraStockSelector);

export const getExtrasQueryStringSelector = ():
  | {
      selectedTagIds: string[];
      selectedExtraIds: string[];
      sortMethod: string;
      queryDataIsValid: boolean;
    }
  | undefined => {
  const { query } = useRouter();

  if (!!query) {
    const { t, s, e } = query;

    const selectedTagIds = getQueryAsStringArray(t)?.filter(isGuid);

    const selectedExtraIds = getQueryAsStringArray(e)?.filter(isGuid);

    const sortMethod = getFirstQueryResult(s);

    const queryDataIsValid =
      selectedTagIds?.length > 0 ||
      selectedExtraIds?.length > 0 ||
      !!sortMethod;

    return {
      selectedTagIds: selectedTagIds,
      selectedExtraIds: selectedExtraIds,
      sortMethod: sortMethod,
      queryDataIsValid,
    };
  }
};
