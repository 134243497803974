import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import {
  QueryTokenisedCardsArgs,
  TokenisedCardsResponse,
} from "@generated/types";

export type PaymentCardsState = {
  loading: boolean;
  data: Nullable<TokenisedCardsResponse>;
  errorMessage: Nullable<string>;
  loaded: boolean;
  hasError: boolean;
};

const initialState: PaymentCardsState = Object.freeze({
  loading: false,
  data: null,
  errorMessage: null,
  loaded: false,
  hasError: false,
});

export const paymentCardsSlice = createSlice({
  name: "paymentCards",
  initialState,
  reducers: {
    getPaymentCardsAction: (
      state,
      action: PayloadAction<QueryTokenisedCardsArgs>
    ) => {
      state.loading = true;
      state.data = null;
      state.errorMessage = null;
      state.loaded = false;
      state.hasError = false;
    },
    getPaymentCardsSuccess: (
      state,
      action: PayloadAction<TokenisedCardsResponse>
    ) => {
      state.data = action.payload;
      state.loaded = true;
      state.loading = false;
      state.errorMessage = null;
      state.hasError = false;
    },
    getPaymentCardsFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loaded = false;
      state.loading = false;
      state.data = null;
    },
  },
});

export const {
  getPaymentCardsAction,
  getPaymentCardsFail,
  getPaymentCardsSuccess,
} = paymentCardsSlice.actions;
export default paymentCardsSlice.reducer;
