import { createSlice } from "@reduxjs/toolkit";
import {
  AvailableCabin,
  AvailableCabinsResponse,
  QueryAvailableCabinsForReservationArgs,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type CabinsForReservationState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<AvailableCabin[]>;
  minCabinSelectionPrice?: number;
  selectedCabin?: AvailableCabin;
};

const initialState: CabinsForReservationState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const cabinsReservationSlice = createSlice({
  name: "cabinsReservation",
  initialState,
  reducers: {
    getAvailableCabinsForReservation: (
      state,
      action: PayloadAction<QueryAvailableCabinsForReservationArgs>
    ) => ({
      ...initialState,
      loading: true,
      selectedCabin: state.selectedCabin,
    }),
    getAvailableCabinsForReservationSuccess: (
      state,
      action: PayloadAction<AvailableCabinsResponse>
    ) => {
      const data = action.payload.availableCabins as AvailableCabin[];
      return {
        ...initialState,
        data,
        minCabinSelectionPrice: Math.min.apply(
          null,
          (data || []).map((cabin) => cabin.price)
        ),
        loaded: true,
        selectedCabin: state.selectedCabin,
      };
    },
    getAvailableCabinsForReservationFail: (
      state,
      action: PayloadAction<string>
    ) => ({
      ...initialState,
      hasError: true,
      errorMessage: action.payload,
      selectedCabin: state.selectedCabin,
    }),
    setSelectedCabinForReservation: (
      state,
      action: PayloadAction<{ cabinId: string | null }>
    ) => {
      const selectedCabin = state.data?.find(
        (cabin) => cabin.cabinId === action.payload.cabinId
      );
      return {
        ...state,
        selectedCabin,
      };
    },
  },
});

export const {
  getAvailableCabinsForReservation,
  getAvailableCabinsForReservationSuccess,
  getAvailableCabinsForReservationFail,
  setSelectedCabinForReservation,
} = cabinsReservationSlice.actions;

export default cabinsReservationSlice.reducer;
