import { createSlice } from "@reduxjs/toolkit";
import {
  PlanYourVisitResponse,
  QueryPlanYourVisitArgs,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type PlanYourVisitState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  planYourVisit: Nullable<string>;
  getPlanYourVisitRequest?: QueryPlanYourVisitArgs;
};

const initialState: PlanYourVisitState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  planYourVisit: null,
});

export const planYourVisitSlice = createSlice({
  name: "planYourVisit",
  initialState,
  reducers: {
    getPlanYourVisit: (
      state,
      action: PayloadAction<QueryPlanYourVisitArgs>
    ) => {
      state.getPlanYourVisitRequest = action.payload;
      state.loading = true;
    },
    getPlanYourVisitSuccess: (
      state,
      action: PayloadAction<PlanYourVisitResponse>
    ) => {
      const planYourVisit = action.payload.json as string;

      state.planYourVisit = planYourVisit;
      state.loaded = true;
      state.loading = false;
    },
    getPlanYourVisitFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage =
        action?.payload ||
        "An error occurred with Plan Your Visit. Please try again.";
      state.loaded = false;
      state.loading = false;
      state.planYourVisit = null;
    },
  },
});

export const {
  getPlanYourVisit,
  getPlanYourVisitSuccess,
  getPlanYourVisitFail,
} = planYourVisitSlice.actions;

export default planYourVisitSlice.reducer;
