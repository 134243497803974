import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import {
  ChangeCardForUpcomingPaymentsResponse,
  MutationChangeCardForUpcomingPaymentsArgs,
} from "@generated/types";

export type ChangeCardState = {
  loading: boolean;
  data: Nullable<ChangeCardForUpcomingPaymentsResponse>;
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
};

const initialState: ChangeCardState = Object.freeze({
  loading: false,
  data: null,
  errorMessage: null,
  hasError: false,
  loaded: false,
});

export const changeCardSlice = createSlice({
  name: "changeCard",
  initialState,
  reducers: {
    changeCardForUpcomingPaymentsAction: (
      state,
      action: PayloadAction<MutationChangeCardForUpcomingPaymentsArgs>
    ) => {
      state.loading = true;
      state.data = null;
      state.errorMessage = null;
      state.loaded = false;
      state.hasError = false;
    },
    changeCardForUpcomingPaymentsSuccessAction: (
      state,
      action: PayloadAction<ChangeCardForUpcomingPaymentsResponse>
    ) => {
      state.data = action.payload;
      state.loaded = false;
      state.loading = false;
      state.errorMessage = null;
      state.hasError = false;
    },
    changeCardForUpcomingPaymentsFailAction: (
      state,
      action: PayloadAction<string>
    ) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loaded = true;
      state.loading = false;
      state.data = null;
    },
    changeCardForUpcomingPaymentsResetAction: (state) => {
      state.loading = false;
      state.data = null;
      state.errorMessage = null;
      state.loaded = false;
      state.hasError = false;
    },
  },
});

export const {
  changeCardForUpcomingPaymentsAction,
  changeCardForUpcomingPaymentsFailAction,
  changeCardForUpcomingPaymentsResetAction,
  changeCardForUpcomingPaymentsSuccessAction,
} = changeCardSlice.actions;

export default changeCardSlice.reducer;
