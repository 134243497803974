import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { gql } from "graphql-tag";
import { apolloClient } from "../../apollo-client";
import { getErrorMessageByKey } from "../../components/Utils";
import {
  BookingsWithScheduledPaymentsResponse,
  QueryBookingsWithScheduledPaymentsArgs,
} from "@generated/types";
import { errorMessagesDataSelector } from "../selectors";
import {
  getScheduledPaymentsByBookingAction,
  getScheduledPaymentsByBookingFailAction,
  getScheduledPaymentsByBookingSuccessAction,
} from "../slices";
import { loadCustomGraphQLQuery } from "../../utils";

function* getScheduledPaymentsByBooking(
  action: ReturnType<typeof getScheduledPaymentsByBookingAction>,
) {
  const dynamicClearUnconfirmedExtras = yield call(
    loadCustomGraphQLQuery,
    "bookingsWithScheduledPayments",
  );
  const getScheduledPaymentsByBookingCall = () =>
    apolloClient.query<
      { bookingsWithScheduledPayments: BookingsWithScheduledPaymentsResponse },
      QueryBookingsWithScheduledPaymentsArgs
    >({
      query: gql`
        ${dynamicClearUnconfirmedExtras}
      `,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });
  try {
    const response = yield call(getScheduledPaymentsByBookingCall);
    if (response.data?.bookingsWithScheduledPayments) {
      yield put(
        getScheduledPaymentsByBookingSuccessAction(
          response.data.bookingsWithScheduledPayments,
        ),
      );
    } else {
      throw Error("Failed to get scheduled payments by booking");
    }
  } catch (error: any) {
    const errorMessages = yield select(errorMessagesDataSelector);
    const errorByKey = getErrorMessageByKey(error.message, errorMessages);
    yield put(
      getScheduledPaymentsByBookingFailAction(
        errorByKey?.message || error.message,
      ),
    );
    // appInsights.trackException(error);
  }
}

export default function* scheduledPaymentsByBookingSagas() {
  yield all([
    takeLatest(
      "scheduledPaymentsByBooking/getScheduledPaymentsByBookingAction",
      getScheduledPaymentsByBooking,
    ),
  ]);
}
