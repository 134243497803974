export enum ExtrasStepperTag {
  extras = 'extras',
  payment = 'payment',
  confirm = 'confirmation',
  cabinEssentials = 'cabinessentials',
}


export type SelectedExtraOption = {
  extraOptionId: string;
  confirmedQuantity: number;
  obtainingQuoteQuantity: number;
  totalQuantity: number;
};
