import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LocationsDropdownItem } from "../../interfaces/bookingForm";
import {
  BookableLocation,
  BookableLocationRequestInput,
} from "@generated/types";
import { Nullable } from "../../utils";

export type LocationState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  bookableLocations: Nullable<LocationsDropdownItem[]>;
  mapLocations: Nullable<BookableLocation[]>;
};

export type selectLocationParams = {
  selectedLocationIds: string[];
  checked?: boolean;
  bookingLocation?: LocationsDropdownItem;
};

export type selectAllLocationsParams = {
  checked: boolean;
};

const initialState: LocationState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  sortedLocations: null,
  bookableLocations: null,
  mapLocations: null,
});

export const locationSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    selectLocation: (state, action: PayloadAction<selectLocationParams>) => {
      return state;
    },
    selectAllLocations: (
      state,
      action: PayloadAction<selectAllLocationsParams>,
    ) => {
      return state;
    },
    getLocationsAction: (
      state,
      action: PayloadAction<BookableLocationRequestInput>,
    ) => {
      state.loading = true;
    },
    getLocationsSuccess: (state, action: PayloadAction<BookableLocation[]>) => {
      state.bookableLocations = action.payload.map(
        (bookableLocation) =>
          ({
            id: bookableLocation.id,
            country: bookableLocation.country,
            region: bookableLocation.region,
            name: bookableLocation.locationName,
          }) as LocationsDropdownItem,
      );
      state.mapLocations = action.payload;
      state.loaded = true;
      state.errorMessage = null;
      state.hasError = false;
      state.loading = false;
    },
    getLocationsFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loaded = false;
      state.loading = false;
      state.bookableLocations = null;
      state.mapLocations = null;
    },
  },
});

export const {
  getLocationsAction,
  getLocationsSuccess,
  getLocationsFail,
  selectLocation,
  selectAllLocations,
} = locationSlice.actions;

export default locationSlice.reducer;
