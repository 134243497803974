import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import {
  QueryApplicableTokenisedCardsArgs,
  TokenisedCardsResponse,
} from "@generated/types";

export type ApplicableSavedCardsState = {
  loading: boolean;
  data: Nullable<TokenisedCardsResponse>;
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
};

const initialState: ApplicableSavedCardsState = Object.freeze({
  loading: false,
  data: null,
  errorMessage: null,
  loaded: false,
  hasError: false,
});

export const applicableSavedCardsSlice = createSlice({
  name: "applicableSavedCards",
  initialState,
  reducers: {
    getApplicableSavedCardsAction: (
      state,
      action: PayloadAction<QueryApplicableTokenisedCardsArgs>
    ) => {
      state.loading = true;
      state.data = null;
      state.errorMessage = null;
      state.loaded = false;
      state.hasError = false;
    },
    getApplicableSavedCardsSuccess: (
      state,
      action: PayloadAction<TokenisedCardsResponse>
    ) => {
      state.data = action.payload;
      state.loaded = true;
      state.loading = false;
      state.errorMessage = null;
      state.hasError = false;
    },
    getApplicableSavedCardsFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loaded = false;
      state.loading = false;
      state.data = null;
    },
  },
});

export const {
  getApplicableSavedCardsAction,
  getApplicableSavedCardsFail,
  getApplicableSavedCardsSuccess,
} = applicableSavedCardsSlice.actions;

export default applicableSavedCardsSlice.reducer;
