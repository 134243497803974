import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import {
  MutationRemoveGiftVoucherFromBookingArgs,
  RemoveGiftVoucherResponse,
} from "@generated/types";

export type RemoveVoucherState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<RemoveGiftVoucherResponse>;
};

const initialState: RemoveVoucherState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const removeVoucherSlice = createSlice({
  name: "voucherRemove",
  initialState,
  reducers: {
    removeVoucherAction: (
      state,
      action: PayloadAction<MutationRemoveGiftVoucherFromBookingArgs>
    ) => {
      state.loading = true;
      state.errorMessage = null;
      state.hasError = false;
      state.loaded = false;
      state.data = null;
    },
    removeVoucherSuccessAction: (
      state,
      action: PayloadAction<RemoveGiftVoucherResponse>
    ) => {
      state;
      state.data = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    removeVoucherFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loading = false;
      state.loaded = false;
      state.data = null;
    },
  },
});

export const {
  removeVoucherAction,
  removeVoucherFail,
  removeVoucherSuccessAction,
} = removeVoucherSlice.actions;
export default removeVoucherSlice.reducer;
