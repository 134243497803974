import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import {
  MutationUpdateCustomerDetailsArgs,
  UpdateCustomerDetailResponseUnion,
} from "@generated/types";

export type UpdateCustomerDetailState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<UpdateCustomerDetailResponseUnion>;
};

const initialState: UpdateCustomerDetailState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const updateMyDetailsCreateSlice = createSlice({
  name: "updateMyDetailsCreate",
  initialState,
  reducers: {
    createUpdateMydetails: (
      state,
      action: PayloadAction<MutationUpdateCustomerDetailsArgs>
    ) => {
      state.loading = true;
      state.data = null;
      state.errorMessage = null;
      state.loaded = false;
      state.hasError = false;
    },
    createUpdateMydetailsSuccess: (
      state,
      action: PayloadAction<UpdateCustomerDetailResponseUnion>
    ) => {
      state.data = action.payload;
      state.loaded = true;
      state.loading = false;
      state.errorMessage = null;
      state.hasError = false;
    },
    createUpdateMydetailsFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loaded = true;
      state.loading = false;
      state.data = null;
    },
  },
});

export const {
  createUpdateMydetails,
  createUpdateMydetailsFail,
  createUpdateMydetailsSuccess,
} = updateMyDetailsCreateSlice.actions;

export default updateMyDetailsCreateSlice.reducer;
