import { createSlice } from "@reduxjs/toolkit";
import { AvailableYear } from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type CalendarSummaryState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<AvailableYear[]>;
  availableYears: Nullable<number[]>;
  availableMonths: Nullable<{
    [key: number]: string[];
  }>;
  showQuickLinks: boolean;
  defaultYear: number | null;
  defaultMonth: string;
};

const initialState: CalendarSummaryState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
  availableYears: null,
  availableMonths: null,
  showQuickLinks: false,
  defaultYear: 0,
  defaultMonth: "",
});

export const calendarSummarySlice = createSlice({
  name: "calendarSummary",
  initialState,
  reducers: {
    getCalendarSummary: (state, action: PayloadAction<void>) => {
      state.loading = true;
      state.hasError = false;
      state.loaded = false;
      state.errorMessage = null;
      state.data = null;
    },
    getCalendarSummarySuccess: (
      state,
      action: PayloadAction<AvailableYear[]>
    ) => {
      let availableYears = [],
        availableMonths = {};
      action.payload.forEach((availableYearsAndMonths) => {
        availableYears.push(availableYearsAndMonths.year);
        availableMonths[availableYearsAndMonths.year] =
          availableYearsAndMonths.availableMonths.map((months) => months.month);
        availableYearsAndMonths.availableMonths;
      });

      state.loading = false;
      state.loaded = true;
      state.data = action.payload;
      state.availableYears = availableYears;
      state.availableMonths = availableMonths;
      state.showQuickLinks = Object.keys(availableMonths).length > 0;
      state.defaultYear = availableYears[0];
      state.defaultMonth = availableMonths[availableYears[0]][0];
    },
    getCalendarSummaryFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.loaded = true;
      state.hasError = true;
      state.errorMessage = action.payload;
    },
  },
});

export const {
  getCalendarSummary,
  getCalendarSummarySuccess,
  getCalendarSummaryFail,
} = calendarSummarySlice.actions;

export default calendarSummarySlice.reducer;
