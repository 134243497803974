import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type GVbasketIdPersistState = {
  basketId: string | null;
};

const initialState: GVbasketIdPersistState = Object.freeze({
  basketId: "",
});

export const gvBasketIdPersistedSlice = createSlice({
  name: "gvBasketIdPersisted",
  initialState,
  reducers: {
    setGvBasketId: (state, action: PayloadAction<string | null>) => {
      state.basketId = action.payload;
    },
  },
});

export const { setGvBasketId } = gvBasketIdPersistedSlice.actions;

export default gvBasketIdPersistedSlice.reducer;
