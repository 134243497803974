import { HttpStatusCode } from "axios";
import { getCookie, setCookie } from "cookies-next";
import { FHCookies } from "src/interfaces/cookies";

export const MouseMovement = () => {
  const mouseMovement = getCookie(FHCookies.mouseMovement);
  let mouseMovementTriggered = false;

  const fetchData = async () => {
    if (mouseMovement || mouseMovementTriggered) {
      return;
    }
    mouseMovementTriggered = true;
    try {
      const response = await fetch("/api/get-mouse-movement", {
        headers: {
          accept: "application/json",
          "experiment-url": window.location.href,
        },
      });

      if (HttpStatusCode.NoContent === response.status) {
        const fourHundredDayExpiry = new Date();
        fourHundredDayExpiry.setDate(fourHundredDayExpiry.getDate() + 400);

        setCookie(FHCookies.mouseMovement, 1, {
          expires: fourHundredDayExpiry,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  if (!mouseMovement) {
    document.addEventListener("mousemove", (e) => {
      fetchData();
    });
  }
};
