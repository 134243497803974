import { createSlice } from "@reduxjs/toolkit";
import {
  CabinPanelSummaryResponse,
  Category_S as Category,
  QueryCabinAvailabilityArgs,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";
import { useRouter } from "next/router";

export type SearchCabinState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<CabinPanelSummaryResponse>;
  cabinSort: string;
  availableFilters: Category[];
  activeFilters: string[];
  redirecting: boolean;
  cabinSearchData: QueryCabinAvailabilityArgs;
};

const initialState: SearchCabinState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
  cabinSort: "",
  availableFilters: [],
  activeFilters: [],
  redirecting: false,
  cabinSearchData: null,
});

export const cabinsSearchSlice = createSlice({
  name: "cabinsSearch",
  initialState,
  reducers: {
    getCabin: (
      state,
      action: PayloadAction<{
        cabinQuery: QueryCabinAvailabilityArgs;
        filtered: boolean;
      }>
    ) => {
      state.loading = true;
      state.cabinSearchData = action?.payload?.cabinQuery;
    },
    getCabinSuccess: (
      state,
      action: PayloadAction<{
        summaryResponse: CabinPanelSummaryResponse;
        filtered: boolean;
      }>
    ) => {
      const availableFilters = action.payload.filtered
        ? state.availableFilters
        : (action.payload.summaryResponse.availableFilters as Category[]);
      const activeFilters = action.payload.filtered ? state.activeFilters : [];
      return {
        ...initialState,
        cabinSearchData: state.cabinSearchData,
        data: action.payload.summaryResponse,
        loaded: true,
        cabinSort: state.cabinSort,
        availableFilters,
        activeFilters,
      };
    },
    getCabinFail: (state, action: PayloadAction<string>) => ({
      ...initialState,
      hasError: true,
      errorMessage: action.payload,
      cabinSort: state.cabinSort,
    }),
    createCabinSearchRedirect: (
      state,
      action: PayloadAction<ReturnType<typeof useRouter>>
    ) => {
      state.redirecting = true;
    },
    setCabinSearchFilter: (
      state,
      action: PayloadAction<{
        sortMethod: string | false;
        filters: string[] | false;
      }>
    ) => ({
      ...state,
      cabinSort: action.payload.sortMethod || state.cabinSort,
      activeFilters: action.payload.filters || state.activeFilters,
    }),
    createCabinSearch: () => {},
  },
});

export const {
  getCabin,
  getCabinSuccess,
  getCabinFail,
  setCabinSearchFilter,
  createCabinSearchRedirect,
  createCabinSearch,
} = cabinsSearchSlice.actions;

export default cabinsSearchSlice.reducer;
