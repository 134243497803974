import { createSlice } from "@reduxjs/toolkit";
import { Nullable } from "../../utils";
import {
  ConfirmedGiftVouchersResponse,
  QueryConfirmedGiftVoucherItemsArgs,
} from "@generated/types";
import type { PayloadAction } from "@reduxjs/toolkit";

export type GiftVoucherConfirmedBasketState = {
  loading: boolean;
  loaded: boolean;
  hasError: boolean;
  data: Nullable<ConfirmedGiftVouchersResponse>;
  errorMessage: Nullable<string>;
};

const initialState: GiftVoucherConfirmedBasketState = Object.freeze({
  loading: false,
  loaded: false,
  hasError: false,
  data: null,
  errorMessage: null,
});

export const giftVoucherConfirmedBasketSlice = createSlice({
  name: "giftVoucherConfirmedBasket",
  initialState,
  reducers: {
    confirmedGiftVoucher: (
      state,
      action: PayloadAction<QueryConfirmedGiftVoucherItemsArgs>
    ) => {
      state.loading = true;
      state.loaded = false;
      state.hasError = false;
      state.errorMessage = null;
      state.data = null;
    },
    confirmedGiftVoucherSuccess: (
      state,
      action: PayloadAction<ConfirmedGiftVouchersResponse>
    ) => {
      state.data = action.payload;
      state.loaded = true;
      state.loading = false;
      state.errorMessage = null;
      state.hasError = false;
    },
    confirmedGiftVoucherFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.loaded = false;
      state.loading = false;
      state.data = null;
    },
  },
});
export const {
  confirmedGiftVoucher,
  confirmedGiftVoucherFail,
  confirmedGiftVoucherSuccess,
} = giftVoucherConfirmedBasketSlice.actions;
export default giftVoucherConfirmedBasketSlice.reducer;
