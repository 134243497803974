import { FeaturedExtraStockControlState } from "./featured-extra-stock-control";
import { BookingSearchState } from "./booking-search.slice";
import { BookingSummaryState } from "./booking-summary.slice";
import { UpcomingBookingState } from "./booking-upcoming.slice";
import { ExtraStockControlState } from "./extra-stock-control.slice";
import { FeaturedExtraDetailsContentState } from "./featured-extra-details-content";
import { FutureBookingsState } from "./future-bookings.slice";
import { SearchLocationState } from "./location-search.slice";
import { LocationState } from "./location.slice";
import { PlanYourVisitState } from "./plan-your-visit.slice";
import { ReservationState } from "./reservation.slice";
import { SingleFutureCabinReservationState } from "./single-future-booking.slice";
import { SiteSettingsState } from "./site-settings.slice";
import { StickyState } from "./sticky.slice";
import { AddCabinState } from "./cabin-add.slice";
import { SearchCabinState } from "./cabin-search.slice";
import { CabinTypesState } from "./cabinsType";
import { CabinTypesAtLocationState } from "./cabin-type-location.slice";
import { CabinsForReservationState } from "./cabin-for-reservation.slice";
import { AddSpecificCabinState } from "./cabin-specific-add.slice";
import { RemoveSpecificCabinState } from "./cabin-specific-remove.slice";
import { SwapSpecificCabinState } from "./cabin-specific-swap.slice";
import { BookingPersistState } from "./persist-booking.slice";
import { AddExtraState } from "./extra-add.slice";
import { RemoveExtraState } from "./extra-remove.slice";
import { LocationBlockInfoState } from "./location-block-info.slice";
import { ExtraUiState } from "./extra-ui.slice";
import { ExtraListState } from "./extra-list.slice";
import { AddFeaturedExtraState } from "./featured-extras-add.slice";
import { RemoveFeaturedExtraState } from "./featured-extra-remove";
import { FeaturedExtraListState } from "./featured-extra-list.slice";
import { ConfirmedBookingSummaryState } from "./confirmed-booking-summary.slice";
import { ConfirmedBookingInfoState } from "./confirmed-booking-info.slice";
import { ConfirmedBookingCabinEssentialsState } from "./confirmed-booking-cabin-essentials.slice";
import { CreateExtraPaymentState } from "./extra-payment.slice";
import { ExtraDetailsPageState } from "./extra-details-page.slice";
import { ExtraDetailsContentState } from "./extra-details-content.slice";
import { ExtrasPurchasedState } from "./extra-purchased.slice";
import { LoginState } from "./auth.slice";
import { AccountState } from "./account.slice";
import { RegisterState } from "./register.slice";
import { ResetPasswordState } from "./reset-password.slice";
import { ForgotPasswordState } from "./forgot-password.slice";
import { BreadcrumbState } from "./breadcrumb.slice";
import { BookingCustomerAddState } from "./booking-customer-add.slice";
import { LocationMapState } from "./location-map.slice";
import { ErrorMessagesState } from "./error-messages.slice";
import { DatesState } from "./dates.slice";
import { PromoCodeState } from "./promo-code.slice";
import { CreatePaymentState } from "./payment-create.slice";
import { FaqState } from "./faq.slice";
import { ActiveStepperPersistState } from "./active-stepper-persisted.slice";
import { GVbasketIdPersistState } from "./gv-basketId-persisted.slice";
import { GVSettingsState } from "./gv-settings.slice";
import { GiftVoucherAddState } from "./gift-voucher-add.slice";
import { GiftVoucherBasketState } from "./gift-voucher-basket.slice";
import { GiftVoucherConfirmedBasketState } from "./gift-voucher-confirmedBasket.slice";
import { GiftVoucherEditState } from "./gift-voucher-edit.slice";
import { GiftVoucherPaymentState } from "./gift-voucher-payment.slice";
import { GiftVoucherRemoveState } from "./gift-voucher-remove.slice";
import { BlogsState } from "./blog.slice";
import { PostCodeAddressesState } from "./post-code-addresses.slice";
import { CreateConfirmFreeExtrasState } from "./confirm-freeextras.slice";
import { VoucherState } from "./voucher.slice";
import { ApplyVoucherState } from "./voucher-apply.slice";
import { RemoveVoucherState } from "./voucher-remove.slice";
import { BookingDataForGAState } from "./booking-data-for-ga.slice";
import { CreateChangePasswordState } from "./change-password.slice";
import { UpdateCustomerDetailState } from "./update-mydetails.slice";
import { FeaturedExtraState } from "./featured-extra.slice";
import { ScheduledPaymentsByBookingState } from "./scheduled-payments-by-booking.slice";
import { ScheduledPaymentsByBookingIdState } from "./scheduled-payments-by-booking-id.slice";
import { ScheduledPaymentsState } from "./scheduled-payment.slice";
import { PaymentCardsState } from "./payment-cards.slice";
import { CreateAddCardState } from "./add-card.slice";
import { RemoveCardsState } from "./removeCard.slice";
import { ChangeCardState } from "./change-card.slice";
import { ApplicableSavedCardsState } from "./applicable-saved-cards.slice";
import { AbandonedBasketState } from "./abandoned-basket.slice";
import { AttendeeAddState } from "./attendee-add.slice";
import { AttendeeRemoveState } from "./attendee-remove.slice";
import { AttendeeSummaryState } from "./attendee-summary.slice";
import { VehicleAddState } from "./vehicle-add.slice";
import { VehicleRemoveState } from "./vehicle-remove.slice";
import { ExtrasStockControlState } from "./extras-stock-control.slice";
import { BookingPaymentOptionsState } from "./payment-options.slice";
import { CalendarSummaryState } from "./calendar-summary.slice";
import { AuthRememberMeState } from "./auth-remember-me.slice";
import { CabinInfoState } from "./cabin-info.slice";
import { ReservationFromReferralState } from "./reservation-from-referral.slice";
import { MandatoryExtrasState } from "./mandatory-extras.slice";
import { ActiveExperimentsState } from "./active-experiments.slice";

export type RootState = {
  account: AccountState;
  cabinAdd: AddCabinState;
  cabinSearch: SearchCabinState;
  cabinAllTypes: CabinTypesState;
  cabinTypesAtLocation: CabinTypesAtLocationState;
  cabinsForReservation: CabinsForReservationState;
  cabinSpecificAdd: AddSpecificCabinState;
  cabinSpecificRemove: RemoveSpecificCabinState;
  cabinSpecificSwap: SwapSpecificCabinState;
  bookingSearch: BookingSearchState;
  bookingSummary: BookingSummaryState;
  bookingUpcoming: UpcomingBookingState;
  bookingPersisted: BookingPersistState;
  futureBookings: FutureBookingsState;
  addExtra: AddExtraState;
  removeExtra: RemoveExtraState;
  extraUi: ExtraUiState;
  extraList: ExtraListState;
  featuredExtra: FeaturedExtraState;
  addFeaturedExtra: AddFeaturedExtraState;
  removeFeaturedExtra: RemoveFeaturedExtraState;
  featuredExtrasList: FeaturedExtraListState;
  locations: LocationState;
  locationBlockInfo: LocationBlockInfoState;
  locationsSearch: SearchLocationState;
  login: LoginState;
  register: RegisterState;
  planYourVisit: PlanYourVisitState;
  confirmedBookingSummary: ConfirmedBookingSummaryState;
  confirmedBookingInfo: ConfirmedBookingInfoState;
  confirmedBookingCabinEssentials: ConfirmedBookingCabinEssentialsState;
  reservation: ReservationState;
  siteSettings: SiteSettingsState;
  sticky: StickyState;
  extraPayment: CreateExtraPaymentState;
  extraDetailsPage: ExtraDetailsPageState;
  extraDetailsContent: ExtraDetailsContentState;
  featuredExtraDetails: FeaturedExtraDetailsContentState;
  extrasPurchased: ExtrasPurchasedState;
  extraStock: ExtraStockControlState;
  extrasStock: ExtrasStockControlState;
  featuredExtraStock: FeaturedExtraStockControlState;
  faqs: FaqState;
  singleFutureCabinReservation: SingleFutureCabinReservationState;
  resetPassword: ResetPasswordState;
  forgotPassword: ForgotPasswordState;
  breadcrumb: BreadcrumbState;
  bookingCustomerAdd: BookingCustomerAddState;
  locationMap: LocationMapState;
  errorMessages: ErrorMessagesState;
  dates: DatesState;
  promoCode: PromoCodeState;
  createPayment: CreatePaymentState;
  paymentOptions: BookingPaymentOptionsState;
  activeStepperSetting: ActiveStepperPersistState;
  gvBasketIdPersisted: GVbasketIdPersistState;
  gvSettings: GVSettingsState;
  giftVoucherAdd: GiftVoucherAddState;
  giftVoucherBasket: GiftVoucherBasketState;
  giftVoucherConfirmedBasket: GiftVoucherConfirmedBasketState;
  giftVouchersSaved: GiftVoucherEditState;
  giftVouchersPayment: GiftVoucherPaymentState;
  giftVoucherRemove: GiftVoucherRemoveState;
  blogs: BlogsState;
  postCodeAddresses: PostCodeAddressesState;
  confirmFreeExtras: CreateConfirmFreeExtrasState;
  voucher: VoucherState;
  voucherApply: ApplyVoucherState;
  voucherRemove: RemoveVoucherState;
  bookingDataForGA: BookingDataForGAState;
  changePasswordCreate: CreateChangePasswordState;
  updateMyDetailsCreate: UpdateCustomerDetailState;
  scheduledPaymentsByBooking: ScheduledPaymentsByBookingState;
  scheduledPaymentsByBookingId: ScheduledPaymentsByBookingIdState;
  scheduledPayments: ScheduledPaymentsState;
  paymentCards: PaymentCardsState;
  addCard: CreateAddCardState;
  removedCards: RemoveCardsState;
  changeCard: ChangeCardState;
  applicableSavedCards: ApplicableSavedCardsState;
  abandonedBasket: AbandonedBasketState;
  attendeeAdd: AttendeeAddState;
  attendeeRemove: AttendeeRemoveState;
  attendeeSummary: AttendeeSummaryState;
  vehicleAdd: VehicleAddState;
  vehicleRemove: VehicleRemoveState;
  calendarSummary: CalendarSummaryState;
  authRememberMe: AuthRememberMeState;
  cabinInfo: CabinInfoState;
  reservationFromReferral: ReservationFromReferralState;
  mandatoryExtras: MandatoryExtrasState;
  experiments: ActiveExperimentsState;
};

export * from "../slices/abandoned-basket.slice";
export * from "../slices/account.slice";
export * from "../slices/active-stepper-persisted.slice";
export * from "../slices/add-card.slice";
export * from "../slices/applicable-saved-cards.slice";
export * from "./payment-options.slice";
export * from "../slices/attendee-add.slice";
export * from "../slices/attendee-remove.slice";
export * from "../slices/attendee-summary.slice";
export * from "../slices/auth.slice";
export * from "../slices/authActions";
export * from "../slices/blog.slice";
export * from "../slices/booking-customer-add.slice";
export * from "../slices/booking-data-for-ga.slice";
export * from "../slices/booking-search.slice";
export * from "../slices/booking-summary.slice";
export * from "../slices/booking-upcoming.slice";
export * from "../slices/breadcrumb.slice";
export * from "../slices/cabin-add.slice";
export * from "../slices/cabinActions";
export * from "../slices/cabin-for-reservation.slice";
export * from "../slices/cabin-search.slice";
export * from "../slices/cabin-specific-add.slice";
export * from "../slices/cabin-specific-remove.slice";
export * from "../slices/cabin-specific-swap.slice";
export * from "../slices/cabinsType";
export * from "../slices/cabin-type-location.slice";
export * from "../slices/change-card.slice";
export * from "../slices/change-password.slice";
export * from "../slices/confirm-freeextras.slice";
export * from "../slices/confirmed-booking-cabin-essentials.slice";
export * from "../slices/confirmed-booking-info.slice";
export * from "../slices/confirmed-booking-summary.slice";
export * from "../slices/dates.slice";
export * from "../slices/error-messages.slice";
export * from "../slices/extra-add.slice";
export * from "../slices/extra-details-content.slice";
export * from "../slices/extra-details-page.slice";
export * from "../slices/extra-list.slice";
export * from "../slices/extra-payment.slice";
export * from "../slices/extra-purchased.slice";
export * from "../slices/extra-remove.slice";
export * from "../slices/extra-stock-control.slice";
export * from "../slices/extra-ui.slice";
export * from "../slices/extras-stock-control.slice";
export * from "../slices/faq.slice";
export * from "../slices/featured-extra-details-content";
export * from "../slices/featured-extra-list.slice";
export * from "../slices/featured-extra-remove";
export * from "../slices/featured-extra-stock-control";
export * from "../slices/featured-extra.slice";
export * from "../slices/featured-extras-add.slice";
export * from "../slices/forgot-password.slice";
export * from "../slices/future-bookings.slice";
export * from "../slices/gift-voucher-add.slice";
export * from "../slices/gift-voucher-basket.slice";
export * from "../slices/gift-voucher-confirmedBasket.slice";
export * from "../slices/gift-voucher-edit.slice";
export * from "../slices/gift-voucher-payment.slice";
export * from "../slices/gift-voucher-remove.slice";
export * from "../slices/gv-basketId-persisted.slice";
export * from "../slices/gv-customer.slice";
export * from "../slices/gv-settings.slice";
export * from "../slices/location-block-info.slice";
export * from "../slices/location-map.slice";
export * from "../slices/location-search.slice";
export * from "../slices/location.slice";
export * from "../slices/payment-cards.slice";
export * from "../slices/payment-create.slice";
export * from "../slices/persist-booking.slice";
export * from "../slices/plan-your-visit.slice";
export * from "../slices/post-code-addresses.slice";
export * from "../slices/promo-code.slice";
export * from "../slices/register.slice";
export * from "../slices/removeCard.slice";
export * from "../slices/reservation.slice";
export * from "../slices/reset-password.slice";
export * from "../slices/rootActions";
export * from "../slices/scheduled-payment.slice";
export * from "../slices/scheduled-payments-by-booking-id.slice";
export * from "../slices/scheduled-payments-by-booking.slice";
export * from "../slices/single-future-booking.slice";
export * from "../slices/site-settings.slice";
export * from "../slices/sticky.slice";
export * from "../slices/update-mydetails.slice";
export * from "../slices/vehicle-add.slice";
export * from "../slices/vehicle-remove.slice";
export * from "../slices/voucher-apply.slice";
export * from "../slices/voucher-remove.slice";
export * from "../slices/voucher.slice";
export * from "../slices/window-location.slice";
export * from "../slices/calendar-summary.slice";
export * from "../slices/auth-remember-me.slice";
export * from "../slices/cabin-info.slice";
export * from "../slices/reservation-from-referral.slice";
export * from "../slices/mandatory-extras.slice";
export * from "../slices/active-experiments.slice";
