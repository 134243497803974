import {
  all,
  call,
  put,
  select,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import { gql } from "graphql-tag";
import { apolloClient } from "../../apollo-client";
import {
  AddExtraResponse,
  ExtraDetailsResponse,
  ExtraStockAvailabilityResponseUnion,
  FeaturedExtrasResponse,
  MutationAddExtrasToCabinArgs,
  MutationRemoveExtraFromBasketArgs,
  QueryExtraDetailsDirectArgs,
  QueryExtraStockAvailabilityArgs,
  QueryFeaturedExtrasForReservationArgs,
} from "@generated/types";
import {
  getFeaturedExtraDetailsContent,
  getFeaturedExtraDetailsContentFail,
  getFeaturedExtraDetailsContentSuccess,
  getFeaturedExtras,
  getFeaturedExtrasFail,
  getFeaturedExtrasSuccess,
  getFeaturedExtraStock,
  getFeaturedExtraStockFail,
  getFeaturedExtraStockSuccess,
  addFeaturedExtra,
  addFeaturedExtraFail,
  addFeaturedExtraSuccess,
  viewExtraDetailsWithSticky,
  removeFeaturedExtra,
  removeFeaturedExtraFail,
  removeFeaturedExtraSuccess,
  getBookingConfirmationAction,
} from "../slices";
import {
  loadGraphQLQuery,
  loadGraphQLMutation,
  loadCustomGraphQLMutation,
  loadCustomGraphQLQuery,
} from "src/utils";
import {
  bookingPersistedStateSelector,
  featuredExtraSelector,
} from "../selectors";
import { RemoveExtraFromBasketResponse } from "../../graphql/bsl/gql-custom/types";

// Featured Extras List Sagas:
function* getFeaturedExtrasSaga(action: ReturnType<typeof getFeaturedExtras>) {
  const dynamicGetFeaturedExtras = yield call(
    loadCustomGraphQLQuery,
    "featuredExtrasForReservation",
  );
  const getFeaturedExtrasCall = () =>
    apolloClient.query<
      { featuredExtrasForReservation: FeaturedExtrasResponse },
      QueryFeaturedExtrasForReservationArgs
    >({
      query: gql`
        ${dynamicGetFeaturedExtras}
      `,
      variables: action.payload.featuredExtrasQuery,
      fetchPolicy: "no-cache",
    });

  try {
    const getFeaturedExtrasResponse = yield call(getFeaturedExtrasCall);
    const { featuredExtrasForReservation } = getFeaturedExtrasResponse.data!;

    const { bookingId } = yield select(bookingPersistedStateSelector);
    if (!!bookingId) yield put(getBookingConfirmationAction({ bookingId }));
    yield put(
      getFeaturedExtrasSuccess({
        featuredExtrasResponse: featuredExtrasForReservation,
      }),
    );
  } catch (error: any) {
    yield put(getFeaturedExtrasFail(error as any));
    // appInsights.trackException(error);
  }
}

function* getFeaturedExtrasWithStateArgsSaga() {
  const getFeaturedExtraState = yield select(featuredExtraSelector);
  const { featuredExtrasList } = getFeaturedExtraState;

  if (featuredExtrasList && featuredExtrasList.getExtrasRequest) {
    yield put(
      getFeaturedExtras({
        featuredExtrasQuery: featuredExtrasList.getExtrasRequest,
      }),
    );
  }
}

// Add Featured Extra Sagas:
function* addFeaturedExtraSaga(action: ReturnType<typeof addFeaturedExtra>) {
  const dynamicAddFeaturesExtras = yield call(
    loadCustomGraphQLMutation,
    "addFeaturedExtrasToCabin",
  );
  const addFeaturedExtraCall = () =>
    apolloClient
      .mutate<
        { addExtrasToCabin: AddExtraResponse[] },
        MutationAddExtrasToCabinArgs
      >({
        mutation: gql`
          ${dynamicAddFeaturesExtras}
        `,
        fetchPolicy: "no-cache",
        variables: {
          request: action.payload.addFeaturedExtraInput,
        },
      })
      .then((response) => response.data?.addExtrasToCabin);

  try {
    const addFeaturedExtraData: AddExtraResponse[] =
      yield call(addFeaturedExtraCall);
    const isPostBookingJourney = action.payload.isPostBookingJourney;

    const [firstResult] = addFeaturedExtraData!;

    if (firstResult.success) {
      yield put(
        addFeaturedExtraSuccess({
          addFeaturedExtraResponse: addFeaturedExtraData as AddExtraResponse[],
          isPostBookingJourney,
        }),
      );
      if (action.payload.showSticky) {
        yield put(
          viewExtraDetailsWithSticky(
            action.payload.addFeaturedExtraInput[0].cabinReservationId,
          ),
        );
      }
    } else {
      throw Error(firstResult?.errorInfo || "Error adding extra.");
    }
  } catch (error: any) {
    yield put(addFeaturedExtraFail(error.message));
    // appInsights.trackException(error);
  }
}

function* addFeaturedExtraSuccessfulSaga(action: any) {
  yield call(getFeaturedExtrasWithStateArgsSaga);
}

// Remove Featured Extra Sagas:
function* removeFeaturedExtraSaga(
  action: ReturnType<typeof removeFeaturedExtra>,
) {
  const dynamicRemoveFeaturedExtras = yield call(
    loadGraphQLMutation,
    "removeExtraFromBasket",
  );
  const removeFeaturedExtraCall = () =>
    apolloClient.mutate<
      { removeExtraFromBasket: RemoveExtraFromBasketResponse },
      MutationRemoveExtraFromBasketArgs
    >({
      mutation: gql`
        ${dynamicRemoveFeaturedExtras}
      `,
      fetchPolicy: "no-cache",
      variables: action.payload.mutationRemoveFeaturedExtraFromBasketArgs,
    });

  try {
    const removeFeaturedExtraResponse = yield call(removeFeaturedExtraCall);
    const removeFeaturedExtraData =
      removeFeaturedExtraResponse?.data?.removeExtraFromBasket;

    if (removeFeaturedExtraData) {
      yield put(
        removeFeaturedExtraSuccess({
          removeFeaturedExtraFromBasketResponse: removeFeaturedExtraData,
          isPostBookingJourney: action.payload.isPostBookingJourney,
        }),
      );
    } else {
      throw Error("Failed to remove extra");
    }
  } catch (error: any) {
    yield put(removeFeaturedExtraFail(error.message));
    // appInsights.trackException(error);
  }
}

function* removeFeaturedExtraSuccessfulSaga(action: any) {
  yield call(getFeaturedExtrasWithStateArgsSaga);
}

// Featured Extras Details sagas
function* getFeaturedExtraDetails(
  action: ReturnType<typeof getFeaturedExtraDetailsContent>,
) {
  const dynamicFeaturedExtraDetails = yield call(
    loadGraphQLQuery,
    "extraDetailsDirect",
  );
  const getFeaturedExtraDetailsContentCall = () =>
    apolloClient.query<
      { extraDetailsDirect: ExtraDetailsResponse },
      QueryExtraDetailsDirectArgs
    >({
      query: gql`
        ${dynamicFeaturedExtraDetails}
      `,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });

  try {
    const getfeaturedExtraDetailsContent = yield call(
      getFeaturedExtraDetailsContentCall,
    );
    const data = getfeaturedExtraDetailsContent?.data?.extraDetailsDirect;

    if (!!data?.extraDetails) {
      yield put(getFeaturedExtraDetailsContentSuccess(data.extraDetails));
    } else {
      yield put(
        getFeaturedExtraDetailsContentFail("Could not get extra details"),
      );
    }
  } catch (error: any) {
    yield put(getFeaturedExtraDetailsContentFail(error.message));
  }
}

// Extra Stock Availability
function* getFeaturedExtraStockAvailability(
  action: ReturnType<typeof getFeaturedExtraStock>,
) {
  const dynamicFeaturedExtraStockAvailability = yield call(
    loadGraphQLQuery,
    "extraStockAvailability",
  );
  const getFeaturedExtrasStockAvailabilityCall = () =>
    apolloClient.query<
      { extraStockAvailability: ExtraStockAvailabilityResponseUnion },
      QueryExtraStockAvailabilityArgs
    >({
      query: gql`
        ${dynamicFeaturedExtraStockAvailability}
      `,
      variables: action.payload,
      fetchPolicy: "no-cache",
    });

  try {
    const getFeaturedExtrasStockResponse = yield call(
      getFeaturedExtrasStockAvailabilityCall,
    );
    const { data } = getFeaturedExtrasStockResponse;

    switch (data?.extraStockAvailability.__typename) {
      case "ExtraStockAvailabilityResponse":
        yield put(getFeaturedExtraStockSuccess(data.extraStockAvailability));
        break;
    }
  } catch (error: any) {
    yield put(getFeaturedExtraStockFail(error.message));
    // appInsights.trackException(error);
  }
}

export function* featuredExtraSagas() {
  yield all([
    takeLatest("featuredExtrasList/getFeaturedExtras", getFeaturedExtrasSaga),
    takeLatest("featuredExtrasList", getFeaturedExtrasWithStateArgsSaga),
    takeEvery("addFeaturedExtra/addFeaturedExtra", addFeaturedExtraSaga),
    takeEvery(
      "RemoveFeaturedExtra/removeFeaturedExtra",
      removeFeaturedExtraSaga,
    ),
    takeLatest(
      "featuredExtraDetails/getFeaturedExtraDetailsContent",
      getFeaturedExtraDetails,
    ),
    takeLatest(
      "featuredExtrasStockControl/getFeaturedExtraStock",
      getFeaturedExtraStockAvailability,
    ),
  ]);
}
