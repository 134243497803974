import { RootState } from "../slices";
import { useSelector } from "react-redux";

// export const getPaymentCardssStateSelector = (state: RootState) =>
//   state.paymentCards;
export const getApplicableSavedCardsStateSelector = (state: RootState) =>
  state.applicableSavedCards;
// export const getRemoveCardsStateSelector = (state: RootState) =>
//   state.removedCards;
// export const useGetPaymentCardssStateSelector = () =>
//   useSelector(getPaymentCardssStateSelector);
export const useGetApplicableSavedCardsStateSelector = () =>
  useSelector(getApplicableSavedCardsStateSelector);
// export const useGetRemoveCardssStateSelector = () =>
//   useSelector(getRemoveCardsStateSelector);
