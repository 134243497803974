import { createSlice } from "@reduxjs/toolkit";
import {
  LocationMapPropertiesResponse,
  QueryLocationMapPropertiesArgs,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type LocationMapState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<LocationMapPropertiesResponse>;
};

const initialState: LocationMapState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
});

export const locationMapSlice = createSlice({
  name: "locationMap",
  initialState,
  reducers: {
    getLocationMapProperties: (
      state,
      action: PayloadAction<QueryLocationMapPropertiesArgs>
    ) => {
      state.loading = true;
      state.loaded = false;
      state.data = null;
      state.errorMessage = null;
      state.hasError = false;
    },
    getLocationMapPropertiesSuccess: (
      state,
      action: PayloadAction<LocationMapPropertiesResponse>
    ) => {
      state.data = action.payload;
      state.loaded = true;
      state.loading = false;
    },
    getLocationMapPropertiesFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.loaded = false;
      state.data = null;
      state.errorMessage = action.payload;
      state.hasError = true;
    },
  },
});

export const {
  getLocationMapProperties,
  getLocationMapPropertiesSuccess,
  getLocationMapPropertiesFail,
} = locationMapSlice.actions;

export default locationMapSlice.reducer;
