import { useBookingPersistedStateSelector } from "@/store/selectors";
import { HttpStatusCode } from "axios";
import { getCookie, setCookie } from "cookies-next";
import { FHCookies } from "src/interfaces/cookies";

const fetchData = async (stateBookingId: string) => {
  const bookingStateBody = {
    bookingId: stateBookingId,
  };
  try {
    const response = await fetch("/api/post-experiment-data", {
      method: "POST",
      body: JSON.stringify(bookingStateBody),
      headers: {
        accept: "application/json",
        "experiment-url": window.location.href,
      },
    });

    if (HttpStatusCode.NoContent === response.status) {
      const oneDayExpiry = new Date();
      oneDayExpiry.setDate(oneDayExpiry.getDate() + 1);
      setCookie(FHCookies.bookingId, stateBookingId, {
        expires: oneDayExpiry,
      });
    }
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

export const ExperimentData = () => {
  const { bookingId } = useBookingPersistedStateSelector();
  const currentBookingId = getCookie(FHCookies.bookingId);
  if (
    "undefined" !== typeof window && 
    undefined !== bookingId &&
    currentBookingId != bookingId
  ) {
    fetchData(bookingId);
  }
  return null;
};
