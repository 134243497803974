import { ExtraStockAvailability } from "@components/SemanticTheme/ExtrasCard";

export type OptionDateQuantitySelectProps = OptionSelectSharedProps & {
  defaultTotalPrice: ExtraField[];
  setDefaultTotalPrice: (listOfItems: any) => void;
  showRemainingQtyLabel?: boolean;
  showTotalPriceOnAddButton?: boolean;
  primaryCTATitle?: string;
  primaryCTAMiddleTitle?: string;
  basketExtrasOptionsArray?: basketExtrasOptionsArrayProp[];
};

export type ExtraField = {
  value: number;
  qty: number;
  id?: string;
  priceCheck: string;
  priceExpiry?: string;
};

export type ExtrasDateProps = {
  isoDate: string;
  label?: string;
  maxAdded?: boolean;
  soldOut?: boolean;
  maxTime?: {
    am?: boolean;
    pm?: boolean;
  };
};

export enum ExtraTime {
  AM = "am",
  PM = "pm",
}

export type AddExtraProps = {
  qtyTotal: number;
  summary?: ExtraField[];
  date?: string;
  time?: ExtraTime;
};

export type MaxAddedDateTime = {
  date?: string;
  time?: ExtraTime;
};

export type ExtrasItemProps = {
  id: string;
  label: string;
  value: number;
  min?: number;
  max?: number;
  priceCheck: string;
  priceExpiry?: string;
  currentStockLevel?: number;
  stockControlled?: boolean;
  maxAdded?: boolean;
  maxAddedDateTime?: MaxAddedDateTime[];
};

export type OptionSelectSharedProps = {
  hasOnlyOneDefaultValue?: boolean;
  items: ExtrasItemProps[];
  dates?: ExtrasDateProps[];
  includeTime?: boolean;
  onSubmit?: (extrasValue: AddExtraProps) => void;
  onLearnMoreAction?: (extraId: string) => void;
  qtyTitle?: string;
  dateTitle?: string;
  timeTitle?: string;
  quickLearnMoreLabel?: string;
  ctaLabel?: string;
  ctaUrl?: string;
  maxQuantity: number;
  loading?: boolean;
  hideSubmitButton?: boolean;
  showLearnMoreButton?: boolean;
  disableCtaButton?: boolean;
  maxAddedWarningLabel?: string;
  extraId?: string;
  addLabel?: string;
  forLabel?: string;
  overrideComplexScenarioHide?: boolean;
  cabinReservationId?: string;
  getExtraStockAvailability?: (extra: StockControlProps) => void;
  extrasStockAvailability?: ExtraStockAvailability;
  limitedStockLabel?: string;
  soldOutLabel?: string;
  extrasListLoading?: boolean;
  addExtraHasError?: boolean;
  isExtraAvailable?: boolean;
};

export type SelectedExtraOptions = {
  extraOptionId: string;
  selectedQuantity: number;
  calendarDate?: string;
  timeSlot?: string;
};

export type StockControlProps = {
  extraId: string;
  extraOptionIds: string[];
  cabinReservationId: string;
  selectedExtraOptions: SelectedExtraOptions[];
};

export type basketExtrasOptionsArrayProp = {
  id?: string;
  date?: string;
  quantity: number;
  aMPM?: string | null;
  hasAMPM?: boolean | null;
  itemMaxReached?: boolean;
};
