import { createSlice } from "@reduxjs/toolkit";
import {
  CustomerResetPasswordRequestInput,
  CustomerResetPasswordResponse,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type ResetPasswordState = {
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
  data: Nullable<CustomerResetPasswordResponse>;
  isValidLoading: boolean;
  isValidLoaded: boolean;
  isValid: boolean;
};

const initialState: ResetPasswordState = Object.freeze({
  errorMessage: null,
  hasError: false,
  loaded: false,
  loading: false,
  data: null,
  isValidLoading: false,
  isValidLoaded: false,
  isValid: false,
});

export const resetPasswordSlice = createSlice({
  name: "resetPassword",
  initialState,
  reducers: {
    resetPassword: (
      state,
      action: PayloadAction<CustomerResetPasswordRequestInput>
    ) => {
      state.loading = true;
      state.data = null;
      state.errorMessage = null;
      state.loaded = false;
      state.hasError = false;
      state.isValid = false;
      state.isValidLoaded = false;
      state.isValidLoading = false;
    },
    resetPasswordSuccess: (
      state,
      action: PayloadAction<CustomerResetPasswordResponse>
    ) => {
      state.loading = false;
      state.data = action.payload;
      state.errorMessage = null;
      state.loaded = true;
      state.hasError = false;
      state.isValid = false;
      state.isValidLoaded = false;
      state.isValidLoading = false;
    },
    resetPasswordFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.data = null;
      state.errorMessage = action.payload;
      state.loaded = false;
      state.hasError = true;
      state.isValid = false;
      state.isValidLoaded = false;
      state.isValidLoading = false;
    },
    resetPasswordReset: (state, action: PayloadAction<void>) => ({
      ...initialState,
    }),
    isValidResetId: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.data = null;
      state.errorMessage = null;
      state.loaded = false;
      state.hasError = false;
      state.isValid = false;
      state.isValidLoaded = false;
      state.isValidLoading = true;
    },
    isValidResetIdSuccess: (state, action: PayloadAction<boolean>) => {
      state.loading = false;
      state.data = null;
      state.errorMessage = null;
      state.loaded = false;
      state.hasError = false;
      state.isValid = action.payload;
      state.isValidLoaded = true;
      state.isValidLoading = false;
    },
    isValidResetIdFail: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.data = null;
      state.errorMessage = action.payload;
      state.loaded = false;
      state.hasError = true;
      state.isValid = false;
      state.isValidLoaded = true;
      state.isValidLoading = false;
    },
  },
});

export const {
  resetPassword,
  resetPasswordSuccess,
  resetPasswordFail,
  resetPasswordReset,
  isValidResetId,
  isValidResetIdSuccess,
  isValidResetIdFail,
} = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;
