import { RootState } from "../slices";
import { useSelector } from "react-redux";

export const attendeeSummaryStateSelector = (state: RootState) =>
  state.attendeeSummary;
export const useAttendeeSummaryStateSelector = () =>
  useSelector(attendeeSummaryStateSelector);

export const addAttendeeStateSelector = (state: RootState) => state.attendeeAdd;
export const useAddAttendeeStateSelector = () =>
  useSelector(addAttendeeStateSelector);

export const removeAttendeeStateSelector = (state: RootState) =>
  state.attendeeRemove;
export const useRemoveAttendeeStateSelector = () =>
  useSelector(removeAttendeeStateSelector);
