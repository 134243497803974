import { createSlice } from "@reduxjs/toolkit";
import {
  ExtraStockAvailabilityResponse,
  QueryExtraStockAvailabilityArgs,
} from "@generated/types";
import { Nullable } from "../../utils";
import type { PayloadAction } from "@reduxjs/toolkit";

export type ExtraStockControlState = {
  extraStockControlItems: Nullable<ExtraStockAvailabilityResponse[]>;
  errorMessage: Nullable<string>;
  hasError: boolean;
  loaded: boolean;
  loading: boolean;
};

const initialState: ExtraStockControlState = Object.freeze({
  extraStockControlItems: null,
  hasError: false,
  errorMessage: null,
  loaded: false,
  loading: false,
});

export const extraStockControlSlice = createSlice({
  name: "extraStockControl",
  initialState,
  reducers: {
    getExtraStock: (
      state,
      action: PayloadAction<QueryExtraStockAvailabilityArgs>
    ) => {
      state.loading = true;
    },
    getExtraStockSuccess: (
      state,
      action: PayloadAction<ExtraStockAvailabilityResponse>
    ) => {
      const extraStockControlItems = state.extraStockControlItems ?? [
        action.payload,
      ];
      const idx =
        state.extraStockControlItems?.findIndex(
          (item) => item.extraId === action.payload.extraId
        ) ?? -1;

      if (!!state.extraStockControlItems?.length) {
        if (idx >= 0) {
          extraStockControlItems.splice(idx, 1, action.payload);
        } else {
          extraStockControlItems.push(action.payload);
        }
      }

      state.loading = false;
      state.loaded = true;
      state.extraStockControlItems = extraStockControlItems;
    },
    getExtraStockFail: (state, action: PayloadAction<string>) => {
      state.hasError = true;
      state.errorMessage = action.payload;
      state.extraStockControlItems = null;
      state.loaded = false;
      state.loading = false;
    },
  },
});

export const { getExtraStock, getExtraStockSuccess, getExtraStockFail } =
  extraStockControlSlice.actions;

export default extraStockControlSlice.reducer;
